import { createAction } from 'redux-actions';

export const GET_LOG_FILTER_BY_OID = 'logReports/GET_LOG_FILTER_BY_OID';
export const GET_LOG_FILTER_BY_OID_SUCCESS = 'logReports/GET_LOG_FILTER_BY_OID_SUCCESS';
export const GET_LOG_FILTER_BY_OID_ERROR = 'logReports/GET_LOG_FILTER_BY_OID_ERROR';

export const getLogFilterByOid = createAction(GET_LOG_FILTER_BY_OID, (id) => id);

export const GET_LOG_FILTERS = 'logReports/GET_LOG_FILTERS';
export const GET_LOG_FILTERS_SUCCESS = 'logReports/GET_LOG_FILTERS_SUCCESS';
export const GET_LOG_FILTERS_ERROR = 'logReports/GET_LOG_FILTERS_ERROR';

export const getLogFilters = createAction(GET_LOG_FILTERS);

export const EDIT_LOG_FILTER = 'logReports/EDIT_LOG_FILTER';
export const EDIT_LOG_FILTER_SUCCESS = 'logReports/EDIT_LOG_FILTER_SUCCESS';
export const EDIT_LOG_FILTER_ERROR = 'logReports/EDIT_LOG_FILTER_ERROR';

export const editLogFilter = createAction(EDIT_LOG_FILTER, ({ oid, param }) => ({ oid, param }));

export const GET_LOG_REPORTS = 'logReports/GET_LOG_REPORTS';
export const GET_LOG_REPORTS_SUCCESS = 'logReports/GET_LOG_REPORTS_SUCCESS';
export const GET_LOG_REPORTS_ERROR = 'logReports/GET_LOG_REPORTS_ERROR';

export const getLogReports = createAction(GET_LOG_REPORTS, ({ page, limit }) => ({ page, limit }));

export const SEARCH_LOG_REPORTS = 'logReports/SEARCH_LOG_REPORTS';
export const SEARCH_LOG_REPORTS_SUCCESS = 'logReports/SEARCH_LOG_REPORTS_SUCCESS';
export const SEARCH_LOG_REPORTS_ERROR = 'logReports/SEARCH_LOG_REPORTS_ERROR';

export const searchLogReports = createAction(SEARCH_LOG_REPORTS, ({ page, limit, param }) => ({ page, limit, param }));

export const ON_CHANGE = 'logReports/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'logReports/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
