import { combineActions, handleActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions, handleAsyncActionsWithHeader } from 'src/lib/asyncUtils';
import {
    GET_ACCOUNT_BY_OID,
    GET_ACCOUNT_BY_OID_SUCCESS,
    GET_ACCOUNT_BY_OID_ERROR,
    GET_ACCOUNTS,
    GET_ACCOUNTS_SUCCESS,
    GET_ACCOUNTS_ERROR,
    GET_ACCOUNT_GB,
    GET_ACCOUNT_GB_SUCCESS,
    GET_ACCOUNT_GB_ERROR,
    GET_PAGE_ROLES,
    GET_PAGE_ROLES_SUCCESS,
    GET_PAGE_ROLES_ERROR,
    EDIT_ACCOUNT,
    EDIT_ACCOUNT_SUCCESS,
    EDIT_ACCOUNT_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    CREATE_ACCOUNT,
    CREATE_ACCOUNT_SUCCESS,
    CREATE_ACCOUNT_ERROR,
    FORCE_LOGOUT,
    FORCE_LOGOUT_SUCCESS,
    FORCE_LOGOUT_ERROR,
    EXPORT_HISTORY,
    EXPORT_HISTORY_SUCCESS,
    EXPORT_HISTORY_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';

const initialState = {
    result: reducerUtils.initial(false),
    accounts: reducerUtils.initial([]),
    account: reducerUtils.initial({}),
    accountGb: reducerUtils.initial([]),
    pageRoles: reducerUtils.initial([]),
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(GET_ACCOUNT_BY_OID, GET_ACCOUNT_BY_OID_SUCCESS, GET_ACCOUNT_BY_OID_ERROR)]: (state, action) =>
            handleAsyncActions(GET_ACCOUNT_BY_OID, 'account', true)(state, action),
        [combineActions(GET_ACCOUNTS, GET_ACCOUNTS_SUCCESS, GET_ACCOUNTS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(GET_ACCOUNTS, 'accounts', true)(state, action),
        [combineActions(GET_ACCOUNT_GB, GET_ACCOUNT_GB_SUCCESS, GET_ACCOUNT_GB_ERROR)]: (state, action) =>
            handleAsyncActions(GET_ACCOUNT_GB, 'accountGb', true)(state, action),
        [combineActions(GET_PAGE_ROLES, GET_PAGE_ROLES_SUCCESS, GET_PAGE_ROLES_ERROR)]: (state, action) =>
            handleAsyncActions(GET_PAGE_ROLES, 'pageRoles', true)(state, action),
        [combineActions(CREATE_ACCOUNT, CREATE_ACCOUNT_SUCCESS, CREATE_ACCOUNT_ERROR)]: (state, action) =>
            handleAsyncActions(CREATE_ACCOUNT, 'result', false)(state, action),
        [combineActions(EDIT_ACCOUNT, EDIT_ACCOUNT_SUCCESS, EDIT_ACCOUNT_ERROR)]: (state, action) =>
            handleAsyncActions(EDIT_ACCOUNT, 'result', false)(state, action),
        [combineActions(RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR)]: (state, action) =>
            handleAsyncActions(RESET_PASSWORD, 'result', false)(state, action),
        [combineActions(FORCE_LOGOUT, FORCE_LOGOUT_SUCCESS, FORCE_LOGOUT_ERROR)]: (state, action) =>
            handleAsyncActions(FORCE_LOGOUT, 'result', false)(state, action),
        [combineActions(EXPORT_HISTORY, EXPORT_HISTORY_SUCCESS, EXPORT_HISTORY_ERROR)]: (state, action) =>
            handleAsyncActions(EXPORT_HISTORY, 'result', false)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
