import { handleActions } from 'redux-actions';
import { ON_CHANGE } from './action';

const initialState = {
    sidebarShow: 'responsive',
};

export default handleActions(
    {
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
    },
    initialState,
);
