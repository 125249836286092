import { createAction } from 'redux-actions';

export const GET_CURRENT_LOCALE_MAP = 'locale/GET_CURRENT_LOCALE_MAP';
export const GET_CURRENT_LOCALE_MAP_SUCCESS = 'locale/GET_CURRENT_LOCALE_MAP_SUCCESS';
export const GET_CURRENT_LOCALE_MAP_ERROR = 'locale/GET_CURRENT_LOCALE_MAP_ERROR';

export const getCurrentLocaleMap = createAction(GET_CURRENT_LOCALE_MAP, (lang) => lang);

export const ON_CHANGE = 'lang/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);
