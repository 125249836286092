import { handleActions, combineActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions, handleAsyncActionsWithHeader } from 'src/lib/asyncUtils';
import {
    GET_CMCODE,
    GET_CMCODE_SUCCESS,
    GET_CMCODE_ERROR,
    GET_CMCODE_FOR_SELECT,
    GET_CMCODE_FOR_SELECT_SUCCESS,
    GET_CMCODE_FOR_SELECT_ERROR,
    GET_CMCODES,
    GET_CMCODES_SUCCESS,
    GET_CMCODES_ERROR,
    SEARCH_CMCODES,
    SEARCH_CMCODES_SUCCESS,
    SEARCH_CMCODES_ERROR,
    GET_CMCODES_FOR_SELECT,
    GET_CMCODES_FOR_SELECT_SUCCESS,
    GET_CMCODES_FOR_SELECT_ERROR,
    GET_PRODUCT_CMCODES_FOR_SELECT,
    GET_PRODUCT_CMCODES_FOR_SELECT_SUCCESS,
    GET_PRODUCT_CMCODES_FOR_SELECT_ERROR,
    CREATE_MAJOR,
    CREATE_MAJOR_SUCCESS,
    CREATE_MAJOR_ERROR,
    EDIT_MAJOR,
    EDIT_MAJOR_SUCCESS,
    EDIT_MAJOR_ERROR,
    CREATE_MINOR,
    CREATE_MINOR_SUCCESS,
    CREATE_MINOR_ERROR,
    EDIT_MINOR,
    EDIT_MINOR_SUCCESS,
    EDIT_MINOR_ERROR,
    FIND_MAJOR,
    FIND_MINORS,
    FIND_MINOR,
    ON_CHANGE,
    INITIALIZE,
    CHANGE_ITEM_TO_UPDATED_DATA,
} from './action';

const initialState = {
    result: reducerUtils.initial(false),
    cmcodes: reducerUtils.initial([]),
    cmcode: reducerUtils.initial({}),
    majors: reducerUtils.initial([]),
    major: null,
    minors: [],
    minor: null,
    searchForm: {
        gb: '',
        useYn: '',
        key: '',
        value: '',
    },
    isSearch: false,
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(GET_CMCODE, GET_CMCODE_SUCCESS, GET_CMCODE_ERROR)]: (state, action) =>
            handleAsyncActions(GET_CMCODE, 'cmcode', false)(state, action),
        [combineActions(GET_CMCODE_FOR_SELECT, GET_CMCODE_FOR_SELECT_SUCCESS, GET_CMCODE_FOR_SELECT_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_CMCODE_FOR_SELECT, 'cmcode', false)(state, action),
        [combineActions(GET_CMCODES, GET_CMCODES_SUCCESS, GET_CMCODES_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(GET_CMCODES, 'cmcodes', true)(state, action),
        [combineActions(GET_CMCODES_FOR_SELECT, GET_CMCODES_FOR_SELECT_SUCCESS, GET_CMCODES_FOR_SELECT_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_CMCODES_FOR_SELECT, 'majors', true)(state, action),
        [combineActions(
            GET_PRODUCT_CMCODES_FOR_SELECT,
            GET_PRODUCT_CMCODES_FOR_SELECT_SUCCESS,
            GET_PRODUCT_CMCODES_FOR_SELECT_ERROR,
        )]: (state, action) => handleAsyncActions(GET_PRODUCT_CMCODES_FOR_SELECT, 'cmcodes', true)(state, action),
        [combineActions(SEARCH_CMCODES, SEARCH_CMCODES_SUCCESS, SEARCH_CMCODES_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(SEARCH_CMCODES, 'cmcodes', true)(state, action),
        [combineActions(CREATE_MAJOR, CREATE_MAJOR_SUCCESS, CREATE_MAJOR_ERROR)]: (state, action) =>
            handleAsyncActions(CREATE_MAJOR, 'result', false)(state, action),
        [combineActions(EDIT_MAJOR, EDIT_MAJOR_SUCCESS, EDIT_MAJOR_ERROR)]: (state, action) =>
            handleAsyncActions(EDIT_MAJOR, 'result', false)(state, action),
        [combineActions(CREATE_MINOR, CREATE_MINOR_SUCCESS, CREATE_MINOR_ERROR)]: (state, action) =>
            handleAsyncActions(CREATE_MINOR, 'result', false)(state, action),
        [combineActions(EDIT_MINOR, EDIT_MINOR_SUCCESS, EDIT_MINOR_ERROR)]: (state, action) =>
            handleAsyncActions(EDIT_MINOR, 'result', false)(state, action),
        [FIND_MAJOR]: (state, action) => {
            const _cdMajor = action.payload;
            const cmcodes = state.cmcodes.data;

            const targetIndex = cmcodes.findIndex(({ cdMajor }) => cdMajor === _cdMajor);
            const { _id, gb, cdMajor, cdFName, effStaDt, effEndDt, description, timestamp } = cmcodes[targetIndex];

            return {
                ...state,
                major: {
                    _id,
                    gb,
                    cdMajor,
                    cdFName,
                    effStaDt,
                    effEndDt,
                    description,
                    timestamp,
                },
            };
        },
        [FIND_MINORS]: (state, action) => {
            const _cdMajor = action.payload;
            const cmcodes = state.cmcodes.data;

            const targetIndex = cmcodes.findIndex(({ cdMajor }) => cdMajor === _cdMajor);
            const { cdMinors } = cmcodes[targetIndex];

            return {
                ...state,
                minors: cdMinors,
            };
        },
        [FIND_MINOR]: (state, action) => {
            const _cdMinor = action.payload;
            const { minors } = state;

            const targetIndex = minors.findIndex(({ cdMinor }) => cdMinor === _cdMinor);

            return {
                ...state,
                minor: minors[targetIndex],
            };
        },
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
        [CHANGE_ITEM_TO_UPDATED_DATA]: (state, action) => {
            const { target, name, key, value } = action.payload;
            const currentList = target ? state[target][name] : state[name];
            const targetIndex = currentList.findIndex((item) => item[key] === value[key]);

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: [
                              ...currentList.slice(0, targetIndex),
                              value,
                              ...currentList.slice(targetIndex + 1, currentList.length),
                          ],
                      },
                  }
                : {
                      ...state,
                      [name]: [
                          ...currentList.slice(0, targetIndex),
                          value,
                          ...currentList.slice(targetIndex + 1, currentList.length),
                      ],
                  };
        },
    },
    initialState,
);
