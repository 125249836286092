import { createAction } from 'redux-actions';

export const GET_BACKUP_METADATA_BY_OID = 'backupMetadata/GET_BACKUP_METADATA_BY_OID';
export const GET_BACKUP_METADATA_BY_OID_SUCCESS = 'backupMetadata/GET_BACKUP_METADATA_BY_OID_SUCCESS';
export const GET_BACKUP_METADATA_BY_OID_ERROR = 'backupMetadata/GET_BACKUP_METADATA_BY_OID_ERROR';

export const getBackupMetadataByOid = createAction(GET_BACKUP_METADATA_BY_OID, (id) => id);

export const GET_BACKUP_METADATAS = 'backupMetadata/GET_BACKUP_METADATAS';
export const GET_BACKUP_METADATAS_SUCCESS = 'backupMetadata/GET_BACKUP_METADATAS_SUCCESS';
export const GET_BACKUP_METADATAS_ERROR = 'backupMetadata/GET_BACKUP_METADATAS_ERROR';

export const getBackupMetadatas = createAction(GET_BACKUP_METADATAS, ({ page, limit }) => ({ page, limit }));

export const ADD_BACKUP_METADATA = 'backupMetadata/ADD_BACKUP_METADATA';
export const ADD_BACKUP_METADATA_SUCCESS = 'backupMetadata/ADD_BACKUP_METADATA_SUCCESS';
export const ADD_BACKUP_METADATA_ERROR = 'backupMetadata/ADD_BACKUP_METADATA_ERROR';

export const addBackupMetadata = createAction(ADD_BACKUP_METADATA, ({ gameId, param }) => ({
    gameId,
    param,
}));

export const EDIT_BACKUP_METADATA = 'backupMetadata/EDIT_BACKUP_METADATA';
export const EDIT_BACKUP_METADATA_SUCCESS = 'backupMetadata/EDIT_BACKUP_METADATA_SUCCESS';
export const EDIT_BACKUP_METADATA_ERROR = 'backupMetadata/EDIT_BACKUP_METADATA_ERROR';

export const editBackupMetadata = createAction(EDIT_BACKUP_METADATA, ({ oid, param }) => ({ oid, param }));

export const ON_CHANGE = 'backupMetadata/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const ON_PUSH_ITEM = 'backupMetadata/ON_PUSH_ITEM';
export const onPushItem = createAction(ON_PUSH_ITEM, (payload) => payload);

export const ON_REMOVE_ITEM = 'backupMetadata/ON_REMOVE_ITEM';
export const onRemoveItem = createAction(ON_REMOVE_ITEM, (payload) => payload);

export const INITIALIZE = 'backupMetadata/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
