import { takeEvery, call, put, take } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import {
    GET_FIELD_MAP,
    GET_FIELD_MAPS,
    SEARCH_FIELD_MAPS,
    GET_FIELD_MAPS_FOR_SELECT,
    CREATE_FIELD_MAP,
    EDIT_FIELD_MAP,
} from 'src/store/modules/fieldMap';
import { OPEN_MODAL, CLOSE_MODAL } from 'src/store/modules/modal';

const getFieldMapSaga = createPromiseSaga(GET_FIELD_MAP, api.getFieldMap);
const getFieldMapsSaga = createPromiseSagaWithHeader(GET_FIELD_MAPS, api.getFieldMaps);
const searchFieldMapsSaga = createPromiseSagaWithHeader(SEARCH_FIELD_MAPS, api.searchFieldMaps);
const getFieldMapsForSelectSaga = createPromiseSaga(GET_FIELD_MAPS_FOR_SELECT, api.getFieldMapsForSelect);

function* createFieldMapSaga(action) {
    const param = action.payload;

    try {
        const formData = new FormData();

        Object.keys(param).forEach((key) => {
            formData.append(key, param[key]);
        });

        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.createFieldMap, formData);

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${CREATE_FIELD_MAP}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: CLOSE_MODAL, payload: 'createFieldMap' });
        yield put({ type: GET_FIELD_MAPS, payload: { page: 1, limit: 10 } });
    } catch (e) {
        yield put({ type: `${CREATE_FIELD_MAP}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* editFieldMapSaga(action) {
    const { oid, param } = action.payload;

    try {
        const formData = new FormData();

        Object.keys(param).forEach((key) => {
            formData.append(key, param[key]);
        });

        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.editFieldMap, { oid, param: formData });

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${EDIT_FIELD_MAP}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: CLOSE_MODAL, payload: 'editFieldMap' });
        yield put({ type: GET_FIELD_MAPS, payload: { page: 1, limit: 10 } });
    } catch (e) {
        yield put({ type: `${EDIT_FIELD_MAP}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* fieldMapSaga() {
    yield takeEvery(GET_FIELD_MAP, getFieldMapSaga);
    yield takeEvery(GET_FIELD_MAPS, getFieldMapsSaga);
    yield takeEvery(SEARCH_FIELD_MAPS, searchFieldMapsSaga);
    yield takeEvery(GET_FIELD_MAPS_FOR_SELECT, getFieldMapsForSelectSaga);
    yield takeEvery(CREATE_FIELD_MAP, createFieldMapSaga);
    yield takeEvery(EDIT_FIELD_MAP, editFieldMapSaga);
}
