import { takeEvery, call, put } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import {
    GET_DATA_PRESET_LOGS,
    SEARCH_DATA_PRESET_LOGS,
    DOWNLOAD_DATA_PRESET_BACKUP_LOG,
} from 'src/store/modules/dataPresetLog/action';
import { OPEN_MODAL, CLOSE_MODAL } from 'src/store/modules/modal';

const getDataPresetLogsSaga = createPromiseSagaWithHeader(GET_DATA_PRESET_LOGS, api.getDataPresetLogs);
const searchDataPresetLogsSaga = createPromiseSagaWithHeader(SEARCH_DATA_PRESET_LOGS, api.searchDataPresetLogs);

function* downloadDataPresetBackupLogSaga(action) {
    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.downloadDataPresetBakcupLog, action.payload);

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${DOWNLOAD_DATA_PRESET_BACKUP_LOG}_SUCCESS`, payload: true });
    } catch (e) {
        yield put({ type: `${DOWNLOAD_DATA_PRESET_BACKUP_LOG}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* dataPresetLogSaga() {
    yield takeEvery(GET_DATA_PRESET_LOGS, getDataPresetLogsSaga);
    yield takeEvery(SEARCH_DATA_PRESET_LOGS, searchDataPresetLogsSaga);
    yield takeEvery(DOWNLOAD_DATA_PRESET_BACKUP_LOG, downloadDataPresetBackupLogSaga);
}
