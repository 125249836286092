import { combineActions, handleActions } from 'redux-actions';
import { reducerUtils, handleAsyncActionsWithHeader, handleAsyncActions } from 'src/lib/asyncUtils';
import {
    GET_NOTICES,
    GET_NOTICES_SUCCESS,
    GET_NOTICES_ERROR,
    SEARCH_NOTICES,
    SEARCH_NOTICES_SUCCESS,
    SEARCH_NOTICES_ERROR,
    GET_PUBLISHED_NOTICES,
    GET_PUBLISHED_NOTICES_SUCCESS,
    GET_PUBLISHED_NOTICES_ERROR,
    ORDER_PUBLISHED_NOTICES,
    ORDER_PUBLISHED_NOTICES_SUCCESS,
    ORDER_PUBLISHED_NOTICES_ERROR,
    ON_CHANGE,
    INITIALIZE,
    CLEAR,
} from './action';

const initialState = {
    notices: reducerUtils.initial([]),
    publishedNotices: reducerUtils.initial([]),
    result: reducerUtils.initial(false),
    isSearch: false,
    searchForm: {},
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(GET_NOTICES, GET_NOTICES_SUCCESS, GET_NOTICES_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(GET_NOTICES, 'notices', true)(state, action),
        [combineActions(SEARCH_NOTICES, SEARCH_NOTICES_SUCCESS, SEARCH_NOTICES_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(SEARCH_NOTICES, 'notices', true)(state, action),
        [combineActions(GET_PUBLISHED_NOTICES, GET_PUBLISHED_NOTICES_SUCCESS, GET_PUBLISHED_NOTICES_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_PUBLISHED_NOTICES, 'publishedNotices', false)(state, action),
        [combineActions(ORDER_PUBLISHED_NOTICES, ORDER_PUBLISHED_NOTICES_SUCCESS, ORDER_PUBLISHED_NOTICES_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(ORDER_PUBLISHED_NOTICES, 'result', true)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
        [CLEAR]: () => {
            return initialState;
        },
    },
    initialState,
);
