import { takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import { GET_PLAYER_INQUIRY_BAN_LOGS, GET_INQUIRY_BAN_LOGS, SEARCH_INQUIRY_BAN_LOGS } from './action';

const getPlayerInquiryBanLogs = createPromiseSaga(GET_PLAYER_INQUIRY_BAN_LOGS, api.getPlayerInquiryBanLogs);
const getDeviceBanLogsSaga = createPromiseSagaWithHeader(GET_INQUIRY_BAN_LOGS, api.getInquiryBanLogs);
const searchDeviceBanLogsSaga = createPromiseSagaWithHeader(SEARCH_INQUIRY_BAN_LOGS, api.searchInquiryBanLogs);

export default function* deviceBanLogSaga() {
    yield takeEvery(GET_PLAYER_INQUIRY_BAN_LOGS, getPlayerInquiryBanLogs);
    yield takeEvery(GET_INQUIRY_BAN_LOGS, getDeviceBanLogsSaga);
    yield takeEvery(SEARCH_INQUIRY_BAN_LOGS, searchDeviceBanLogsSaga);
}
