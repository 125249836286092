import { put, call, take, takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import { CLOSE_MODAL, OPEN_MODAL, CONFIRM } from '../modal';
import { GET_SCHEDULES_BY_GAME_ID, CREATE_SCHEDULE, DELETE_SCHEDULE, GET_SCHEDULE_LOGS_BY_GAME_ID } from './action';

export const getDiceAccrueSchedulesByGameIdSaga = createPromiseSaga(
    GET_SCHEDULES_BY_GAME_ID,
    api.getDiceAccrueSchedulesByGameId,
);
export const getDiceAccrueScheduleLogsByGameIdSaga = createPromiseSagaWithHeader(
    GET_SCHEDULE_LOGS_BY_GAME_ID,
    api.getDiceAccrueScheduleLogsByGameId,
);

function* createDiceAccrueScheduleSaga(action) {
    const { gameId, score, scheduleType } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.createDiceAccrueSchedule, { gameId, score, scheduleType });

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${CREATE_SCHEDULE}_SUCCESS`, payload: true });

        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: CLOSE_MODAL, payload: 'addDiceAccrueSchedule' });

        yield put({ type: GET_SCHEDULES_BY_GAME_ID, payload: gameId });
    } catch (e) {
        yield put({ type: `${CREATE_SCHEDULE}_ERROR`, payload: false });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* deleteDiceAccrueScheduleSaga(action) {
    const { id, gameId } = action.payload;
    try {
        const { payload: isConfirm } = yield take(CONFIRM);

        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
            yield call(api.deleteDiceAccrueSchedule, id);
            yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

            yield put({ type: OPEN_MODAL, payload: 'successAlert' });

            yield take(CLOSE_MODAL);

            yield put({ type: GET_SCHEDULES_BY_GAME_ID, payload: gameId });
        }
    } catch (e) {
        yield put({ type: `${DELETE_SCHEDULE}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* diceAccrueScheduleSaga() {
    yield takeEvery(GET_SCHEDULES_BY_GAME_ID, getDiceAccrueSchedulesByGameIdSaga);
    yield takeEvery(CREATE_SCHEDULE, createDiceAccrueScheduleSaga);
    yield takeEvery(DELETE_SCHEDULE, deleteDiceAccrueScheduleSaga);
    yield takeEvery(GET_SCHEDULE_LOGS_BY_GAME_ID, getDiceAccrueScheduleLogsByGameIdSaga);
}
