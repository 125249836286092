import { combineActions, handleActions } from 'redux-actions';
import { handleAsyncActions, reducerUtils } from 'src/lib/asyncUtils';
import {
    GET_LATEST_RECORDS,
    GET_LATEST_RECORDS_SUCCESS,
    GET_LATEST_RECORDS_ERROR,
    GET_CURRENT_RANKERS,
    GET_CURRENT_RANKERS_SUCCESS,
    GET_CURRENT_RANKERS_ERROR,
    GET_RANKERS,
    GET_RANKERS_SUCCESS,
    GET_RANKERS_ERROR,
} from './action';

const initialState = {
    records: reducerUtils.initial([]),
    rankers: reducerUtils.initial([]),
};

export default handleActions(
    {
        [combineActions(GET_LATEST_RECORDS, GET_LATEST_RECORDS_SUCCESS, GET_LATEST_RECORDS_ERROR)]: (state, action) =>
            handleAsyncActions(GET_LATEST_RECORDS, 'records', true)(state, action),
        [combineActions(GET_CURRENT_RANKERS, GET_CURRENT_RANKERS_SUCCESS, GET_CURRENT_RANKERS_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_CURRENT_RANKERS, 'rankers', true)(state, action),
        [combineActions(GET_RANKERS, GET_RANKERS_SUCCESS, GET_RANKERS_ERROR)]: (state, action) =>
            handleAsyncActions(GET_RANKERS, 'rankers', true)(state, action),
    },
    initialState,
);
