import { combineActions, handleActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions, handleAsyncActionsWithHeader } from 'src/lib/asyncUtils';
import {
    GET_FIELD_MAP,
    GET_FIELD_MAP_SUCCESS,
    GET_FIELD_MAP_ERROR,
    GET_FIELD_MAPS,
    GET_FIELD_MAPS_SUCCESS,
    GET_FIELD_MAPS_ERROR,
    SEARCH_FIELD_MAPS,
    SEARCH_FIELD_MAPS_SUCCESS,
    SEARCH_FIELD_MAPS_ERROR,
    GET_FIELD_MAPS_FOR_SELECT,
    GET_FIELD_MAPS_FOR_SELECT_SUCCESS,
    GET_FIELD_MAPS_FOR_SELECT_ERROR,
    CREATE_FIELD_MAP,
    CREATE_FIELD_MAP_SUCCESS,
    CREATE_FIELD_MAP_ERROR,
    EDIT_FIELD_MAP,
    EDIT_FIELD_MAP_SUCCESS,
    EDIT_FIELD_MAP_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';

const initialState = {
    result: reducerUtils.initial(false),
    fieldMap: reducerUtils.initial({}),
    fieldMaps: reducerUtils.initial([]),
    searchForm: {},
    isSearch: false,
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(GET_FIELD_MAP, GET_FIELD_MAP_SUCCESS, GET_FIELD_MAP_ERROR)]: (state, action) =>
            handleAsyncActions(GET_FIELD_MAP, 'fieldMap', false)(state, action),
        [combineActions(GET_FIELD_MAPS, GET_FIELD_MAPS_SUCCESS, GET_FIELD_MAPS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(GET_FIELD_MAPS, 'fieldMaps', true)(state, action),
        [combineActions(SEARCH_FIELD_MAPS, SEARCH_FIELD_MAPS_SUCCESS, SEARCH_FIELD_MAPS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(SEARCH_FIELD_MAPS, 'fieldMaps', true)(state, action),
        [combineActions(
            GET_FIELD_MAPS_FOR_SELECT,
            GET_FIELD_MAPS_FOR_SELECT_SUCCESS,
            GET_FIELD_MAPS_FOR_SELECT_ERROR,
        )]: (state, action) => handleAsyncActions(GET_FIELD_MAPS_FOR_SELECT, 'fieldMaps', true)(state, action),
        [combineActions(CREATE_FIELD_MAP, CREATE_FIELD_MAP_SUCCESS, CREATE_FIELD_MAP_ERROR)]: (state, action) =>
            handleAsyncActions(CREATE_FIELD_MAP, 'result', false)(state, action),
        [combineActions(EDIT_FIELD_MAP, EDIT_FIELD_MAP_SUCCESS, EDIT_FIELD_MAP_ERROR)]: (state, action) =>
            handleAsyncActions(EDIT_FIELD_MAP, 'result', false)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
