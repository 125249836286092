import { createAction } from 'redux-actions';

export const GET_CREWS = 'crew/GET_CREWS';
export const GET_CREWS_SUCCESS = 'crew/GET_CREWS_SUCCESS';
export const GET_CREWS_ERROR = 'crew/GET_CREWS_ERROR';

export const getCrews = createAction(GET_CREWS, ({ gameId, page }) => ({ gameId, page }));

export const SEARCH_CREWS = 'crew/SEARCH_CREWS';
export const SEARCH_CREWS_SUCCESS = 'crew/SEARCH_CREWS_SUCCESS';
export const SEARCH_CREWS_ERROR = 'crew/SEARCH_CREWS_ERROR';

export const searchCrews = createAction(SEARCH_CREWS, ({ gameId, page, param }) => ({ gameId, page, param }));

export const GET_CREW_DETAIL = 'crew/GET_CREW_DETAIL';
export const GET_CREW_DETAIL_SUCCESS = 'crew/GET_CREW_DETAIL_SUCCESS';
export const GET_CREW_DETAIL_ERROR = 'crew/GET_CREW_DETAIL_ERROR';

export const getCrewDetail = createAction(GET_CREW_DETAIL, ({ gameId, id }) => ({ gameId, id }));

export const ON_CHANGE = 'crew/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'crew/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
