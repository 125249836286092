import { put, take, takeEvery, call } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga } from 'src/lib/asyncUtils';
import { PERIOD_BAN, PERIOD_UNBAN, GET_PERIOD_BAN_STATE, SET_BAN_TARGET, ON_CHANGE } from './action';
import { GET_PLAYER_FROM_FIREBASE } from 'src/store/modules/player';
import { OPEN_MODAL, CLOSE_MODAL, CONFIRM_WITH, CONFIRM } from 'src/store/modules/modal';

const getPeriodBanStateSaga = createPromiseSaga(GET_PERIOD_BAN_STATE, api.getPeriodBanState);

function* banSaga(action) {
    const { payload: isConfirm } = yield take(CONFIRM);
    const { payload } = action;

    try {
        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
            yield call(api.periodBan, payload);
            yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

            yield put({ type: `${PERIOD_BAN}_SUCCESS`, payload: true });
            yield put({ type: OPEN_MODAL, payload: 'successAlert' });

            yield take(CLOSE_MODAL);

            yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
            yield put({ type: GET_PERIOD_BAN_STATE, payload: payload.playerId });
            yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        }
    } catch (e) {
        yield put({ type: `${PERIOD_BAN}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* unbanSaga(action) {
    const { payload: isConfirm } = yield take(CONFIRM);
    const { payload } = action;

    try {
        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
            yield call(api.periodUnban, payload);
            yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

            yield put({ type: `${PERIOD_UNBAN}_SUCCESS`, payload: true });
            yield put({ type: OPEN_MODAL, payload: 'successAlert' });

            yield take(CLOSE_MODAL);

            yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
            yield put({ type: GET_PERIOD_BAN_STATE, payload: payload.playerId });
            yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        }
    } catch (e) {
        yield put({ type: `${PERIOD_UNBAN}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* setBanTargetSaga() {
    try {
        const { payload } = yield take(CONFIRM_WITH);
        const { isConfirm, ...param } = payload;
        const { gameId, database, playerId } = param;

        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'loadingAlert' });
            const { data: player } = yield call(api.getPlayerFromFirebaseWithIntl, {
                gameId,
                database,
                playerId,
            });
            yield put({ type: GET_PERIOD_BAN_STATE, payload: playerId });

            yield put({ type: `${GET_PLAYER_FROM_FIREBASE}_SUCCESS`, payload: player });
            yield put({ type: ON_CHANGE, payload: { name: 'target', value: { database, playerId } } });
            yield put({ type: CLOSE_MODAL, payload: 'loadingAlert' });
        }

        yield put({ type: CLOSE_MODAL, payload: 'playerSearch' });
    } catch (e) {
        yield put({ type: `${SET_BAN_TARGET}_ERROR` });
        yield put({ type: CLOSE_MODAL, payload: 'loadingAlert' });
    }
}

export default function* periodBanSaga() {
    yield takeEvery(PERIOD_BAN, banSaga);
    yield takeEvery(PERIOD_UNBAN, unbanSaga);
    yield takeEvery(SET_BAN_TARGET, setBanTargetSaga);
    yield takeEvery(GET_PERIOD_BAN_STATE, getPeriodBanStateSaga);
}
