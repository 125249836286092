import { createAction } from 'redux-actions';

export const GET_MANUAL_PROVIDE_LOGS = 'manualProvideLog/GET_MANUAL_PROVIDE_LOGS';
export const GET_MANUAL_PROVIDE_LOGS_SUCCESS = 'manualProvideLog/GET_MANUAL_PROVIDE_LOGS_SUCCESS';
export const GET_MANUAL_PROVIDE_LOGS_ERROR = 'manualProvideLog/GET_MANUAL_PROVIDE_LOGS_ERROR';

export const getManualProvideLogs = createAction(GET_MANUAL_PROVIDE_LOGS, ({ page, limit }) => ({ page, limit }));

export const SEARCH_MANUAL_PROVIDE_LOGS = 'manualProvideLog/SEARCH_MANUAL_PROVIDE_LOGS';
export const SEARCH_MANUAL_PROVIDE_LOGS_SUCCESS = 'manualProvideLog/SEARCH_MANUAL_PROVIDE_LOGS_SUCCESS';
export const SEARCH_MANUAL_PROVIDE_LOGS_ERROR = 'manualProvideLog/SEARCH_MANUAL_PROVIDE_LOGS_ERROR';

export const searchManualProvideLogs = createAction(SEARCH_MANUAL_PROVIDE_LOGS, ({ page, limit, param }) => ({
    page,
    limit,
    param,
}));

export const ON_CHANGE = 'manualProvdeLog/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'manualProvideLog/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
