import { call, take, put, delay, takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { REGISTER, LOGIN, CHECK, LOGOUT } from './action';
import { OPEN_MODAL, CLOSE_MODAL } from 'src/store/modules/modal';

function* registerSaga(action) {
    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        yield call(api.register, action.payload);
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${REGISTER}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);
        window.location.href = '/#/login';
    } catch (e) {
        yield put({ type: `${REGISTER}_ERROR`, error: true, payload: e });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
    }
}

function* loginSaga(action) {
    try {
        yield delay(1000);

        const data = yield call(api.login, action.payload);

        yield put({ type: `${LOGIN}_SUCCESS`, payload: data });
        window.location.hash = '#/dashboard';
    } catch (e) {
        yield put({ type: `${LOGIN}_ERROR`, error: true, payload: true });
    }
}

function* checkSaga() {
    try {
        const data = yield call(api.check);

        yield put({ type: `${CHECK}_SUCCESS`, payload: data });

        if (window.location.hash === '#/login') {
            window.location.hash = '#/dashboard';
        }
    } catch (e) {
        yield put({ type: `${CHECK}_ERROR`, error: true, payload: true });
        window.location.hash = '#/login';
    }
}

function* logoutSaga() {
    try {
        yield call(api.logout);

        yield put({ type: `${LOGOUT}_SUCCESS` });

        window.location.hash = '#/login';
    } catch (e) {
        yield put({ type: `${LOGOUT}_ERROR` });
        window.location.hash = '#/login';
    }
}

export default function* authSaga() {
    yield takeEvery(REGISTER, registerSaga);
    yield takeEvery(LOGIN, loginSaga);
    yield takeEvery(CHECK, checkSaga);
    yield takeEvery(LOGOUT, logoutSaga);
}
