import { call, put, takeEvery, select } from 'redux-saga/effects';
import { createPromiseSaga } from 'src/lib/asyncUtils';
import * as api from 'src/lib/api';
import { GET_LATEST_RECORDS, GET_CURRENT_RANKERS, GET_RANKERS } from './action';

const getLatestRecordsSaga = createPromiseSaga(GET_LATEST_RECORDS, api.getLatestRecords);
const getRankersSaga = createPromiseSaga(GET_RANKERS, api.getRankers);

function* getCurrentRankersSaga(action) {
    const { camp, start, end } = action.payload;

    try {
        const { data } = yield call(api.getCurrentRankers, { camp, start, end });

        if (start === 0) {
            yield put({ type: `${GET_CURRENT_RANKERS}_SUCCESS`, error: true, payload: data });
        } else {
            const { data: prevData } = yield select((state) => state.leaderboard.rankers);

            yield put({ type: `${GET_CURRENT_RANKERS}_SUCCESS`, error: true, payload: [...prevData, ...data] });
        }
    } catch (e) {
        yield put({ type: `${GET_CURRENT_RANKERS}_ERROR`, error: true, payload: e });
    }
}

export default function* leaderboardSaga() {
    yield takeEvery(GET_LATEST_RECORDS, getLatestRecordsSaga);
    yield takeEvery(GET_CURRENT_RANKERS, getCurrentRankersSaga);
    yield takeEvery(GET_RANKERS, getRankersSaga);
}
