import { handleActions, combineActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions } from 'src/lib/asyncUtils';
import {
    GET_MY_NOTIFICATIONS,
    GET_MY_NOTIFICATIONS_SUCCESS,
    GET_MY_NOTIFICATIONS_ERROR,
    UPDATE_TO_READ,
    UPDATE_TO_READ_SUCCESS,
    UPDATE_TO_READ_ERROR,
} from './action';

const initialState = {
    result: reducerUtils.initial(false),
    notifications: reducerUtils.initial([]),
};

export default handleActions(
    {
        [combineActions(GET_MY_NOTIFICATIONS, GET_MY_NOTIFICATIONS_SUCCESS, GET_MY_NOTIFICATIONS_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_MY_NOTIFICATIONS, 'notifications', true)(state, action),
        [combineActions(UPDATE_TO_READ, UPDATE_TO_READ_SUCCESS, UPDATE_TO_READ_ERROR)]: (state, action) =>
            handleAsyncActions(UPDATE_TO_READ, 'result', false)(state, action),
    },
    initialState,
);
