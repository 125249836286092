import { takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import { GET_PERIOD_BAN_LOGS, SEARCH_PERIOD_BAN_LOGS } from './action';

const getPeriodBanLogsSaga = createPromiseSagaWithHeader(GET_PERIOD_BAN_LOGS, api.getPeriodBanLogs);
const searchPeriodBanLogsSaga = createPromiseSagaWithHeader(SEARCH_PERIOD_BAN_LOGS, api.searchPeriodBanLogs);

export default function* periodBanLogSaga() {
    yield takeEvery(GET_PERIOD_BAN_LOGS, getPeriodBanLogsSaga);
    yield takeEvery(SEARCH_PERIOD_BAN_LOGS, searchPeriodBanLogsSaga);
}
