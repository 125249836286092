import { createAction } from 'redux-actions';

export const GET_REPORT_SCHEDULES = 'reportSchedules/GET_REPORT_SCHEDULES';
export const GET_REPORT_SCHEDULES_SUCCESS = 'reportSchedules/GET_REPORT_SCHEDULES_SUCCESS';
export const GET_REPORT_SCHEDULES_ERROR = 'reportSchedules/GET_REPORT_SCHEDULES_ERROR';

export const getReportSchedules = createAction(GET_REPORT_SCHEDULES, ({ page, limit }) => ({
    page,
    limit,
}));

export const SEARCH_REPORT_SCHEDULES = 'reportSchedules/SEARCH_REPORT_SCHEDULES';
export const SEARCH_REPORT_SCHEDULES_SUCCESS = 'reportSchedules/SEARCH_REPORT_SCHEDULES_SUCCESS';
export const SEARCH_REPORT_SCHEDULES_ERROR = 'reportSchedules/SEARCH_REPORT_SCHEDULES_ERROR';

export const searchReportSchedules = createAction(SEARCH_REPORT_SCHEDULES, ({ page, limit, param }) => ({
    page,
    limit,
    param,
}));

export const ON_CHANGE = 'reportSchedules/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'reportSchedules/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
