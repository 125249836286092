import { combineActions, handleActions } from 'redux-actions';
import { reducerUtils, handleAsyncActionsWithHeader } from 'src/lib/asyncUtils';
import {
    GET_REPORT_SCHEDULES,
    GET_REPORT_SCHEDULES_SUCCESS,
    GET_REPORT_SCHEDULES_ERROR,
    SEARCH_REPORT_SCHEDULES,
    SEARCH_REPORT_SCHEDULES_SUCCESS,
    SEARCH_REPORT_SCHEDULES_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';

const initialState = {
    reportSchedules: reducerUtils.initial([]),
    searchForm: reducerUtils.initial({}),
    isSearch: false,
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(GET_REPORT_SCHEDULES_ERROR, GET_REPORT_SCHEDULES_SUCCESS, GET_REPORT_SCHEDULES_ERROR)]: (
            state,
            action,
        ) => handleAsyncActionsWithHeader(GET_REPORT_SCHEDULES, 'reportSchedules', true)(state, action),
        [combineActions(SEARCH_REPORT_SCHEDULES, SEARCH_REPORT_SCHEDULES_SUCCESS, SEARCH_REPORT_SCHEDULES_ERROR)]: (
            state,
            action,
        ) => handleAsyncActionsWithHeader(SEARCH_REPORT_SCHEDULES, 'reportSchedules', true)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
