import { createAction } from 'redux-actions';

export const GET_PROVIDE_LOG_BY_OID = 'provideLog/GET_PROVIDE_LOG_BY_OID';
export const GET_PROVIDE_LOG_BY_OID_SUCCESS = 'provideLog/GET_PROVIDE_LOG_BY_OID_SUCCESS';
export const GET_PROVIDE_LOG_BY_OID_ERROR = 'provideLog/GET_PROVIDE_LOG_BY_OID_ERROR';

export const getProvideLogByOid = createAction(GET_PROVIDE_LOG_BY_OID, (id) => id);

export const GET_PROVIDE_LOGS_BY_ORDER_ID = 'provideLog/GET_PROVIDE_LOGS_BY_ORDER_ID';
export const GET_PROVIDE_LOGS_BY_ORDER_ID_SUCCESS = 'provideLog/GET_PROVIDE_LOGS_BY_ORDER_ID_SUCCESS';
export const GET_PROVIDE_LOGS_BY_ORDER_ID_ERROR = 'provideLog/GET_PROVIDE_LOGS_BY_ORDER_ID_ERROR';

export const getProvideLogsByOrderId = createAction(GET_PROVIDE_LOGS_BY_ORDER_ID, ({ gameId, orderId }) => ({
    gameId,
    orderId,
}));

export const GET_PROVIDE_LOGS_TO = 'provideLog/GET_PROVIDE_LOGS_TO';
export const GET_PROVIDE_LOGS_TO_SUCCESS = 'provideLog/GET_PROVIDE_LOGS_TO_SUCCESS';
export const GET_PROVIDE_LOGS_TO_ERROR = 'provideLog/GET_PROVIDE_LOGS_TO_ERROR';

export const getProvideLogsTo = createAction(GET_PROVIDE_LOGS_TO, ({ gameId, id }) => ({ gameId, id }));

export const GET_PROVIDE_LOGS = 'provideLog/GET_PROVIDE_LOGS';
export const GET_PROVIDE_LOGS_SUCCESS = 'provideLog/GET_PROVIDE_LOGS_SUCCESS';
export const GET_PROVIDE_LOGS_ERROR = 'provideLog/GET_PROVIDE_LOGS_ERROR';

export const getProvideLogs = createAction(GET_PROVIDE_LOGS, ({ page, limit }) => ({ page, limit }));

export const SEARCH_PROVIDE_LOGS = 'provideLog/SEARCH_PROVIDE_LOGS';
export const SEARCH_PROVIDE_LOGS_SUCCESS = 'provideLog/SEARCH_PROVIDE_LOGS_SUCCESS';
export const SEARCH_PROVIDE_LOGS_ERROR = 'provideLog/SEARCH_PROVIDE_LOGS_ERROR';

export const searchProvideLogs = createAction(SEARCH_PROVIDE_LOGS, ({ page, limit, param }) => ({
    param,
    page,
    limit,
}));

export const ON_CHANGE = 'provideLog/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'provideLog/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
