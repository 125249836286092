import { takeEvery, call, put, take } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import {
    PROVIDE_PACKAGE,
    PROVIDE_CUSTOM,
    PROVIDE_TOKEN,
    PROVIDE_PASS,
    PROVIDE_SKIN,
    PROVIDE_EMBLEM,
    PROVIDE_EMOTICON,
    PROVIDE_CUSTOM_TO_CREW,
    SET_PROVIDE_TARGET,
    PROVIDE_LEGEND_DICE,
} from './action';
import { OPEN_MODAL, CLOSE_MODAL, CONFIRM_WITH } from 'src/store/modules/modal';
import { GET_PROVIDE_LOGS_TO } from 'src/store/modules/provideLog';
import { GET_PLAYER_FROM_FIREBASE } from '../player';

function* providePackageSaga(action) {
    const { gameId, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        const { data } = yield call(api.providePackage, { gameId, param });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${PROVIDE_PACKAGE}_SUCCESS`, payload: data });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });
    } catch (e) {
        yield put({ type: `${PROVIDE_PACKAGE}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* provideCustomSaga(action) {
    const { gameId, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        const { data } = yield call(api.provideCustom, { gameId, param });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${PROVIDE_CUSTOM}_SUCCESS`, payload: data });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });
    } catch (e) {
        yield put({ type: `${PROVIDE_CUSTOM}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* provideLegendDiceSaga(action) {
    const { gameId, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        const { data } = yield call(api.provideLegendDice, { gameId, param });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${PROVIDE_LEGEND_DICE}_SUCCESS`, payload: data });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });
    } catch (e) {
        yield put({ type: `${PROVIDE_LEGEND_DICE}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* provideTokenSaga(action) {
    const { gameId, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        const { data } = yield call(api.provideToken, { gameId, param });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${PROVIDE_TOKEN}_SUCCESS`, payload: data });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });
    } catch (e) {
        yield put({ type: `${PROVIDE_TOKEN}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* providePassSaga(action) {
    const { gameId, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        const { data } = yield call(api.providePass, { gameId, param });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${PROVIDE_PASS}_SUCCESS`, payload: data });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });
    } catch (e) {
        yield put({ type: `${PROVIDE_TOKEN}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* provideSkinSaga(action) {
    const { gameId, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        const { data } = yield call(api.provideSkin, { gameId, param });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${PROVIDE_SKIN}_SUCCESS`, payload: data });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });
    } catch (e) {
        yield put({ type: `${PROVIDE_SKIN}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* provideEmblemSaga(action) {
    const { gameId, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        const { data } = yield call(api.provideEmblem, { gameId, param });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${PROVIDE_EMBLEM}_SUCCESS`, payload: data });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });
    } catch (e) {
        yield put({ type: `${PROVIDE_EMBLEM}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* provideEmoticonSaga(action) {
    const { gameId, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        const { data } = yield call(api.provideEmoticon, { gameId, param });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${PROVIDE_EMOTICON}_SUCCESS`, payload: data });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });
    } catch (e) {
        yield put({ type: `${PROVIDE_EMOTICON}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* provideCustomToCrewSaga(action) {
    const { gameId, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        const { data } = yield call(api.provideCustomToCrew, { gameId, param });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${PROVIDE_CUSTOM_TO_CREW}_SUCCESS`, payload: data });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });
    } catch (e) {
        yield put({ type: `${PROVIDE_CUSTOM_TO_CREW}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* setProvideTargetSaga() {
    try {
        const { payload } = yield take(CONFIRM_WITH);
        const { isConfirm, ...param } = payload;
        const { gameId, database, playerId } = param;

        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'loadingAlert' });
            yield put({ type: GET_PLAYER_FROM_FIREBASE, payload: { gameId, database, playerId } });
            yield put({ type: GET_PROVIDE_LOGS_TO, payload: { gameId, id: playerId } });
            yield put({ type: CLOSE_MODAL, payload: 'loadingAlert' });
        }

        yield put({ type: CLOSE_MODAL, payload: 'playerSearch' });
    } catch (e) {
        yield put({ type: `${SET_PROVIDE_TARGET}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
        yield put({ type: CLOSE_MODAL, payload: 'loadingAlert' });
    }
}

export default function* provideSaga() {
    yield takeEvery(PROVIDE_PACKAGE, providePackageSaga);
    yield takeEvery(PROVIDE_CUSTOM, provideCustomSaga);
    yield takeEvery(PROVIDE_LEGEND_DICE, provideLegendDiceSaga);
    yield takeEvery(PROVIDE_TOKEN, provideTokenSaga);
    yield takeEvery(PROVIDE_PASS, providePassSaga);
    yield takeEvery(PROVIDE_SKIN, provideSkinSaga);
    yield takeEvery(PROVIDE_EMBLEM, provideEmblemSaga);
    yield takeEvery(PROVIDE_EMOTICON, provideEmoticonSaga);
    yield takeEvery(PROVIDE_CUSTOM_TO_CREW, provideCustomToCrewSaga);
    yield takeEvery(SET_PROVIDE_TARGET, setProvideTargetSaga);
}
