import { createAction } from 'redux-actions';

export const GET_SCHEDULES_BY_GAME_ID = 'diceAccrueSchedule/GET_SCHEDULES_BY_GAME_ID';
export const GET_SCHEDULES_BY_GAME_ID_SUCCESS = 'diceAccrueSchedule/GET_SCHEDULES_BY_GAME_ID_SUCCESS';
export const GET_SCHEDULES_BY_GAME_ID_ERROR = 'diceAccrueSchedule/GET_SCHEDULES_BY_GAME_ID_ERROR';

export const getSchedulesByGameId = createAction(GET_SCHEDULES_BY_GAME_ID, (gameId) => gameId);

export const CREATE_SCHEDULE = 'diceAccrueSchedule/CREATE_SCHEDULE';
export const CREATE_SCHEDULE_SUCCESS = 'diceAccrueSchedule/CREATE_SCHEDULE_SUCCESS';
export const CREATE_SCHEDULE_ERROR = 'diceAccrueSchedule/CREATE_SCHEDULE_ERROR';

export const createSchedule = createAction(CREATE_SCHEDULE, ({ gameId, score, scheduleType }) => ({
    gameId,
    score,
    scheduleType,
}));

export const DELETE_SCHEDULE = 'diceAccrueSchedule/DELETE_SCHEDULE';
export const DELETE_SCHEDULE_SUCCESS = 'diceAccrueSchedule/DELETE_SCHEDULE_SUCCESS';
export const DELETE_SCHEDULE_ERROR = 'diceAccrueSchedule/DELETE_SCHEDULE_ERROR';

export const deleteSchedule = createAction(DELETE_SCHEDULE, ({ id, gameId }) => ({ id, gameId }));

export const GET_SCHEDULE_LOGS_BY_GAME_ID = 'diceAccrueSchedule/GET_GET_SCHEDULE_LOGS_BY_GAME_ID';
export const GET_SCHEDULE_LOGS_BY_GAME_ID_SUCCESS = 'diceAccrueSchedule/GET_GET_SCHEDULE_LOGS_BY_GAME_ID_SUCCESS';
export const GET_SCHEDULE_LOGS_BY_GAME_ID_ERROR = 'diceAccrueSchedule/GET_GET_SCHEDULE_LOGS_BY_GAME_ID_ERROR';

export const getScheduleLogsByGameId = createAction(GET_SCHEDULE_LOGS_BY_GAME_ID, ({ gameId, page, limit }) => ({
    gameId,
    page,
    limit,
}));

export const ON_CHANGE = 'diceAccrueSchedule/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'diceAccrueSchedule/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
