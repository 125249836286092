import { takeEvery, take, put, call } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import { OPEN_MODAL, CLOSE_MODAL } from 'src/store/modules/modal';
import { GET_LOGS_BY_PID, DOWNLOAD_LOGS } from './action';

export const getLogsByPidSaga = createPromiseSagaWithHeader(GET_LOGS_BY_PID, api.getDiceAccrueLogsByPid);

function* downloadLogsSaga(action) {
    const { gameId, date, score } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        const { data: result } = yield call(api.downloadDiceAccrueLogs, { gameId, date, score });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${DOWNLOAD_LOGS}_SUCCESS`, payload: result });
        yield put({ type: OPEN_MODAL, payload: 'downloadSuccessAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: CLOSE_MODAL, payload: 'diceAccrueLogDownload' });
    } catch (e) {
        yield put({ type: `${DOWNLOAD_LOGS}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* diceAccrueSaga() {
    yield takeEvery(GET_LOGS_BY_PID, getLogsByPidSaga);
    yield takeEvery(DOWNLOAD_LOGS, downloadLogsSaga);
}
