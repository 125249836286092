import { combineActions, handleActions } from 'redux-actions';
import moment from 'moment-timezone';
import { reducerUtils, handleAsyncActions, handleAsyncActionsWithHeader } from 'src/lib/asyncUtils';
import {
    GET_BACKUP_CRAWLERS,
    GET_BACKUP_CRAWLERS_SUCCESS,
    GET_BACKUP_CRAWLERS_ERROR,
    SEARCH_BACKUP_CRAWLERS,
    SEARCH_BACKUP_CRAWLERS_SUCCESS,
    SEARCH_BACKUP_CRAWLERS_ERROR,
    CREATE_BACKUP_CRAWLERS,
    CREATE_BACKUP_CRAWLERS_SUCCESS,
    CREATE_BACKUP_CRAWLERS_ERROR,
    RESTORE_BY_BACKUP_CRAWLER,
    RESTORE_BY_BACKUP_CRAWLER_SUCCESS,
    RESTORE_BY_BACKUP_CRAWLER_ERROR,
    ON_CHANGE,
} from './action';

const initialState = {
    result: reducerUtils.initial(false),
    crawlers: reducerUtils.initial([]),
    isSearch: false,
    searchForm: {
        gameId: '',
        statusOfStep: '',
        ymd: moment.tz(Date.now(), 'Asia/Seoul').subtract(1, 'days').format('YYYY-MM-DD'),
    },
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(GET_BACKUP_CRAWLERS, GET_BACKUP_CRAWLERS_SUCCESS, GET_BACKUP_CRAWLERS_ERROR)]: (
            state,
            action,
        ) => handleAsyncActionsWithHeader(GET_BACKUP_CRAWLERS, 'crawlers', true)(state, action),
        [combineActions(SEARCH_BACKUP_CRAWLERS, SEARCH_BACKUP_CRAWLERS_SUCCESS, SEARCH_BACKUP_CRAWLERS_ERROR)]: (
            state,
            action,
        ) => handleAsyncActionsWithHeader(SEARCH_BACKUP_CRAWLERS, 'crawlers', true)(state, action),
        [combineActions(CREATE_BACKUP_CRAWLERS, CREATE_BACKUP_CRAWLERS_SUCCESS, CREATE_BACKUP_CRAWLERS_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(CREATE_BACKUP_CRAWLERS, 'result', false)(state, action),
        [combineActions(
            RESTORE_BY_BACKUP_CRAWLER,
            RESTORE_BY_BACKUP_CRAWLER_SUCCESS,
            RESTORE_BY_BACKUP_CRAWLER_ERROR,
        )]: (state, action) => handleAsyncActions(RESTORE_BY_BACKUP_CRAWLER, 'result', false)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
    },
    initialState,
);
