import { createAction } from 'redux-actions';

export const GET_MY_RECOVER_LOGS = 'recoverLog/GET_MY_RECOVER_LOGS';
export const GET_MY_RECOVER_LOGS_SUCCESS = 'recoverLog/GET_MY_RECOVER_LOGS_SUCCESS';
export const GET_MY_RECOVER_LOGS_ERROR = 'recoverLog/GET_MY_RECOVER_LOGS_ERROR';

export const getMyRecoverLogs = createAction(GET_MY_RECOVER_LOGS, (gameId) => gameId);

export const GET_RECOVER_LOGS = 'recoverLog/GET_RECOVER_LOGS';
export const GET_RECOVER_LOGS_SUCCESS = 'recoverLog/GET_RECOVER_LOGS_SUCCESS';
export const GET_RECOVER_LOGS_ERROR = 'recoverLog/GET_RECOVER_LOGS_ERROR';

export const getRecoverLogs = createAction(GET_RECOVER_LOGS, ({ page, limit }) => ({ page, limit }));

export const SEARCH_RECOVER_LOGS = 'recoverLog/SEARCH_RECOVER_LOGS';
export const SEARCH_RECOVER_LOGS_SUCCESS = 'recoverLog/SEARCH_RECOVER_LOGS_SUCCESS';
export const SEARCH_RECOVER_LOGS_ERROR = 'recoverLog/SEARCH_RECOVER_LOGS_ERROR';

export const searchRecoverLogs = createAction(SEARCH_RECOVER_LOGS, ({ page, limit, param }) => ({
    page,
    limit,
    param,
}));

export const EXPORT_SOURCE_PLAYER_SNAPSHOT = 'recoverLog/EXPORT_SOURCE_PLAYER_SNAPSHOT';
export const EXPORT_SOURCE_PLAYER_SNAPSHOT_SUCCESS = 'recoverLog/EXPORT_SOURCE_PLAYER_SNAPSHOT_SUCCESS';
export const EXPORT_SOURCE_PLAYER_SNAPSHOT_ERROR = 'recoverLog/EXPORT_SOURCE_PLAYER_SNAPSHOT_ERROR';

export const exportSourcePlayerSnapshot = createAction(EXPORT_SOURCE_PLAYER_SNAPSHOT, (oid) => oid);

export const ON_CHANGE = 'recoverLog/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'recoverLog/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
