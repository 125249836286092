import { handleActions } from 'redux-actions';
import { ALL_CLOSE_MODAL } from '.';
import { OPEN_MODAL, CLOSE_MODAL, CUSTOM_MESSAGE } from './action';

const initialState = {
    addGame: false,
    editGame: false,
    addMetadata: false,
    addFilter: false,
    editMetadata: false,
    editFilter: false,
    playerDetail: false,
    playerLog: false,
    playerDataCompare: false,
    receiptDetail: false,
    createCmCodeMajor: false,
    editCmCodeMajor: false,
    editAccount: false,
    provideLogDetail: false,
    createFieldMap: false,
    editFieldMap: false,
    successAlert: false,
    failAlert: false,
    processingAlert: false,
    loadingAlert: false,
    createDummyRanker: false,
    editRank: false,
    crewDetail: false,
    noticeDetail: false,
    addCheatPlayers: false,
    crewMailLog: false,
    diceAccrueMetadata: false,
    diceAccrueLog: false,
    diceAccrueLogDownload: false,
    diceAccrueSchedule: false,
    addDiceAccrueSchedule: false,
    customMessage: '',
    logReportsMetadata: false,
};

export default handleActions(
    {
        [OPEN_MODAL]: (state, action) => {
            const name = action.payload;

            return {
                ...state,
                [name]: true,
            };
        },
        [CLOSE_MODAL]: (state, action) => {
            const name = action.payload;

            return {
                ...state,
                [name]: false,
            };
        },
        [ALL_CLOSE_MODAL]: () => {
            return JSON.parse(JSON.stringify(initialState));
        },
        [CUSTOM_MESSAGE]: (state, action) => {
            const { payload } = action;

            return {
                ...state,
                customMessage: payload,
            };
        },
    },
    initialState,
);
