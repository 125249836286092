import { createAction } from 'redux-actions';

export const GET_DATA_PRESET_LOGS = 'dataPresetLogs/GET_DATA_PRESET_LOGS';
export const GET_DATA_PRESET_LOGS_SUCCESS = 'dataPresetLogs/GET_DATA_PRESET_LOGS_SUCCESS';
export const GET_DATA_PRESET_LOGS_ERROR = 'dataPresetLogs/GET_DATA_PRESET_LOGS_ERROR';

export const getDataPresetLogs = createAction(GET_DATA_PRESET_LOGS, (param) => param);

export const SEARCH_DATA_PRESET_LOGS = 'dataPresetLogs/SEARCH_DATA_PRESET_LOGS';
export const SEARCH_DATA_PRESET_LOGS_SUCCESS = 'dataPresetLogs/SEARCH_DATA_PRESET_LOGS_SUCCESS';
export const SEARCH_DATA_PRESET_LOGS_ERROR = 'dataPresetLogs/SEARCH_DATA_PRESET_LOGS_ERROR';

export const searchDataPresetLogs = createAction(SEARCH_DATA_PRESET_LOGS, (param) => param);

export const DOWNLOAD_DATA_PRESET_BACKUP_LOG = 'dataPresetLogs/DOWNLOAD_DATA_PRESET_BACKUP_LOG';
export const DOWNLOAD_DATA_PRESET_BACKUP_LOG_SUCCESS = 'dataPresetLogs/DOWNLOAD_DATA_PRESET_BACKUP_LOG_SUCCESS';
export const DOWNLOAD_DATA_PRESET_BACKUP_LOG_ERROR = 'dataPresetLogs/DOWNLOAD_DATA_PRESET_BACKUP_LOG_ERROR';

export const downloadDataPresetBackupLog = createAction(DOWNLOAD_DATA_PRESET_BACKUP_LOG, (presetLogId) => presetLogId);

export const ON_CHANGE = 'dataPresetLogs/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (param) => param);

export const INITIALIZE = 'dataPresetLogs/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
