import { createAction } from 'redux-actions';

export const ADD_PERIOD_BAN_PLAYERS = 'periodBanPlayer/ADD_PERIOD_BAN_PLAYERS';
export const ADD_PERIOD_BAN_PLAYERS_SUCCESS = 'periodBanPlayer/ADD_PERIOD_BAN_PLAYERS_SUCCESS';
export const ADD_PERIOD_BAN_PLAYERS_ERROR = 'periodBanPlayer/ADD_PERIOD_BAN_PLAYERS_ERROR';

export const addPeriodBanPlayers = createAction(ADD_PERIOD_BAN_PLAYERS, (payload) => payload);

export const GET_PERIOD_BAN_PLAYERS = 'periodBanPlayer/GET_PERIOD_BAN_PLAYERS';
export const GET_PERIOD_BAN_PLAYERS_SUCCESS = 'periodBanPlayer/GET_PERIOD_BAN_PLAYERS_SUCCESS';
export const GET_PERIOD_BAN_PLAYERS_ERROR = 'periodBanPlayer/GET_PERIOD_BAN_PLAYERS_ERROR';

export const getPeriodBanPlayers = createAction(GET_PERIOD_BAN_PLAYERS, ({ page, limit }) => ({ page, limit }));

export const SEARCH_PERIOD_BAN_PLAYERS = 'periodBanPlayer/SEARCH_PERIOD_BAN_PLAYERS';
export const SEARCH_PERIOD_BAN_PLAYERS_SUCCESS = 'periodBanPlayer/SEARCH_PERIOD_BAN_PLAYERS_SUCCESS';
export const SEARCH_PERIOD_BAN_PLAYERS_ERROR = 'periodBanPlayer/SEARCH_PERIOD_BAN_PLAYERS_ERROR';

export const searchPeriodBanPlayers = createAction(SEARCH_PERIOD_BAN_PLAYERS, ({ page, limit, param }) => ({
    page,
    limit,
    param,
}));

export const DELETE_PERIOD_BAN_PLAYER = 'periodBanPlayer/DELETE_PERIOD_BAN_PLAYER';
export const DELETE_PERIOD_BAN_PLAYER_SUCCESS = 'periodBanPlayer/DELETE_PERIOD_BAN_PLAYER_SUCCESS';
export const DELETE_PERIOD_BAN_PLAYER_ERROR = 'periodBanPlayer/DELETE_PERIOD_BAN_PLAYER_ERROR';

export const deletePeriodBanPlayer = createAction(DELETE_PERIOD_BAN_PLAYER, (id) => id);

export const ON_CHANGE = 'periodBanPlayer/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'periodBanPlayer/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
