import { put, call, take, takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import { CLOSE_MODAL, OPEN_MODAL } from '../modal';
import { GET_METADATA_BY_OID, GET_METADATAS_FOR_SELECT, EDIT_METADATA, GET_RESULTS, SEARCH_RESULTS } from './action';

export const getMetadataByOidSaga = createPromiseSaga(GET_METADATA_BY_OID, api.getDiceAccrueMetadataByOid);
export const getMetadatasForSelectSaga = createPromiseSaga(
    GET_METADATAS_FOR_SELECT,
    api.getDiceAccrueMetadatasForSelect,
);
export const getResultsSaga = createPromiseSagaWithHeader(GET_RESULTS, api.getDiceAccrueResults);
export const searchResultsSaga = createPromiseSagaWithHeader(SEARCH_RESULTS, api.searchDiceAccrueResults);

function* editMetadataSaga(action) {
    const { oid, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.editDiceAccrueMetadata, { oid, param });

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${EDIT_METADATA}_SUCCESS`, payload: true });

        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: CLOSE_MODAL, payload: 'diceAccrueMetadata' });
    } catch (e) {
        yield put({ type: `${EDIT_METADATA}_ERROR`, payload: false });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* diceAccrueSaga() {
    yield takeEvery(GET_METADATA_BY_OID, getMetadataByOidSaga);
    yield takeEvery(GET_METADATAS_FOR_SELECT, getMetadatasForSelectSaga);
    yield takeEvery(EDIT_METADATA, editMetadataSaga);
    yield takeEvery(GET_RESULTS, getResultsSaga);
    yield takeEvery(SEARCH_RESULTS, searchResultsSaga);
}
