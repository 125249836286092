import { createAction } from 'redux-actions';

export const GET_GAME_BY_OID = 'game/GET_GAME_BY_OID';
export const GET_GAME_BY_OID_SUCCESS = 'game/GET_GAME_BY_OID_SUCCESS';
export const GET_GAME_BY_OID_ERROR = 'game/GET_GAME_BY_OID_ERROR';

export const getGameByOid = createAction(GET_GAME_BY_OID, (id) => id);

export const GET_MAPPED_CODES = 'game/GET_MAPPED_CODES';
export const GET_MAPPED_CODES_SUCCESS = 'game/GET_MAPPED_CODES_SUCCESS';
export const GET_MAPPED_CODES_ERROR = 'game/GET_MAPPED_CODES_ERROR';

export const getMappedCodes = createAction(GET_MAPPED_CODES, ({ gameId, codeType }) => ({ gameId, codeType }));

export const GET_GAMES = 'game/GET_GAMES';
export const GET_GAMES_SUCCESS = 'game/GET_GAMES_SUCCESS';
export const GET_GAMES_ERROR = 'game/GET_GAMES_ERROR';

export const getGames = createAction(GET_GAMES, ({ page, limit }) => ({ page, limit }));

export const GET_GAMES_FOR_SELECT = 'game/GET_GAMES_FOR_SELECT';
export const GET_GAMES_FOR_SELECT_SUCCESS = 'game/GET_GAMES_FOR_SELECT_SUCCESS';
export const GET_GAMES_FOR_SELECT_ERROR = 'game/GET_GAMES_FOR_SELECT_ERROR';

export const getGamesForSelect = createAction(GET_GAMES_FOR_SELECT);

export const ADD_GAME = 'game/ADD_GAME';
export const ADD_GAME_SUCCESS = 'game/ADD_GAME_SUCCESS';
export const ADD_GAME_ERROR = 'game/ADD_GAME_ERROR';

export const addGame = createAction(ADD_GAME, (payload) => payload);

export const EDIT_GAME = 'game/EDIT_GAME';
export const EDIT_GAME_SUCCESS = 'game/EDIT_GAME_SUCCESS';
export const EDIT_GAME_ERROR = 'game/EDIT_GAME_ERROR';

export const editGame = createAction(EDIT_GAME, ({ oid, param }) => ({ oid, param }));

export const ON_CHANGE = 'game/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'game/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
