import { combineActions, handleActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions } from 'src/lib/asyncUtils';
import {
    GET_CURRENT_LOCALE_MAP,
    GET_CURRENT_LOCALE_MAP_SUCCESS,
    GET_CURRENT_LOCALE_MAP_ERROR,
    ON_CHANGE,
} from './action';

const initialState = {
    localeMap: reducerUtils.initial({}),
    lang: 'kr',
};

export default handleActions(
    {
        [combineActions(GET_CURRENT_LOCALE_MAP, GET_CURRENT_LOCALE_MAP_SUCCESS, GET_CURRENT_LOCALE_MAP_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_CURRENT_LOCALE_MAP, 'localeMap', true)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
    },
    initialState,
);
