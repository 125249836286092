import { createAction } from 'redux-actions';

export const GET_FIELD_MAP = 'fieldMap/GET_FIELD_MAP';
export const GET_FIELD_MAP_SUCCESS = 'fieldMap/GET_FIELD_MAP_SUCCESS';
export const GET_FIELD_MAP_ERROR = 'fieldMap/GET_FIELD_MAP_ERROR';

export const getFieldMap = createAction(GET_FIELD_MAP, (id) => id);

export const GET_FIELD_MAPS = 'fieldMap/GET_FIELD_MAPS';
export const GET_FIELD_MAPS_SUCCESS = 'fieldMap/GET_FIELD_MAPS_SUCCESS';
export const GET_FIELD_MAPS_ERROR = 'fieldMap/GET_FIELD_MAPS_ERROR';

export const getFieldMaps = createAction(GET_FIELD_MAPS, ({ gameId, page, limit }) => ({
    gameId,
    page,
    limit,
}));

export const SEARCH_FIELD_MAPS = 'fieldMap/SEARCH_FIELD_MAPS';
export const SEARCH_FIELD_MAPS_SUCCESS = 'fieldMap/SEARCH_FIELD_MAPS_SUCCESS';
export const SEARCH_FIELD_MAPS_ERROR = 'fieldMap/SEARCH_FIELD_MAPS_ERROR';

export const searchFieldMaps = createAction(SEARCH_FIELD_MAPS, ({ page, limit, param }) => ({ page, limit, param }));

export const GET_FIELD_MAPS_FOR_SELECT = 'fieldMap/GET_FIELD_MAPS_FOR_SELECT';
export const GET_FIELD_MAPS_FOR_SELECT_SUCCESS = 'fieldMap/GET_FIELD_MAPS_FOR_SELECT_SUCCESS';
export const GET_FIELD_MAPS_FOR_SELECT_ERROR = 'fieldMap/GET_FIELD_MAPS_FOR_SELECT_ERROR';

export const getFieldMapsForSelect = createAction(GET_FIELD_MAPS_FOR_SELECT, (gameId) => gameId);

export const CREATE_FIELD_MAP = 'fieldMap/CREATE_FIELD_MAP';
export const CREATE_FIELD_MAP_SUCCESS = 'fieldMap/CREATE_FIELD_MAP_SUCCESS';
export const CREATE_FIELD_MAP_ERROR = 'fieldMap/CREATE_FIELD_MAP_ERROR';

export const createFieldMap = createAction(CREATE_FIELD_MAP, (payload) => payload);

export const EDIT_FIELD_MAP = 'fieldMap/EDIT_FIELD_MAP';
export const EDIT_FIELD_MAP_SUCCESS = 'fieldMap/EDIT_FIELD_MAP_SUCCESS';
export const EDIT_FIELD_MAP_ERROR = 'fieldMap/EDIT_FIELD_MAP_ERROR';

export const editFieldMap = createAction(EDIT_FIELD_MAP, ({ oid, param }) => ({ oid, param }));

export const ON_CHANGE = 'fieldMap/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'fieldMap/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
