import { createAction } from 'redux-actions';

export const GET_SEASON_RECORDS = 'qaqc/season/GET_SEASON_RECORDS';
export const GET_SEASON_RECORDS_SUCCESS = 'qaqc/season/GET_SEASON_RECORDS_SUCCESS';
export const GET_SEASON_RECORDS_ERROR = 'qaqc/season/GET_SEASON_RECORDS_ERROR';

export const getSeasonRecords = createAction(GET_SEASON_RECORDS, (gameId) => gameId);

export const GET_SEASON_DATA = 'qaqc/season/GET_SEASON_DATA';
export const GET_SEASON_DATA_SUCCESS = 'qaqc/season/GET_SEASON_DATA_SUCCESS';
export const GET_SEASON_DATA_ERROR = 'qaqc/season/GET_SEASON_DATA_ERROR';

export const getSeasonData = createAction(GET_SEASON_DATA, (gameId) => gameId);

export const CREATE_DUMMY_RANKERS = 'qaqc/season/CREATE_DUMMY_RANKERS';
export const CREATE_DUMMY_RANKERS_SUCCESS = 'qaqc/season/CREATE_DUMMY_RANKERS_SUCCESS';
export const CREATE_DUMMY_RANKERS_ERROR = 'qaqc/season/CREATE_DUMMY_RANKERS_ERROR';

export const createDummyRankers = createAction(CREATE_DUMMY_RANKERS, (param) => param);

export const EDIT_RANK = 'qaqc/season/EDIT_RANK';
export const EDIT_RANK_SUCCESS = 'qaqc/season/EDIT_RANK_SUCCESS';
export const EDIT_RANK_ERROR = 'qaqc/season/EDIT_RANK_ERROR';

export const editRank = createAction(EDIT_RANK, (param) => param);

export const EDIT_SEASON = 'qaqc/season/EDIT_SEASON';
export const EDIT_SEASON_SUCCESS = 'qaqc/season/EDIT_SEASON_SUCCESS';
export const EDIT_SEASON_ERROR = 'qaqc/season/EDIT_SEASON_ERROR';

export const editSeason = createAction(EDIT_SEASON, ({ season, param }) => ({ season, param }));

export const GET_RANKERS = 'qaqc/season/GET_RANKERS';
export const GET_RANKERS_SUCCESS = 'qaqc/season/GET_RANKERS_SUCCESS';
export const GET_RANKERS_ERROR = 'qaqc/season/GET_RANKERS_ERROR';

export const getRankers = createAction(GET_RANKERS, ({ season, camp, start, end }) => ({ season, camp, start, end }));

export const SET_TARGET_PLAYER = 'player/SET_TARGET_PLAYER';
export const setTargetPlayer = createAction(SET_TARGET_PLAYER);
