import React from 'react';
import { CModal, CModalBody, CButtonToolbar, CButton } from '@coreui/react';
import { FormattedMessage } from 'react-intl';
import useConfirmModal from 'src/hooks/modal/useConfirmModal';

export default function ConfirmModalV2Container({ size = 'lg' }) {
    const { isOpen, onOk, closeConfirm, message } = useConfirmModal();

    const handleConfirm = () => {
        onOk();
        closeConfirm();
    };

    const handleCancel = () => {
        closeConfirm();
    };

    return (
        <CModal show={isOpen} onClose={closeConfirm} centered size={size}>
            <CModalBody>{message}</CModalBody>
            <CButtonToolbar justify="center" className="py-3">
                <CButton className="mr-4 font-weight-bold" color="primary" onClick={handleConfirm}>
                    <FormattedMessage id="확인" />
                </CButton>
                <CButton className="font-weight-bold" onClick={handleCancel}>
                    <FormattedMessage id="취소" />
                </CButton>
            </CButtonToolbar>
        </CModal>
    );
}
