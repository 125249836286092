import { call, put, take, takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSagaWithHeader, createPromiseSaga } from 'src/lib/asyncUtils';
import { GET_BACKUP_METADATA_BY_OID, GET_BACKUP_METADATAS, ADD_BACKUP_METADATA, EDIT_BACKUP_METADATA } from './action';
import { OPEN_MODAL, CLOSE_MODAL } from 'src/store/modules/modal';

const getBackupMetadataByOidSaga = createPromiseSaga(GET_BACKUP_METADATA_BY_OID, api.getBackupMetadataByOid);
const getBackupMetadatasSaga = createPromiseSagaWithHeader(GET_BACKUP_METADATAS, api.getBackupMetadatas);

function* addBackupMeatadataSaga(action) {
    const { gameId, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.addBackupMetadata, { gameId, param });

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${ADD_BACKUP_METADATA}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);
        yield put({ type: CLOSE_MODAL, payload: 'addMetadata' });

        yield put({ type: GET_BACKUP_METADATAS, payload: { page: 1, limit: 5 } });
    } catch (e) {
        yield put({ type: `${ADD_BACKUP_METADATA}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* editBackupMetadataSaga(action) {
    const { oid, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.editBackupMetadata, { oid, param });

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${EDIT_BACKUP_METADATA}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);
        yield put({ type: CLOSE_MODAL, payload: 'editMetadata' });

        yield put({ type: GET_BACKUP_METADATAS, payload: { page: 1, limit: 5 } });
    } catch (e) {
        yield put({ type: `${EDIT_BACKUP_METADATA}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* backupMetadataSaga() {
    yield takeEvery(GET_BACKUP_METADATA_BY_OID, getBackupMetadataByOidSaga);
    yield takeEvery(GET_BACKUP_METADATAS, getBackupMetadatasSaga);
    yield takeEvery(ADD_BACKUP_METADATA, addBackupMeatadataSaga);
    yield takeEvery(EDIT_BACKUP_METADATA, editBackupMetadataSaga);
}
