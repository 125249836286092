import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeConfirmModalAction, openConfirmModalAction } from 'src/store/modules/confirmModal';

function useConfirmModal() {
    const dispatch = useDispatch();
    const { isOpen, onOk, message } = useSelector((state) => state.confirmModal);
    const closeConfirm = useCallback(() => {
        dispatch(closeConfirmModalAction());
    }, [dispatch]);
    const confirm = useCallback(
        ({ message, onOk }) => {
            dispatch(
                openConfirmModalAction({
                    message,
                    onOk,
                }),
            );
        },
        [dispatch],
    );

    return { isOpen, onOk, message, confirm, closeConfirm };
}

export default useConfirmModal;
