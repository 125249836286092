import { handleActions, combineActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions, handleAsyncActionsWithHeader } from 'src/lib/asyncUtils';
import {
    GET_RECEIPT_BY_OID,
    GET_RECEIPT_BY_OID_SUCCESS,
    GET_RECEIPT_BY_OID_ERROR,
    GET_RECEIPTS,
    GET_RECEIPTS_SUCCESS,
    GET_RECEIPTS_ERROR,
    SEARCH_RECEIPTS,
    SEARCH_RECEIPTS_SUCCESS,
    SEARCH_RECEIPTS_ERROR,
    GET_MY_LATEST_RECEIPTS,
    GET_MY_LATEST_RECEIPTS_SUCCESS,
    GET_MY_LATEST_RECEIPTS_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';

const initialState = {
    receipt: reducerUtils.initial({}),
    receipts: reducerUtils.initial([]),
    isSearch: false,
    searchForm: {},
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(GET_RECEIPT_BY_OID, GET_RECEIPT_BY_OID_SUCCESS, GET_RECEIPT_BY_OID_ERROR)]: (state, action) =>
            handleAsyncActions(GET_RECEIPT_BY_OID, 'receipt', true)(state, action),
        [combineActions(GET_RECEIPTS, GET_RECEIPTS_SUCCESS, GET_RECEIPTS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(GET_RECEIPTS, 'receipts', true)(state, action),
        [combineActions(SEARCH_RECEIPTS, SEARCH_RECEIPTS_SUCCESS, SEARCH_RECEIPTS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(SEARCH_RECEIPTS, 'receipts', true)(state, action),
        [combineActions(GET_MY_LATEST_RECEIPTS, GET_MY_LATEST_RECEIPTS_SUCCESS, GET_MY_LATEST_RECEIPTS_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_MY_LATEST_RECEIPTS, 'receipts', true)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
