import { take, call, put, takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import {
    GET_ACCOUNT_BY_OID,
    GET_ACCOUNTS,
    GET_ACCOUNT_GB,
    GET_PAGE_ROLES,
    CREATE_ACCOUNT,
    EDIT_ACCOUNT,
    RESET_PASSWORD,
    FORCE_LOGOUT,
    EXPORT_HISTORY,
    ON_CHANGE,
} from './action';
import { OPEN_MODAL, CLOSE_MODAL, CONFIRM } from 'src/store/modules/modal';

const getAccountByOidSaga = createPromiseSaga(GET_ACCOUNT_BY_OID, api.getAccountByOid);
const getAccountsSaga = createPromiseSagaWithHeader(GET_ACCOUNTS, api.getAccounts);
const getAccountGbSaga = createPromiseSaga(GET_ACCOUNT_GB, api.getCmCodeForSelect);
const getPageRolesSaga = createPromiseSaga(GET_PAGE_ROLES, api.getCmCodeForSelect);

function* createAccountSaga(action) {
    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        yield call(api.createAccount, action.payload);
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: `${CREATE_ACCOUNT}_SUCCESS`, payload: true });
        yield put({ type: CLOSE_MODAL, payload: 'createAccount' });

        yield put({ type: GET_ACCOUNTS, payload: { page: 1, limit: 14 } });
        yield put({ type: ON_CHANGE, payload: { name: 'currentPage', value: 1 } });
    } catch (e) {
        yield put({ type: `${CREATE_ACCOUNT}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* editAccountSaga(action) {
    const { oid, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        yield call(api.editAccount, { oid, param });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: `${EDIT_ACCOUNT}_SUCCESS`, payload: true });
        yield put({ type: CLOSE_MODAL, payload: 'editAccount' });

        yield put({ type: GET_ACCOUNTS, payload: { page: 1, limit: 14 } });
        yield put({ type: ON_CHANGE, payload: { name: 'currentPage', value: 1 } });
    } catch (e) {
        yield put({ type: `${EDIT_ACCOUNT}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* resetPasswordSaga(action) {
    try {
        const { payload: isConfirm } = yield take(CONFIRM);

        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
            yield call(api.resetPassword, action.payload);
            yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

            yield put({ type: `${RESET_PASSWORD}_SUCCESS`, payload: true });
            yield put({ type: OPEN_MODAL, payload: 'successAlert' });

            yield take(CLOSE_MODAL);

            yield put({ type: GET_ACCOUNTS, payload: { page: 1, limit: 14 } });
            yield put({ type: ON_CHANGE, payload: { name: 'currentPage', value: 1 } });
        }
    } catch (e) {
        yield put({ type: `${RESET_PASSWORD}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* forceLogoutSaga(action) {
    try {
        const { payload: isConfirm } = yield take(CONFIRM);

        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
            yield call(api.forceLogout, action.payload);
            yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

            yield put({ type: `${FORCE_LOGOUT}_SUCCESS`, payload: true });
            yield put({ type: OPEN_MODAL, payload: 'successAlert' });

            yield take(CLOSE_MODAL);
        }
    } catch (e) {
        yield put({ type: `${FORCE_LOGOUT}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* exportHistory(action) {
    const { oid, type } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        const { data: result } = yield call(api.exportHistory, { oid, type });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${EXPORT_HISTORY}_SUCCESS`, payload: result });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });
    } catch (e) {
        yield put({ type: `${EXPORT_HISTORY}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* accountSaga() {
    yield takeEvery(GET_ACCOUNT_BY_OID, getAccountByOidSaga);
    yield takeEvery(GET_ACCOUNTS, getAccountsSaga);
    yield takeEvery(GET_ACCOUNT_GB, getAccountGbSaga);
    yield takeEvery(GET_PAGE_ROLES, getPageRolesSaga);
    yield takeEvery(CREATE_ACCOUNT, createAccountSaga);
    yield takeEvery(EDIT_ACCOUNT, editAccountSaga);
    yield takeEvery(RESET_PASSWORD, resetPasswordSaga);
    yield takeEvery(FORCE_LOGOUT, forceLogoutSaga);
    yield takeEvery(EXPORT_HISTORY, exportHistory);
}
