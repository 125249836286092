import axios from 'axios';
import qs from 'querystring';
import ContentDisposition from 'content-disposition';

console.log(process.env.REACT_APP_API_URL);

export const adminApi = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
    headers: {
        'x-percent-token': localStorage.getItem('pt') || '',
        'x-percent-rtoken': localStorage.getItem('pr') || '',
        'x-site-lang': localStorage.getItem('lang') || 'kr',
    },
    timeout: 50000,
});

adminApi.interceptors.request.use(
    (config) => {
        /* eslint-disable no-param-reassign */
        config.headers['x-percent-token'] = localStorage.getItem('pt') || '';
        config.headers['x-percent-rtoken'] = localStorage.getItem('pr') || '';
        config.headers['x-site-lang'] = localStorage.getItem('lang') || 'kr';

        return config;
        /* eslint-enable no-param-reassign */
    },
    (err) => Promise.reject(err),
);

adminApi.interceptors.response.use(async (res) => {
    if (res.status >= 200 && res.status < 300) {
        if (res.headers['x-percent-token']) {
            const token = res.headers['x-percent-token'];
            localStorage.setItem('pt', token);
            if (res.status === 204) {
                let data;

                try {
                    if (res.config.data) {
                        if (res.config.data instanceof FormData) {
                            data = res.config.data;
                        } else {
                            data = JSON.parse(res.config.data);
                        }
                    }
                } catch (e) {
                    data = undefined;
                }
                const result = await adminApi.request({ ...res.config, data });
                return result;
            }
        }

        return res;
    }

    return Promise.reject(res);
});

export const getPlayerByOid = ({ gameId, id }) =>
    adminApi.get(`/players/detail-by-oid?${qs.stringify({ gameId, id })}`);
export const getPlayerByPid = ({ gameId, id }) =>
    adminApi.get(`/players/detail-by-pid?${qs.stringify({ gameId, id })}`);
export const getPlayerFromFirebase = ({ gameId, database, playerId }) =>
    adminApi.post(`/players/detail/current?gameId=${gameId}`, { database, playerId });
export const getPlayerFromFirebaseWithIntl = ({ gameId, database, playerId, lang }) =>
    adminApi.post(`/players/detail/current-with-intl?gameId=${gameId}${lang ? `&forceEN=${lang === 'en'}` : ''}`, {
        database,
        playerId,
    });
export const getPlayerByPidFromFirebase = ({ gameId, id }) =>
    adminApi.get(`/players/detail-by-pid/current?${qs.stringify({ gameId, id })}`);
export const getPlayerLogs = ({ gameId, id, date }) =>
    adminApi.get(`/players/logs?${qs.stringify({ gameId, id, date })}`);
export const getPlayerBattleLogs = ({ gameId, id, date, mode }) =>
    adminApi.get(`/players/logs/battle?${qs.stringify({ gameId, id, date, mode: mode || 'pvp' })}`);
export const getPlayers = ({ gameId, page }) => adminApi.get(`/players?${qs.stringify({ gameId, page })}`);
export const searchPlayers = ({ gameId, page, param }) =>
    adminApi.post(`/players/search?${qs.stringify({ gameId, page })}`, param);
export const searchPlayersFromFirebase = ({ gameId, id }) =>
    adminApi.get(`/players/scan?${qs.stringify({ gameId, id })}`);
export const comparePlayerData = ({ gameId, param }) => adminApi.post(`/players/compare?gameId=${gameId}`, param);
export const getSnapshotList = ({ gameId, limit, param }) =>
    adminApi.post(`/players/snapshots?${qs.stringify({ gameId, limit })}`, param);
export const getPlayerCrew = ({ gameId, playerId }) => adminApi.post(`/players/crew?gameId=${gameId}`, { playerId });
export const updatePlayerByPreset = ({ gameId, param }) =>
    adminApi.patch(`/players/update-by-preset?gameId=${gameId}`, param);

export const getReceiptByOid = ({ gameId, id }) =>
    adminApi.get(`/receipts/detail-by-oid?${qs.stringify({ gameId, id })}`);
export const getReceipts = ({ gameId, page }) => adminApi.get(`/receipts?${qs.stringify({ gameId, page })}`);
export const searchReceipts = ({ gameId, page, limit, param }) =>
    adminApi.post(`/receipts/search?${qs.stringify({ gameId, page, limit })}`, param);
export const getMyLatestReceipts = ({ gameId, param }) => adminApi.post(`/receipts/my?gameId=${gameId}`, param);

export const getCrews = ({ gameId, page }) => adminApi.get(`/crews?${qs.stringify({ gameId, page })}`);
export const searchCrews = ({ gameId, page, param }) =>
    adminApi.post(`/crews/search?${qs.stringify({ gameId, page })}`, param);
export const getCrewDetail = ({ gameId, id }) => adminApi.post(`/crews/detail?gameId=${gameId}`, { id });
export const patchCrewAuth = ({ crewId, playerId, auth }) =>
    adminApi.patch(`/crews/modify/auth`, { id: crewId, playerId, auth });

export const getCmCodes = ({ gb, page }) => adminApi.get(`/cmcodes?${qs.stringify({ gb, page })}`);
export const searchCmCodes = ({ gb, page, param }) =>
    adminApi.post(`/cmcodes/search?${qs.stringify({ gb, page })}`, param);
export const getCmCodesForSelect = (gb) => adminApi.get(`/cmcodes/for-select?gb=${gb}`);
export const getProductCmCodesForSelect = (gb) => adminApi.get(`/cmcodes/products?gb=${gb}`);
export const getCmCode = ({ gb, cdMajor }) => adminApi.get(`/cmcodes/${cdMajor}?gb=${gb}`);
export const getCmCodeForSelect = ({ gb, cdMajor }) => adminApi.get(`/cmcodes/${cdMajor}/for-select?gb=${gb}`);
export const createMajor = ({ gb, param }) => adminApi.post(`/cmcodes?gb=${gb}`, param);
export const editMajor = ({ oid, gb, param }) =>
    adminApi.patch(`/cmcodes/edit/major?${qs.stringify({ oid, gb })}`, param);
export const createMinor = ({ gb, cdMajor, param }) => adminApi.post(`/cmcodes/${cdMajor}?gb=${gb}`, param);
export const editMinor = ({ oid, param }) => adminApi.patch(`/cmcodes/edit/minor?oid=${oid}`, param);

export const getGameByOid = (id) => adminApi.get(`/games/detail-by-oid?id=${id}`);
export const getMappedCodes = ({ gameId, codeType }) =>
    adminApi.get(`/games/mapped-codes?${qs.stringify({ gameId, codeType })}`);
export const getGames = ({ page, limit }) => adminApi.get(`/games?${qs.stringify({ page, limit })}`);
export const getGamesForSelect = () => adminApi.get('/games/for-select');
export const addGame = (param) => adminApi.post('/games', param);
export const editGame = ({ oid, param }) => adminApi.patch(`/games/edit?oid=${oid}`, param);

export const getPackageByOid = (id) => adminApi.get(`/packages/detail-by-oid?id=${id}`);
export const getPackages = (gameId) => adminApi.get(`/packages?gameId=${gameId}`);
export const getPackagesForSelect = (gameId) => adminApi.get(`/packages/for-select?gameId=${gameId}`);
export const createPackage = ({ gameId, param }) => adminApi.post(`/packages?gameId=${gameId}`, param);
export const editPackage = ({ oid, param }) => adminApi.patch(`/packages/edit?oid=${oid}`, param);

export const getProvideLogByOid = (id) => adminApi.get(`/providelogs/detail-by-oid?id=${id}`);
export const getProvideLogsByOrderId = ({ gameId, orderId }) =>
    adminApi.get(`/providelogs/by-orderid?${qs.stringify({ gameId, orderId })}`);
export const getProvideLogsTo = ({ gameId, id }) => adminApi.get(`/providelogs/to?${qs.stringify({ gameId, id })}`);
export const getProvideLogs = ({ page, limit }) => adminApi.get(`/providelogs?${qs.stringify({ page, limit })}`);
export const searchProvideLogs = ({ page, limit, param }) =>
    adminApi.post(`/providelogs/search?${qs.stringify({ page, limit })}`, param);

export const getManualProvideLogs = ({ page, limit }) => adminApi.get(`/mprovidelogs?${qs.stringify({ page, limit })}`);
export const searchManualProvideLogs = ({ page, limit, param }) =>
    adminApi.post(`/mprovidelogs/search?${qs.stringify({ page, limit })}`, param);

export const getCrewMailLogByOid = (id) => adminApi.get(`/crewmaillogs/detail-by-oid?id=${id}`);
export const getCrewMailLogsByCrewId = ({ gameId, crewId }) =>
    adminApi.get(`/crewmaillogs/to?${qs.stringify({ gameId, crewId })}`);
export const getCrewMailLogs = ({ page, limit }) => adminApi.get(`/crewmaillogs?${qs.stringify({ page, limit })}`);
export const searchCrewMailLogs = ({ page, limit, param }) =>
    adminApi.post(`/crewmaillogs/search?${qs.stringify({ page, limit })}`, param);

export const providePackage = ({ gameId, param }) => adminApi.post(`/provides?gameId=${gameId}`, param);
export const provideCustom = ({ gameId, param }) => adminApi.post(`/provides/custom?gameId=${gameId}`, param);
export const provideLegendDice = ({ gameId, param }) => adminApi.post(`/provides/legend-dice?gameId=${gameId}`, param);
export const provideToken = ({ gameId, param }) => adminApi.post(`/provides/token?gameId=${gameId}`, param);
export const providePass = ({ gameId, param }) => adminApi.post(`/provides/pass?gameId=${gameId}`, param);
export const provideSkin = ({ gameId, param }) => adminApi.post(`/provides/skin?gameId=${gameId}`, param);
export const provideEmblem = ({ gameId, param }) => adminApi.post(`/provides/emblem?gameId=${gameId}`, param);
export const provideEmoticon = ({ gameId, param }) => adminApi.post(`/provides/emoticon?gameId=${gameId}`, param);
export const provideCustomToCrew = ({ gameId, param }) =>
    adminApi.post(`/provides/custom/crew?gameId=${gameId}`, param);

export const recover = ({ gameId, param }) => adminApi.post(`/recovers?gameId=${gameId}`, param);

export const getMyRecoverLogs = (gameId) => adminApi.get(`/recoverlogs/my?gameId=${gameId}`);
export const getRecoverLogs = ({ page, limit }) => adminApi.get(`/recoverlogs?${qs.stringify({ page, limit })}`);
export const searchRecoverLogs = ({ page, limit, param }) =>
    adminApi.post(`/recoverlogs/search?${qs.stringify({ page, limit })}`, param);
export const exportSourcePlayerSnapshot = (oid) =>
    adminApi.get(`/recoverlogs/${oid}/export-source`, { responseType: 'blob' }).then((response) => {
        const { filename } = ContentDisposition.parse(response.headers['content-disposition']).parameters;

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    });

export const getPlayerCheatStatus = ({ gameId, database, playerId }) =>
    adminApi.post('/cheatplayers/status', { gameId, database, playerId });
export const playerBan = ({ gameId, param }) => adminApi.patch(`/cheatplayers/ban?gameId=${gameId}`, param);
export const playerUnban = ({ gameId, param }) => adminApi.patch(`/cheatplayers/unban?gameId=${gameId}`, param);
export const deviceBan = ({ gameId, param }) => adminApi.patch(`/cheatplayers/ban/device?gameId=${gameId}`, param);
export const deviceUnban = ({ gameId, param }) => adminApi.patch(`/cheatplayers/unban/device?gameId=${gameId}`, param);
export const inquiryBan = ({ gameId, param }) => adminApi.patch(`/cheatplayers/ban/inquiry?gameId=${gameId}`, param);
export const inquiryUnban = ({ gameId, param }) =>
    adminApi.patch(`/cheatplayers/unban/inquiry?gameId=${gameId}`, param);
export const getCheatPlayers = ({ page, limit }) => adminApi.get(`/cheatplayers?${qs.stringify({ page, limit })}`);
export const searchCheatPlayers = ({ page, limit, param }) =>
    adminApi.post(`/cheatplayers/search?${qs.stringify({ page, limit })}`, param);
export const addCheatPlayers = (param) =>
    adminApi.post('/cheatplayers/upload', param, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });
export const deleteCheatPlayer = (id) => adminApi.post('/cheatplayers/delete', { id });

export const getPlayerBanLogs = ({ gameId, database, playerId }) =>
    adminApi.post(`/playerbanlogs/by?gameId=${gameId}`, { database, playerId });
export const getBanLogs = ({ page, limit }) => adminApi.get(`/playerbanlogs?${qs.stringify({ page, limit })}`);
export const searchBanLogs = ({ page, limit, param }) =>
    adminApi.post(`/playerbanlogs/search?${qs.stringify({ page, limit })}`, param);
export const getDeviceBanLogsByDeviceId = ({ gameId, param }) =>
    adminApi.post(`/playerbanlogs/device/by?gameId=${gameId}`, param);
export const getDeviceBanLogs = ({ page, limit }) =>
    adminApi.get(`/playerbanlogs/device?${qs.stringify({ page, limit })}`);
export const searchDeviceBanLogs = ({ page, limit, param }) =>
    adminApi.post(`/playerbanlogs/device/search?${qs.stringify({ page, limit })}`, param);
export const getPlayerInquiryBanLogs = ({ gameId, database, playerId }) =>
    adminApi.post(`/playerbanlogs/inquiry/by?gameId=${gameId}`, { database, playerId });
export const getInquiryBanLogs = ({ page, limit }) =>
    adminApi.get(`/playerbanlogs/inquiry?${qs.stringify({ page, limit })}`);
export const searchInquiryBanLogs = ({ page, limit, param }) =>
    adminApi.post(`/playerbanlogs/inquiry/search?${qs.stringify({ page, limit })}`, param);

export const getBackupMetadataByOid = (id) => adminApi.get(`/backups/metadatas/detail-by-oid?id=${id}`);
export const getBackupMetadatas = ({ page, limit }) =>
    adminApi.get(`/backups/metadatas?${qs.stringify({ page, limit })}`);
export const addBackupMetadata = ({ gameId, param }) => adminApi.post(`/backups/metadatas?gameId=${gameId}`, param);
export const editBackupMetadata = ({ oid, param }) => adminApi.patch(`/backups/metadatas?oid=${oid}`, param);
export const getBackupFilterByOid = (id) => adminApi.get(`/backups/filters/detail-by-oid?id=${id}`);
export const getBackupFilters = ({ page, limit }) => adminApi.get(`/backups/filters?${qs.stringify({ page, limit })}`);
export const addBackupFilter = ({ gameId, param }) => adminApi.post(`/backups/filters?gameId=${gameId}`, param);
export const editBackupFilter = ({ oid, param }) => adminApi.patch(`/backups/filters?oid=${oid}`, param);
export const getBackupCrawlers = ({ page, limit }) =>
    adminApi.get(`/backups/crawlers?${qs.stringify({ page, limit })}`);
export const searchBackupCrawlers = ({ page, limit, param }) =>
    adminApi.post(`/backups/crawlers/search?${qs.stringify({ page, limit })}`, param);
export const createBackupCrawlers = ({ gameId, ymd }) =>
    adminApi.post(`/backups/create-crawlers?${qs.stringify({ gameId, ymd })}`);
export const restoreByBackupCrawler = (oid) => adminApi.post(`/backups/restore?oid=${oid}`);

export const register = (param) => adminApi.post('/auth/register', param);
export const login = (param) => adminApi.post('/auth/login', param);
export const check = () => adminApi.get('/auth/check');
export const logout = () => adminApi.post('/auth/logout');

export const getAccountByOid = (id) => adminApi.get(`/accounts/detail-by-oid?id=${id}`);
export const getAccounts = ({ page, limit }) => adminApi.get(`/accounts?${qs.stringify({ page, limit })}`);
export const createAccount = (param) => adminApi.post('/accounts/create', param);
export const editAccount = ({ oid, param }) => adminApi.patch(`/accounts/edit?oid=${oid}`, param);
export const resetPassword = (oid) => adminApi.patch(`/accounts/reset-password?oid=${oid}`);
export const forceLogout = (oid) => adminApi.patch(`/accounts/force-logout?oid=${oid}`);
export const exportHistory = ({ oid, type }) => adminApi.get(`/accounts/export/history?${qs.stringify({ oid, type })}`);

export const getFieldMap = (id) => adminApi.get(`/fieldmaps/detail-by-oid?id=${id}`);
export const getFieldMaps = ({ page, limit }) => adminApi.get(`/fieldmaps?${qs.stringify({ page, limit })}`);
export const searchFieldMaps = ({ page, limit, param }) =>
    adminApi.post(`/fieldmaps/search?${qs.stringify({ page, limit })}`, param);
export const getFieldMapsForSelect = (gameId) => adminApi.get(`/fieldmaps/for-select?gameId=${gameId}`);
export const createFieldMap = (param) => adminApi.post('/fieldmaps', param);
export const editFieldMap = ({ oid, param }) => adminApi.patch(`/fieldmaps/edit?oid=${oid}`, param);

export const getMyNotifications = () => adminApi.get('/notifications/my');
export const updateNotificationToRead = (oid) => adminApi.patch(`/notifications/read?oid=${oid}`);

export const updateVipList = ({ gameId, param }) =>
    adminApi.post(`/management/vip/update?gameId=${gameId}`, param, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });

export const getSeasonRecords = (gameId) => adminApi.get(`/qaqc/season/records?gameId=${gameId}`);
export const getSeasonData = (gameId) => adminApi.get(`/qaqc/season/data?gameId=${gameId}`);
export const createDummyRankers = (param) => adminApi.post('/qaqc/season/dummy-rankers', param);
export const editRank = (param) => adminApi.patch('/qaqc/season/rank', param);
export const editSeason = ({ season, param }) => adminApi.patch(`/qaqc/season?season=${season}`, param);

// dev일 때 S3에 CR문자가 같이 삽입 되어서.
export const getCurrentLocaleMap = (lang) => adminApi.get(`/locales?lang=${lang}%0D`);

export const getLocaleMap = (id) => adminApi.get(`/localemaps/detail-by-oid?id=${id}`);
export const getLocaleMaps = ({ page, limit }) => adminApi.get(`/localemaps?${qs.stringify({ page, limit })}`);
export const searchLocaleMaps = ({ page, limit, param }) =>
    adminApi.post(`/localemaps/search?${qs.stringify({ page, limit })}`, param);
export const createLocaleMap = (param) => adminApi.post(`/localemaps`, param);
export const editLocaleMap = ({ oid, param }) => adminApi.patch(`/localemaps/edit?oid=${oid}`, param);

export const getVoidedPurchases = ({ gameId, page, limit }) =>
    adminApi.get(`/voided-purchases?${qs.stringify({ gameId, page, limit })}`);
export const searchVoidedPurchases = ({ gameId, page, limit, param }) =>
    adminApi.post(`/voided-purchases/search?${qs.stringify({ gameId, page, limit })}`, param);
export const downloadVoidedPurchases = (param) => adminApi.get(`/voided-purchases/download?${qs.stringify(param)}`);

export const getLatestRecords = () => adminApi.get('/leaderboards/latest/records');
export const getRankers = ({ season, camp, start, end }) =>
    adminApi.post('/leaderboards/rankers', { season, camp, start, end });
export const getCurrentRankers = ({ camp, start, end }) =>
    adminApi.post('/leaderboards/current/rankers', { camp, start, end });

export const getNotices = ({ gameId, page, limit }) =>
    adminApi.get(`/notices?${qs.stringify({ gameId, page, limit })}`);
export const searchNotices = ({ gameId, page, limit, param }) => {
    return adminApi.post(`/notices/search?${qs.stringify({ gameId, page, limit })}`, param);
};
export const getPublishedNotices = (gameId) => adminApi.get(`/notices/published?gameId=${gameId}`);
export const publishNotice = (oid) => adminApi.patch(`/notices/publish?${qs.stringify({ oid })}`);
export const unpublishNotice = (oid) => adminApi.patch(`/notices/unpublish?${qs.stringify({ oid })}`);
export const orderPublishedNotices = ({ gameId, noticeIds }) =>
    adminApi.patch(`/notices/order?gameId=${gameId}`, { noticeIds });
export const deleteNotice = (oid) => adminApi.patch(`/notices/delete?${qs.stringify({ oid })}`);
export const postNotice = ({ body, query }) =>
    adminApi.post(`/notices?${qs.stringify(query)}`, body, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

export const editNotice = ({ body, query }) =>
    adminApi.patch(`/notices/edit?${qs.stringify(query)}`, body, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
export const getReportSchedules = async ({ page, limit }) =>
    adminApi.get(`/report-schedules?${qs.stringify({ page, limit })}`);
export const searchReportSchedules = async ({ page, limit, param }) =>
    adminApi.post(`/report-schedules/search?${qs.stringify({ page, limit })}`, param);
export const syncCrewMember = async (id) =>
    adminApi
        .patch(`/crews/sync-member`, { id })
        .then((res) => alert(`${res.data.memberCount}명으로 동기화되었습니다.`));
export const getReportResults = async ({ type, page, limit }) =>
    adminApi.get(`/report-results?${qs.stringify({ type, page, limit })}`);

export const getDeleteLogByOid = (id) => adminApi.get(`/deletelogs/detail-by-oid?id=${id}`);
export const getDeleteLogs = async ({ gameId, page }) => adminApi.get(`/deletelogs?${qs.stringify({ gameId, page })}`);
export const searchDeleteLogs = async ({ gameId, page, param }) =>
    adminApi.post(`/deletelogs/search?${qs.stringify({ gameId, page })}`, param);
export const cancelDelete = async ({ gameId, param }) => adminApi.post(`/deletelogs/cancel?gameId=${gameId}`, param);

export const getDiceAccrueMetadataByOid = (id) => adminApi.get(`/dice-accrue/metadatas/detail-by-oid?id=${id}`);
export const getDiceAccrueMetadatasForSelect = () => adminApi.get('/dice-accrue/metadatas/for-select');
export const editDiceAccrueMetadata = ({ oid, param }) =>
    adminApi.patch(`/dice-accrue/metadatas/edit?oid=${oid}`, param);

export const getDiceAccrueResults = ({ page, limit }) =>
    adminApi.get(`/dice-accrue/results?${qs.stringify({ page, limit })}`);
export const searchDiceAccrueResults = ({ page, limit, param }) =>
    adminApi.post(`/dice-accrue/results/search?${qs.stringify({ page, limit })}`, param);
export const getDiceAccrueLogsByPid = ({ page, limit, param }) =>
    adminApi.get(`/dice-accrue/results/log?${qs.stringify({ page, limit, ...param })}`);
export const downloadDiceAccrueLogs = (param) =>
    adminApi.get(`/dice-accrue/results/log/download?${qs.stringify(param)}`);

export const createDiceAccrueSchedule = (param) => adminApi.post('/dice-accrue/schedules', param);
export const deleteDiceAccrueSchedule = (id) => adminApi.delete(`/dice-accrue/schedules?id=${id}`);
export const getDiceAccrueSchedulesByGameId = (gameId) => adminApi.get(`/dice-accrue/schedules?gameId=${gameId}`);
export const getDiceAccrueScheduleLogsByGameId = ({ gameId, page, limit }) =>
    adminApi.get(`/dice-accrue/schedules/logs?${qs.stringify({ gameId, page, limit })}`);

/**
 *
 * @param {{products: Array<Product>, playerIds: Array<string>, orderId: string, titles: Record<string, string>, sendDate: Date, expiredDate: Date}} param
 * @param {number} gameId
 * @object {{type: number, index: number, amount: number}} Product
 * @returns
 */
export const createMail = (param, gameId) => adminApi.post(`/mails?gameId=${gameId}`, param);

/**
 *
 * @param {{products: Array<Product>, playerIds: Array<string>, orderId: string, titles: Record<string, string>, sendDate: Date, expiredDate: Date}} param
 * @param {number} gameId
 * @object {{type: number, index: number, amount: number}} Product
 * @returns
 */
export const editMail = (param) => adminApi.patch(`/mails`, param);

/**
 *
 * @param {{page: number}} query
 * @returns
 */
export const getWaitMails = (query) => adminApi.get(`/mails/wait?${qs.stringify(query)}`);

/**
 *
 * @param {{page: number}} query
 * @returns
 */
export const getCompleteMails = (query) => adminApi.get(`/mails/complete?${qs.stringify(query)}`);

/**
 *
 * @param {string} oid
 * @returns
 */
export const sendMail = (oid) => adminApi.post('/mails/send', { mailId: oid });

export const getRandomdiceTv = (lang, page) => adminApi.get(`/randomdice-tv/${lang}?page=${page}`);

/**
 *
 * @param {{title: string, url: { kr: string, gl: string }}} param
 * @returns
 */
export const createRandomdiceTv = (param) => adminApi.post('/randomdice-tv', param);

export const deleteRandomdiceTv = (id) => adminApi.delete(`/randomdice-tv/${id}`);

/**
 *
 * @param {string} playerId
 * @param {string} database
 * @param {'dailyBoard' | 'arcadeSeasonShop' | 'crewToken' | 'crewShop'} resetType
 * @returns
 */
export const resetPlayerSeasonData = (playerId, database, resetType) =>
    adminApi.patch('/players/season/reset', {
        playerId,
        database,
        resetType,
    });

/**
 * @typedef Player
 * @type {object}
 * @property {string | null} playerId
 * @property {string} nickName
 * @property {number} trophy
 * @property {number[]} dices
 * @param {{helpshift: string, wave: number, skinId?: number, bossId?: number, player1: Player, player2: Player}} param
 * @returns
 */
export const setPveNormalRankRequest = (param) => adminApi.patch('/rank/pve', param);

export const setPveHardRankRequest = (param) => adminApi.patch('/rank/pve/hard', param);

export const getRankValidation = (mode) => adminApi.get(`/validation/${mode}`);
export const setRankValidation = (mode, validation) => adminApi.put(`/validation/${mode}`, { validation });
export const getProductPackages = () => adminApi.get('/products/packages');
export const getProductsBoxes = () => adminApi.get('/products/boxes');
export const getProductsDices = () => adminApi.get('/products/dices');

export const getLogFilterByOid = async (oid) => adminApi.get(`/playerlogs/filters/detail-by-oid?oid=${oid}`);
export const getLogFiltersForSelect = async () => adminApi.get('/playerlogs/filters/for-select');
export const editLogFilter = async ({ oid, param }) => adminApi.patch(`/playerlogs/filters/edit?oid=${oid}`, param);
export const getLogReports = async ({ page, limit }) =>
    adminApi.get(`/playerlogs/reports?${qs.stringify({ page, limit })}`);
export const searchLogReports = async ({ page, limit, param }) =>
    adminApi.post(`/playerlogs/reports?${qs.stringify({ page, limit })}`, param);

export const deleteCrewDataByPlayer = async ({ gameId, playerId }) =>
    adminApi.post(`/players/crew/leave?gameId=${gameId}`, { playerId });
export const getCrewRaidData = async ({ gameId, database, playerId }) =>
    adminApi.post(`/players/crewraid?gameId=${gameId}`, { database, playerId }).then((res) => res.data);
export const patchCrewRaidDataSync = async ({ gameId, database, playerId, crewRaidData }) =>
    adminApi.patch(`/players/crewraid?gameId=${gameId}`, {
        database,
        playerId,
        crewRaidData,
    });

export const getDataPresetsForSelect = async () => adminApi.get('/data-presets/for-select');
export const getDataPreset = async (oid) => adminApi.get(`/data-presets/detail-by-oid?oid=${oid}`);
export const createDataPreset = async (param) => adminApi.post('/data-presets/create', param);
export const editDataPreset = async ({ oid, param }) => adminApi.patch(`/data-presets/edit?oid=${oid}`, param);
export const getMergedDataPreset = async (param) => adminApi.post('/data-presets/merge', param);
export const getDataPresets = async ({ page, limit }) => adminApi.get(`/data-presets?${qs.stringify({ page, limit })}`);
export const searchDataPresets = async ({ page, limit, param }) =>
    adminApi.post(`/data-presets/search?${qs.stringify({ page, limit })}`, param);
export const activateDataPreset = async (oid) => adminApi.patch(`/data-presets/activate?oid=${oid}`);
export const deactivateDataPreset = async (oid) => adminApi.patch(`/data-presets/deactivate?oid=${oid}`);
export const getActivatedDataPresetsForSelect = async () => adminApi.get('/data-presets/for-select/activated');
export const getDataPresetCategories = async () => adminApi.get('/data-presets/categories');

export const getDataPresetLogs = async ({ page, limit }) =>
    adminApi.get(`/data-preset-logs?${qs.stringify({ page, limit })}`);
export const searchDataPresetLogs = async ({ page, limit, param }) =>
    adminApi.post(`/data-preset-logs/search?${qs.stringify({ page, limit })}`, param);
export const downloadDataPresetBakcupLog = async (presetLogId) =>
    adminApi
        .get(`/data-preset-logs/download/backup?logId=${presetLogId}`, { responseType: 'blob' })
        .then((response) => {
            const { filename } = ContentDisposition.parse(response.headers['content-disposition']).parameters;

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        });

export const periodBan = async (param) => adminApi.post('/periodban/ban', param);
export const periodUnban = async (param) => adminApi.post('/periodban/unban', param);
export const getPeriodBanState = async (playerId) => adminApi.get(`/periodban/state?playerId=${playerId}`);
export const getPeriodBanLogs = async ({ page, limit }) =>
    adminApi.get(`/periodbanlogs?${qs.stringify({ page, limit })}`);
export const searchPeriodBanLogs = async ({ page, limit, param }) =>
    adminApi.post(`/periodbanlogs/search?${qs.stringify({ page, limit })}`, param);
export const addPeriodBanPlayers = (param) =>
    adminApi.post('/periodbanplayers/upload', param, {
        'Content-Type': 'multipart/form-data',
    });
export const getPeriodBanPlayers = async ({ page, limit }) =>
    adminApi.get(`/periodbanplayers?${qs.stringify({ page, limit })}`);
export const searchPeriodBanPlayers = async ({ page, limit, param }) =>
    adminApi.post(`/periodbanplayers/search?${qs.stringify({ page, limit })}`, param);
export const deletePeriodBanPlayer = async (oid) => adminApi.post('/periodbanplayers/delete', { id: oid });
