import { createAction } from 'redux-actions';

export const GET_LOGS_BY_PID = 'diceAccrueLog/GET_LOGS_BY_PID';
export const GET_LOGS_BY_PID_SUCCESS = 'diceAccrueLog/GET_LOGS_BY_PID_SUCCESS';
export const GET_LOGS_BY_PID_ERROR = 'diceAccrueLog/GET_LOGS_BY_PID_ERROR';

export const getLogsByPid = createAction(GET_LOGS_BY_PID, ({ page, limit, param }) => ({ page, limit, param }));

export const DOWNLOAD_LOGS = 'diceAccrueLog/DOWNLOAD_LOGS';
export const DOWNLOAD_LOGS_SUCCESS = 'diceAccrueLog/DOWNLOAD_LOGS_SUCCESS';
export const DOWNLOAD_LOGS_ERROR = 'diceAccrueLog/DOWNLOAD_LOGS_ERROR';

export const downloadLogs = createAction(DOWNLOAD_LOGS, ({ gameId, date, score }) => ({ gameId, date, score }));

export const ON_CHANGE = 'diceAccrueLog/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'diceAccureLog/INITIALIZE';
export const initialize = createAction(INITIALIZE, (payload) => payload);
