import { createAction } from 'redux-actions';

export const GET_NOTICES = 'notices/GET_NOTICES';
export const GET_NOTICES_SUCCESS = 'notices/GET_NOTICES_SUCCESS';
export const GET_NOTICES_ERROR = 'notices/GET_NOTICES_ERROR';

export const getNotices = createAction(GET_NOTICES, ({ gameId, page, limit }) => ({ gameId, page, limit }));

export const SEARCH_NOTICES = 'notices/SEARCH_NOTICES';
export const SEARCH_NOTICES_SUCCESS = 'notices/SEARCH_NOTICES_SUCCESS';
export const SEARCH_NOTICES_ERROR = 'notices/SEARCH_NOTICES_ERROR';

export const searchNotices = createAction(SEARCH_NOTICES, ({ gameId, page, limit, param }) => ({
    gameId,
    page,
    limit,
    param,
}));

export const GET_PUBLISHED_NOTICES = 'notices/GET_PUBLISHED_NOTICES';
export const GET_PUBLISHED_NOTICES_SUCCESS = 'notices/GET_PUBLISHED_NOTICES_SUCCESS';
export const GET_PUBLISHED_NOTICES_ERROR = 'notices/GET_PUBLISHED_NOTICES_ERROR';

export const getPublishedNotices = createAction(GET_PUBLISHED_NOTICES, (gameId) => gameId);

export const ORDER_PUBLISHED_NOTICES = 'notices/ORDER_PUBLISHED_NOTICES';
export const ORDER_PUBLISHED_NOTICES_SUCCESS = 'notices/ORDER_PUBLISHED_NOTICES_SUCCESS';
export const ORDER_PUBLISHED_NOTICES_ERROR = 'notices/ORDER_PUBLISHED_NOTICES_ERROR';

export const orderPublishedNotices = createAction(ORDER_PUBLISHED_NOTICES, ({ gameId, noticeIds }) => ({
    gameId,
    noticeIds,
}));

export const ON_CHANGE = 'notices/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'notices/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);

export const CLEAR = 'notices/CLEAR';
export const clear = createAction(CLEAR);

export const REGIST = 'notices/POST_NOTICES';
export const regist = createAction(REGIST, (payload) => {
    const { gameId, body } = payload;
    return {
        query: { gameId },
        body,
    };
});

export const EDIT = 'notices/EDIT_NOTICES';
export const edit = createAction(EDIT, (payload) => {
    const { gameId, body, oid, hasNextStap } = payload;
    return {
        query: { gameId, oid },
        body,
        hasNextStap,
    };
});

export const PUBLISH = 'notices/PUBLISH_NOTICES';
export const publish = createAction(PUBLISH, (oid) => {
    return oid;
});

export const UNPUBLISH = 'notices/UNPUBLISH_NOTICES';
export const unpublish = createAction(UNPUBLISH, (oid) => oid);

export const REPUBLISH = 'notices/REPUBLISH_NOTICES';
export const republish = createAction(REPUBLISH, (oid) => oid);

export const DELETE = 'notices/DELETE_NOTICES';
export const deleteNotice = createAction(DELETE, (oid) => oid);
