import { createAction } from 'redux-actions';

export const GET_CMCODE = 'cmcode/GET_CMCODE';
export const GET_CMCODE_SUCCESS = 'cmcode/GET_CMCODE_SUCCESS';
export const GET_CMCODE_ERROR = 'cmcode/GET_CMCODE_ERROR';

export const getCmCode = createAction(GET_CMCODE, ({ gb, cdMajor }) => ({ gb, cdMajor }));

export const GET_CMCODE_FOR_SELECT = 'cmcode/GET_CMCODE_FOR_SELECT';
export const GET_CMCODE_FOR_SELECT_SUCCESS = 'cmcode/GET_CMCODE_FOR_SELECT_SUCCESS';
export const GET_CMCODE_FOR_SELECT_ERROR = 'cmcode/GET_CMCODE_FOR_SELECT_ERROR';

export const getCmCodeForSelect = createAction(GET_CMCODE_FOR_SELECT, ({ gb, cdMajor }) => ({ gb, cdMajor }));

export const GET_CMCODES = 'cmcode/GET_CMCODES';
export const GET_CMCODES_SUCCESS = 'cmcode/GET_CMCODES_SUCCESS';
export const GET_CMCODES_ERROR = 'cmcode/GET_CMCODES_ERROR';

export const getCmCodes = createAction(GET_CMCODES, ({ gb, page }) => ({ gb, page }));

export const SEARCH_CMCODES = 'cmcode/SEARCH_CMCODES';
export const SEARCH_CMCODES_SUCCESS = 'cmcode/SEARCH_CMCODES_SUCCESS';
export const SEARCH_CMCODES_ERROR = 'cmcode/SEARCH_CMCODES_ERROR';

export const searchCmCodes = createAction(SEARCH_CMCODES, ({ gb, page, param }) => ({ gb, page, param }));

export const GET_CMCODES_FOR_SELECT = 'cmcode/GET_CMCODES_FOR_SELECT';
export const GET_CMCODES_FOR_SELECT_SUCCESS = 'cmcode/GET_CMCODES_FOR_SELECT_SUCCESS';
export const GET_CMCODES_FOR_SELECT_ERROR = 'cmcode/GET_CMCODES_FOR_SELECT_ERROR';

export const getCmcodesForSelect = createAction(GET_CMCODES_FOR_SELECT, (gb) => gb);

export const GET_PRODUCT_CMCODES_FOR_SELECT = 'cmcode/GET_PRODUCT_CMCODES_FOR_SELECT';
export const GET_PRODUCT_CMCODES_FOR_SELECT_SUCCESS = 'cmcode/GET_PRODUCT_CMCODES_FOR_SELECT_SUCCESS';
export const GET_PRODUCT_CMCODES_FOR_SELECT_ERROR = 'cmcode/GET_PRODUCT_CMCODES_FOR_SELECT_ERROR';

export const getProductCmCodesForSelect = createAction(GET_PRODUCT_CMCODES_FOR_SELECT, (gb) => gb);

export const CREATE_MAJOR = 'cmcode/CREATE_MAJOR';
export const CREATE_MAJOR_SUCCESS = 'cmcode/CREATE_MAJOR_SUCCESS';
export const CREATE_MAJOR_ERROR = 'cmcode/CREATE_MAJOR_ERROR';

export const createMajor = createAction(CREATE_MAJOR, ({ gb, param }) => ({ gb, param }));

export const EDIT_MAJOR = 'cmcode/EDIT_MAJOR';
export const EDIT_MAJOR_SUCCESS = 'cmcode/EDIT_MAJOR_SUCCESS';
export const EDIT_MAJOR_ERROR = 'cmcode/EDIT_MAJOR_ERROR';

export const editMajor = createAction(EDIT_MAJOR, ({ oid, gb, param }) => ({ oid, gb, param }));

export const CREATE_MINOR = 'cmcode/CREATE_MINOR';
export const CREATE_MINOR_SUCCESS = 'cmcode/CREATE_MINOR_SUCCESS';
export const CREATE_MINOR_ERROR = 'cmcode/CREATE_MINOR_ERROR';

export const createMinor = createAction(CREATE_MINOR, ({ gb, cdMajor, param }) => ({ gb, cdMajor, param }));

export const EDIT_MINOR = 'cmcode/EDIT_MINOR';
export const EDIT_MINOR_SUCCESS = 'cmcode/EDIT_MINOR_SUCCESS';
export const EDIT_MINOR_ERROR = 'cmcode/EDIT_MINOR_ERROR';

export const editMinor = createAction(EDIT_MINOR, ({ oid, param }) => ({ oid, param }));

export const FIND_MAJOR = 'cmcode/FIND_MAJOR';
export const findMajor = createAction(FIND_MAJOR, (cdMajor) => cdMajor);

export const FIND_MINORS = 'cmcode/FIND_MINORS';
export const findMinors = createAction(FIND_MINORS, (cdMajor) => cdMajor);

export const FIND_MINOR = 'cmcode/FIND_MINOR';
export const findMinor = createAction(FIND_MINOR, (cdMinor) => cdMinor);

export const ON_CHANGE = 'cmcode/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'cmcode/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);

export const CHANGE_ITEM_TO_UPDATED_DATA = 'cmcode/CHANGE_ITEM_TO_UPDATED_DATA';
export const changeItemToUpdatedData = createAction(CHANGE_ITEM_TO_UPDATED_DATA, ({ target, name, key, value }) => ({
    target,
    name,
    key,
    value,
}));
