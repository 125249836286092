import { createAction } from 'redux-actions';

export const GET_DATA_PRESETS_FOR_SELECT = 'dataPresets/GET_DATA_PRESETS_FOR_SELECT';
export const GET_DATA_PRESETS_FOR_SELECT_SUCCESS = 'dataPresets/GET_DATA_PRESETS_FOR_SELECT_SUCCESS';
export const GET_DATA_PRESETS_FOR_SELECT_ERROR = 'dataPresets/GET_DATA_PRESETS_FOR_SELECT_ERROR';

export const getDataPresetsForSelect = createAction(GET_DATA_PRESETS_FOR_SELECT);

export const GET_ACTIVATED_DATA_PRESETS_FOR_SELECT = 'dataPresets/GET_ACTIVATED_DATA_PRESETS_FOR_SELECT';
export const GET_ACTIVATED_DATA_PRESETS_FOR_SELECT_SUCCESS =
    'dataPresets/GET_ACTIVATED_DATA_PRESETS_FOR_SELECT_SUCCESS';
export const GET_ACTIVATED_DATA_PRESETS_FOR_SELECT_ERROR = 'dataPresets/GET_ACTIVATED_DATA_PRESETS_FOR_SELECT_ERROR';

export const getActivatedDataPresetsForSelect = createAction(GET_ACTIVATED_DATA_PRESETS_FOR_SELECT);

export const GET_DATA_PRESET = 'dataPresets/GET_DATA_PRESET';
export const GET_DATA_PRESET_SUCCESS = 'dataPresets/GET_DATA_PRESET_SUCCESS';
export const GET_DATA_PRESET_ERROR = 'dataPresets/GET_DATA_PRESET_ERROR';

export const getDataPreset = createAction(GET_DATA_PRESET, (oid) => oid);

export const CREATE_DATA_PRESET = 'dataPresets/CREATE_DATA_PRESET';
export const CREATE_DATA_PRESET_SUCCESS = 'dataPresets/CREATE_DATA_PRESET_SUCCESS';
export const CREATE_DATA_PRESET_ERROR = 'dataPresets/CREATE_DATA_PRESET_ERROR';

export const createDataPreset = createAction(CREATE_DATA_PRESET, (param) => param);

export const EDIT_DATA_PRESET = 'dataPresets/EDIT_DATA_PRESET';
export const EDIT_DATA_PRESET_SUCCESS = 'dataPresets/EDIT_DATA_PRESET_SUCCESS';
export const EDIT_DATA_PRESET_ERROR = 'dataPresets/EDIT_DATA_PRESET_ERROR';

export const editDataPreset = createAction(EDIT_DATA_PRESET, (param) => param);

export const GET_DATA_PRESETS = 'dataPresets/GET_DATA_PRESETS';
export const GET_DATA_PRESETS_SUCCESS = 'dataPresets/GET_DATA_PRESETS_SUCCESS';
export const GET_DATA_PRESETS_ERROR = 'dataPresets/GET_DATA_PRESETS_ERROR';

export const getDataPresets = createAction(GET_DATA_PRESETS, (param) => param);

export const SEARCH_DATA_PRESETS = 'dataPresets/SEARCH_DATA_PRESETS';
export const SEARCH_DATA_PRESETS_SUCCESS = 'dataPresets/SEARCH_DATA_PRESETS_SUCCESS';
export const SEARCH_DATA_PRESETS_ERROR = 'dataPresets/SEARCH_DATA_PRESETS_ERROR';

export const searchDataPresets = createAction(SEARCH_DATA_PRESETS, (param) => param);

export const ACTIVATE_DATA_PRESET = 'dataPresets/ACTIVATE_DATA_PRESET';
export const ACTIVATE_DATA_PRESET_SUCCESS = 'dataPresets/ACTIVATE_DATA_PRESET_SUCCESS';
export const ACTIVATE_DATA_PRESET_ERROR = 'dataPresets/ACTIVATE_DATA_PRESET_ERROR';

export const activateDataPreset = createAction(ACTIVATE_DATA_PRESET, (oid) => oid);

export const DEACTIVATE_DATA_PRESET = 'dataPresets/DEACTIVATE_DATA_PRESET';
export const DEACTIVATE_DATA_PRESET_SUCCESS = 'dataPresets/DEACTIVATE_DATA_PRESET_SUCCESS';
export const DEACTIVATE_DATA_PRESET_ERROR = 'dataPresets/DEACTIVATE_DATA_PRESET_ERROR';

export const deactivateDataPreset = createAction(DEACTIVATE_DATA_PRESET, (oid) => oid);

export const GET_DATA_PRESET_CATEGORIES = 'dataPresets/GET_DATA_PRESET_CATEGORIES';
export const GET_DATA_PRESET_CATEGORIES_SUCCESS = 'dataPresets/GET_DATA_PRESET_CATEGORIES_SUCCESS';
export const GET_DATA_PRESET_CATEGORIES_ERROR = 'dataPresets/GET_DATA_PRESET_CATEGORIES_ERROR';

export const getDataPresetCategories = createAction(GET_DATA_PRESET_CATEGORIES);

export const ON_CHANGE = 'dataPresets/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'dataPresets/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
