import { handleActions, combineActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions, handleAsyncActionsWithHeader } from 'src/lib/asyncUtils';
import {
    GET_PLAYER_BY_OID,
    GET_PLAYER_BY_OID_SUCCESS,
    GET_PLAYER_BY_OID_ERROR,
    GET_PLAYER_BY_PID,
    GET_PLAYER_BY_PID_SUCCESS,
    GET_PLAYER_BY_PID_ERROR,
    GET_PLAYER_BY_PID_FROM_FIREBASE,
    GET_PLAYER_BY_PID_FROM_FIREBASE_SUCCESS,
    GET_PLAYER_BY_PID_FROM_FIREBASE_ERROR,
    GET_PLAYER_FROM_FIREBASE,
    GET_PLAYER_FROM_FIREBASE_SUCCESS,
    GET_PLAYER_FROM_FIREBASE_ERROR,
    GET_PLAYER_LOGS,
    GET_PLAYER_LOGS_SUCCESS,
    GET_PLAYER_LOGS_ERROR,
    GET_PLAYER_BATTLE_LOGS,
    GET_PLAYER_BATTLE_LOGS_SUCCESS,
    GET_PLAYER_BATTLE_LOGS_ERROR,
    GET_PLAYERS,
    GET_PLAYERS_SUCCESS,
    GET_PLAYERS_ERROR,
    SEARCH_PLAYERS,
    SEARCH_PLAYERS_SUCCESS,
    SEARCH_PLAYERS_ERROR,
    SEARCH_PLAYERS_FROM_FIREBASE,
    SEARCH_PLAYERS_FROM_FIREBASE_SUCCESS,
    SEARCH_PLAYERS_FROM_FIREBASE_ERROR,
    COMPARE_PLAYER_DATA,
    COMPARE_PLAYER_DATA_SUCCESS,
    COMPARE_PLAYER_DATA_ERROR,
    GET_SNAPSHOT_LIST,
    GET_SNAPSHOT_LIST_SUCCESS,
    GET_SNAPSHOT_LIST_ERROR,
    GET_PLAYER_CREW,
    GET_PLAYER_CREW_SUCCESS,
    GET_PLAYER_CREW_ERROR,
    UPDATE_PLAYER_BY_PRESET,
    UPDATE_PLAYER_BY_PRESET_SUCCESS,
    UPDATE_PLAYER_BY_PRESET_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';

const initialState = {
    result: reducerUtils.initial(false),
    player: reducerUtils.initial({}),
    playerLogs: reducerUtils.initial([]),
    playerBattleLogs: reducerUtils.initial([]),
    currentPlayer: reducerUtils.initial({}),
    playerCrew: reducerUtils.initial({}),
    players: reducerUtils.initial([]),
    compareResult: reducerUtils.initial([]),
    snapshotList: reducerUtils.initial([]),
    target: null,
    searchForm: {
        startDt: '2020-01-01',
        endDt: '2030-01-01',
    },
    isSearch: false,
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(GET_PLAYER_BY_OID, GET_PLAYER_BY_OID_SUCCESS, GET_PLAYER_BY_OID_ERROR)]: (state, action) =>
            handleAsyncActions(GET_PLAYER_BY_OID, 'player', true)(state, action),
        [combineActions(GET_PLAYER_BY_PID, GET_PLAYER_BY_PID_SUCCESS, GET_PLAYER_BY_PID_ERROR)]: (state, action) =>
            handleAsyncActions(GET_PLAYER_BY_PID, 'player', true)(state, action),
        [combineActions(
            GET_PLAYER_BY_PID_FROM_FIREBASE,
            GET_PLAYER_BY_PID_FROM_FIREBASE_SUCCESS,
            GET_PLAYER_BY_PID_FROM_FIREBASE_ERROR,
        )]: (state, action) =>
            handleAsyncActions(GET_PLAYER_BY_PID_FROM_FIREBASE, 'currentPlayer', true)(state, action),
        [combineActions(GET_PLAYER_FROM_FIREBASE, GET_PLAYER_FROM_FIREBASE_SUCCESS, GET_PLAYER_FROM_FIREBASE_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_PLAYER_FROM_FIREBASE, 'currentPlayer', true)(state, action),
        [combineActions(GET_PLAYERS, GET_PLAYERS_SUCCESS, GET_PLAYERS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(GET_PLAYERS, 'players', true)(state, action),
        [combineActions(GET_PLAYER_LOGS, GET_PLAYER_LOGS_SUCCESS, GET_PLAYER_LOGS_ERROR)]: (state, action) =>
            handleAsyncActions(GET_PLAYER_LOGS, 'playerLogs', true)(state, action),
        [combineActions(GET_PLAYER_BATTLE_LOGS, GET_PLAYER_BATTLE_LOGS_SUCCESS, GET_PLAYER_BATTLE_LOGS_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_PLAYER_BATTLE_LOGS, 'playerBattleLogs', true)(state, action),
        [combineActions(SEARCH_PLAYERS, SEARCH_PLAYERS_SUCCESS, SEARCH_PLAYERS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(SEARCH_PLAYERS, 'players', true)(state, action),
        [combineActions(
            SEARCH_PLAYERS_FROM_FIREBASE,
            SEARCH_PLAYERS_FROM_FIREBASE_SUCCESS,
            SEARCH_PLAYERS_FROM_FIREBASE_ERROR,
        )]: (state, action) => handleAsyncActions(SEARCH_PLAYERS_FROM_FIREBASE, 'players', true)(state, action),
        [combineActions(COMPARE_PLAYER_DATA, COMPARE_PLAYER_DATA_SUCCESS, COMPARE_PLAYER_DATA_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(COMPARE_PLAYER_DATA, 'compareResult', true)(state, action),
        [combineActions(GET_SNAPSHOT_LIST, GET_SNAPSHOT_LIST_SUCCESS, GET_SNAPSHOT_LIST_ERROR)]: (state, action) =>
            handleAsyncActions(GET_SNAPSHOT_LIST, 'snapshotList', true)(state, action),
        [combineActions(GET_PLAYER_CREW, GET_PLAYER_CREW_SUCCESS, GET_PLAYER_CREW_ERROR)]: (state, action) =>
            handleAsyncActions(GET_PLAYER_CREW, 'playerCrew', true)(state, action),
        [combineActions(UPDATE_PLAYER_BY_PRESET, UPDATE_PLAYER_BY_PRESET_SUCCESS, UPDATE_PLAYER_BY_PRESET_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(UPDATE_PLAYER_BY_PRESET, 'result', true)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
