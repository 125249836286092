import { takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import { GET_RECEIPT_BY_OID, GET_RECEIPTS, SEARCH_RECEIPTS, GET_MY_LATEST_RECEIPTS } from './action';

const getReceiptByOidSaga = createPromiseSaga(GET_RECEIPT_BY_OID, api.getReceiptByOid);
const getReceiptsSaga = createPromiseSagaWithHeader(GET_RECEIPTS, api.getReceipts);
const searchReceiptsSaga = createPromiseSagaWithHeader(SEARCH_RECEIPTS, api.searchReceipts);
const getMyLatestReceiptsSaga = createPromiseSaga(GET_MY_LATEST_RECEIPTS, api.getMyLatestReceipts);

export default function* receiptSaga() {
    yield takeEvery(GET_RECEIPT_BY_OID, getReceiptByOidSaga);
    yield takeEvery(GET_RECEIPTS, getReceiptsSaga);
    yield takeEvery(SEARCH_RECEIPTS, searchReceiptsSaga);
    yield takeEvery(GET_MY_LATEST_RECEIPTS, getMyLatestReceiptsSaga);
}
