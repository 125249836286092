import { put, call, takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSagaWithHeader, createPromiseSaga } from 'src/lib/asyncUtils';
import {
    GET_CMCODE,
    GET_CMCODE_FOR_SELECT,
    GET_CMCODES,
    SEARCH_CMCODES,
    GET_CMCODES_FOR_SELECT,
    GET_PRODUCT_CMCODES_FOR_SELECT,
    CREATE_MAJOR,
    EDIT_MAJOR,
    CREATE_MINOR,
    EDIT_MINOR,
    ON_CHANGE,
    CHANGE_ITEM_TO_UPDATED_DATA,
} from './action';
import { CLOSE_MODAL, OPEN_MODAL } from 'src/store/modules/modal';

const getCmCodeSaga = createPromiseSaga(GET_CMCODE, api.getCmCode);
const getCmCodeForSelectSaga = createPromiseSaga(GET_CMCODE_FOR_SELECT, api.getCmCodeForSelect);
const getCmCodesSaga = createPromiseSagaWithHeader(GET_CMCODES, api.getCmCodes);
const getProductCmCodesForSelectSaga = createPromiseSaga(
    GET_PRODUCT_CMCODES_FOR_SELECT,
    api.getProductCmCodesForSelect,
);
const searchCmCodesSaga = createPromiseSagaWithHeader(SEARCH_CMCODES, api.searchCmCodes);
const getCmcodesForSelectSaga = createPromiseSaga(GET_CMCODES_FOR_SELECT, api.getCmCodesForSelect);

function* createMajorSaga(action) {
    const { gb, param } = action.payload;

    try {
        yield call(api.createMajor, { gb, param });

        yield put({ type: `${CREATE_MAJOR}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });
        yield put({ type: CLOSE_MODAL, payload: 'createCmCodeMajor' });

        yield put({ type: GET_CMCODES, payload: { gb, page: 1 } });
        yield put({ type: ON_CHANGE, payload: { name: 'currentPage', value: 1 } });
    } catch (e) {
        yield put({ type: `${CREATE_MAJOR}_ERROR`, error: true, payload: e });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* editMajorSaga(action) {
    const { oid, gb, param } = action.payload;

    try {
        yield call(api.editMajor, { oid, gb, param });

        yield put({ type: `${EDIT_MAJOR}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });
        yield put({ type: CLOSE_MODAL, payload: 'editCmCodeMajor' });
    } catch (e) {
        yield put({ type: `${EDIT_MAJOR}_ERROR`, error: true, payload: e });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* createMinorSaga(action) {
    const { gb, cdMajor, param } = action.payload;

    try {
        yield call(api.createMinor, { gb, cdMajor, param });

        yield put({ type: `${CREATE_MINOR}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });
        yield put({ type: `${CLOSE_MODAL}`, payload: 'createCmCodeMinor' });

        yield put({ type: GET_CMCODES, payload: { gb, page: 1 } });
        yield put({ type: ON_CHANGE, payload: { name: 'currentPage', value: 1 } });
    } catch (e) {
        yield put({ type: `${CREATE_MINOR}_ERROR`, error: true, payload: e });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* editMinorSaga(action) {
    const { oid, param } = action.payload;

    try {
        const { data } = yield call(api.editMinor, { oid, param });

        yield put({ type: `${EDIT_MINOR}_SUCCESS`, payload: true });
        yield put({
            type: CHANGE_ITEM_TO_UPDATED_DATA,
            payload: {
                name: 'minors',
                key: '_id',
                value: data,
            },
        });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });
        yield put({ type: CLOSE_MODAL, payload: 'editCmCodeMinor' });
    } catch (e) {
        yield put({ type: `${EDIT_MINOR}_ERROR`, error: true, payload: e });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* cmcodeSaga() {
    yield takeEvery(GET_CMCODE, getCmCodeSaga);
    yield takeEvery(GET_CMCODE_FOR_SELECT, getCmCodeForSelectSaga);
    yield takeEvery(GET_CMCODES, getCmCodesSaga);
    yield takeEvery(SEARCH_CMCODES, searchCmCodesSaga);
    yield takeEvery(GET_CMCODES_FOR_SELECT, getCmcodesForSelectSaga);
    yield takeEvery(GET_PRODUCT_CMCODES_FOR_SELECT, getProductCmCodesForSelectSaga);
    yield takeEvery(CREATE_MAJOR, createMajorSaga);
    yield takeEvery(EDIT_MAJOR, editMajorSaga);
    yield takeEvery(CREATE_MINOR, createMinorSaga);
    yield takeEvery(EDIT_MINOR, editMinorSaga);
}
