import { combineActions, handleActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions } from 'src/lib/asyncUtils';
import {
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    LOGIN,
    LOGIN_SUCCESS,
    CHECK,
    LOGIN_ERROR,
    CHECK_SUCCESS,
    CHECK_ERROR,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
} from './action';

const initialState = {
    result: reducerUtils.initial(false),
    userInfo: reducerUtils.initial(null),
    isLogin: false,
};

export default handleActions(
    {
        [LOGIN]: (state) => ({
            ...state,
            userInfo: reducerUtils.loading({}),
        }),
        [LOGIN_SUCCESS]: (state, action) => {
            const { data, headers } = action.payload;

            const token = headers['x-percent-token'];
            const refreshToken = headers['x-percent-rtoken'];

            localStorage.setItem('pt', token);
            localStorage.setItem('pr', refreshToken);

            return {
                ...state,
                userInfo: reducerUtils.success(data),
                isLogin: true,
            };
        },
        [LOGIN_ERROR]: (state, action) => ({
            ...state,
            userInfo: reducerUtils.error(action.payload),
            isLogin: false,
        }),
        [CHECK]: (state) => ({
            ...state,
            userInfo: reducerUtils.loading({}),
        }),
        [CHECK_SUCCESS]: (state, action) => {
            const { data, headers } = action.payload;

            const token = headers['x-percent-token'];

            /** 토큰이 재발급된 경우 */
            if (token) localStorage.setItem('pt', token);

            return {
                ...state,
                userInfo: reducerUtils.success(data),
                isLogin: true,
            };
        },
        [CHECK_ERROR]: (state) => ({
            ...state,
            userInfo: initialState.userInfo,
            isLogin: false,
        }),
        [combineActions(LOGOUT_SUCCESS, LOGOUT_ERROR)]: () => {
            localStorage.removeItem('pt');
            localStorage.removeItem('pr');

            return initialState;
        },
        [combineActions(REGISTER, REGISTER_SUCCESS, REGISTER_ERROR)]: (state, action) =>
            handleAsyncActions(REGISTER, 'result', false)(state, action),
    },
    initialState,
);
