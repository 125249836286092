import { handleActions, combineActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions, handleAsyncActionsWithHeader } from 'src/lib/asyncUtils';
import {
    GET_SCHEDULES_BY_GAME_ID,
    GET_SCHEDULES_BY_GAME_ID_SUCCESS,
    GET_SCHEDULES_BY_GAME_ID_ERROR,
    CREATE_SCHEDULE,
    CREATE_SCHEDULE_SUCCESS,
    CREATE_SCHEDULE_ERROR,
    DELETE_SCHEDULE,
    DELETE_SCHEDULE_SUCCESS,
    DELETE_SCHEDULE_ERROR,
    GET_SCHEDULE_LOGS_BY_GAME_ID,
    GET_SCHEDULE_LOGS_BY_GAME_ID_SUCCESS,
    GET_SCHEDULE_LOGS_BY_GAME_ID_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';

const initialState = {
    selectedGame: null,
    schedules: reducerUtils.initial([]),
    logs: reducerUtils.initial([]),
    result: reducerUtils.initial(false),
    currentPage: 1,
    lastPage: 1,
    totalCount: 0,
};

export default handleActions(
    {
        [combineActions(GET_SCHEDULES_BY_GAME_ID, GET_SCHEDULES_BY_GAME_ID_SUCCESS, GET_SCHEDULES_BY_GAME_ID_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_SCHEDULES_BY_GAME_ID, 'schedules', true)(state, action),
        [combineActions(CREATE_SCHEDULE, CREATE_SCHEDULE_SUCCESS, CREATE_SCHEDULE_ERROR)]: (state, action) =>
            handleAsyncActions(GET_SCHEDULES_BY_GAME_ID, 'result', false)(state, action),
        [combineActions(DELETE_SCHEDULE, DELETE_SCHEDULE_SUCCESS, DELETE_SCHEDULE_ERROR)]: (state, action) =>
            handleAsyncActions(DELETE_SCHEDULE, 'result', false)(state, action),
        [combineActions(
            GET_SCHEDULE_LOGS_BY_GAME_ID,
            GET_SCHEDULE_LOGS_BY_GAME_ID_SUCCESS,
            GET_SCHEDULE_LOGS_BY_GAME_ID_ERROR,
        )]: (state, action) => handleAsyncActionsWithHeader(GET_SCHEDULE_LOGS_BY_GAME_ID, 'logs', true)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
