import { createAction } from 'redux-actions';

export const GET_METADATA_BY_OID = 'diceAccrue/GET_METADATA_BY_OID';
export const GET_METADATA_BY_OID_SUCCESS = 'diceAccrue/GET_METADATA_BY_OID_SUCCESS';
export const GET_METADATA_BY_OID_ERROR = 'diceAccrue/GET_METADATA_BY_OID_ERROR';

export const getMetadataByOid = createAction(GET_METADATA_BY_OID, (id) => id);

export const GET_METADATAS_FOR_SELECT = 'diceAccrue/GET_METADATAS_FOR_SELECT';
export const GET_METADATAS_FOR_SELECT_SUCCESS = 'diceAccrue/GET_METADATAS_FOR_SELECT_SUCCESS';
export const GET_METADATAS_FOR_SELECT_ERROR = 'diceAccrue/GET_METADATAS_FOR_SELECT_ERROR';

export const getMetadatasForSelect = createAction(GET_METADATAS_FOR_SELECT);

export const EDIT_METADATA = 'diceAccrue/EDIT_METADATA';
export const EDIT_METADATA_SUCCESS = 'diceAccrue/EDIT_METADATA_SUCCESS';
export const EDIT_METADATA_ERROR = 'diceAccrue/EDIT_METADATA_ERROR';

export const editMetadata = createAction(EDIT_METADATA, ({ oid, param }) => ({ oid, param }));

export const GET_RESULTS = 'diceAccrue/GET_RESULTS';
export const GET_RESULTS_SUCCESS = 'diceAccrue/GET_RESULTS_SUCCESS';
export const GET_RESULTS_ERROR = 'diceAccrue/GET_RESULTS_ERROR';

export const getResults = createAction(GET_RESULTS, ({ page, limit }) => ({ page, limit }));

export const SEARCH_RESULTS = 'diceAccrue/SEARCH_RESULTS';
export const SEARCH_RESULTS_SUCCESS = 'diceAccrue/SEARCH_RESULTS_SUCCESS';
export const SEARCH_RESULTS_ERROR = 'diceAccrue/SEARCH_RESULTS_ERROR';

export const searchResults = createAction(SEARCH_RESULTS, ({ page, limit, param }) => ({ page, limit, param }));

export const ON_CHANGE = 'diceAccrue/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'diceAccure/INITIALIZE';
export const initialize = createAction(INITIALIZE, (payload) => payload);
