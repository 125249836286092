import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { logout as logoutRequest } from 'src/store/modules/auth';

export default function useLogout() {
    const dispatch = useDispatch();
    const logout = useCallback(() => dispatch(logoutRequest()), [dispatch]);

    return logout;
}
