import { handleActions, combineActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions } from 'src/lib/asyncUtils';
import {
    GET_PACKAGES,
    GET_PACKAGES_SUCCESS,
    GET_PACKAGES_ERROR,
    GET_COUNT_BY_PACKAGE_TYPE,
    GET_COUNT_BY_PACKAGE_TYPE_SUCCESS,
    GET_COUNT_BY_PACKAGE_TYPE_ERROR,
    GET_PACKAGES_FOR_SELECT,
    GET_PACKAGES_FOR_SELECT_SUCCESS,
    GET_PACKAGES_FOR_SELECT_ERROR,
    CREATE_PACKAGE,
    CREATE_PACKAGE_SUCCESS,
    CREATE_PACKAGE_ERROR,
    EDIT_PACKAGE,
    EDIT_PACKAGE_SUCCESS,
    EDIT_PACKAGE_ERROR,
    FIND_PACKAGE,
    ADD_ITEM,
    DELETE_ITEM,
    EDIT_ITEM,
    ON_CHANGE,
    INITIALIZE,
} from './action';

const initialState = {
    result: reducerUtils.initial(false),
    packages: reducerUtils.initial([]),
    count: reducerUtils.initial([]),
    package: null,
};

export default handleActions(
    {
        [combineActions(GET_PACKAGES, GET_PACKAGES_SUCCESS, GET_PACKAGES_ERROR)]: (state, action) =>
            handleAsyncActions(GET_PACKAGES, 'packages', true)(state, action),
        [combineActions(
            GET_COUNT_BY_PACKAGE_TYPE,
            GET_COUNT_BY_PACKAGE_TYPE_SUCCESS,
            GET_COUNT_BY_PACKAGE_TYPE_ERROR,
        )]: (state, action) => handleAsyncActions(GET_COUNT_BY_PACKAGE_TYPE, 'count', true)(state, action),
        [combineActions(CREATE_PACKAGE, CREATE_PACKAGE_SUCCESS, CREATE_PACKAGE_ERROR)]: (state, action) =>
            handleAsyncActions(CREATE_PACKAGE, 'result', false)(state, action),
        [combineActions(EDIT_PACKAGE, EDIT_PACKAGE_SUCCESS, EDIT_PACKAGE_ERROR)]: (state, action) =>
            handleAsyncActions(EDIT_PACKAGE, 'result', false)(state, action),
        [combineActions(GET_PACKAGES_FOR_SELECT, GET_PACKAGES_FOR_SELECT_SUCCESS, GET_PACKAGES_FOR_SELECT_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_PACKAGES_FOR_SELECT, 'packages', true)(state, action),
        [FIND_PACKAGE]: (state, action) => {
            const id = action.payload;
            const packages = state.packages.data;

            const targetIndex = packages.findIndex(({ _id }) => _id === id);
            const selectedPackage = packages[targetIndex];

            const updatedPackages = packages.map((pkg, index) => ({ ...pkg, active: index === targetIndex }));

            return {
                ...state,
                packages: {
                    ...state.packages,
                    data: updatedPackages,
                },
                package: selectedPackage,
            };
        },
        [ADD_ITEM]: (state, action) => {
            const { target, name, value } = action.payload;

            const currentState = target ? state[target][name] : state[name];

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: [...currentState, value],
                      },
                  }
                : {
                      ...state,
                      [name]: [...currentState, value],
                  };
        },
        [DELETE_ITEM]: (state, action) => {
            const { target, name, index } = action.payload;

            const currentState = target ? state[target][name] : state[name];

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: [
                              ...currentState.slice(0, index),
                              ...currentState.slice(index + 1, currentState.length),
                          ],
                      },
                  }
                : {
                      ...state,
                      [name]: [...currentState.slice(0, index), ...currentState.slice(index + 1, currentState.length)],
                  };
        },
        [EDIT_ITEM]: (state, action) => {
            const { target, name, index, key, value, refName, refValue } = action.payload;

            const currentState = target ? state[target][name] : state[name];

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: [
                              ...currentState.slice(0, index),
                              key
                                  ? {
                                        ...currentState[index],
                                        [key]: value,
                                        [refName]: refValue,
                                    }
                                  : value,
                              ...currentState.slice(index + 1, currentState.length),
                          ],
                      },
                  }
                : {
                      ...state,
                      [name]: [
                          ...currentState.slice(0, index),
                          key
                              ? {
                                    ...currentState[index],
                                    [key]: value,
                                    [key]: value,
                                    [refName]: refValue,
                                }
                              : value,
                          ...currentState.slice(index + 1, currentState.length),
                      ],
                  };
        },
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
