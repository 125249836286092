import { createAction } from 'redux-actions';

export const PROVIDE_PACKAGE = 'provide/PROVIDE_PAKCAGE';
export const PROVIDE_PACKAGE_SUCCESS = 'provide/PROVIDE_PACKAGE_SUCCESS';
export const PROVIDE_PACKAGE_ERROR = 'provide/PROVIDE_PACKAGE_ERROR';

export const providePackage = createAction(PROVIDE_PACKAGE, ({ gameId, param }) => ({ gameId, param }));

export const PROVIDE_CUSTOM = 'provide/PROVIDE_CUSTOM';
export const PROVIDE_CUSTOM_SUCCESS = 'provide/PROVIDE_CUSTOM_SUCCESS';
export const PROVIDE_CUSTOM_ERROR = 'provide/PROVIDE_CUSTOM_ERROR';

export const provideCustom = createAction(PROVIDE_CUSTOM, ({ gameId, param }) => ({ gameId, param }));

export const PROVIDE_LEGEND_DICE = 'provide/PROVIDE_LEGEND_DICE';
export const PROVIDE_LEGEND_DICE_SUCCESS = 'provide/PROVIDE_LEGEND_DICE_SUCCESS';
export const PROVIDE_LEGEND_DICE_ERROR = 'provide/PROVIDE_LEGEND_DICE_ERROR';

export const provideLegendDice = createAction(PROVIDE_LEGEND_DICE, ({ gameId, param }) => ({ gameId, param }));

export const PROVIDE_TOKEN = 'provide/PROVIDE_TOKEN';
export const PROVIDE_TOKEN_SUCCESS = 'provide/PROVIDE_TOKEN_SUCCESS';
export const PROVIDE_TOKEN_ERROR = 'provide/PROVIDE_TOKEN_ERROR';

export const provideToken = createAction(PROVIDE_TOKEN, ({ gameId, param }) => ({ gameId, param }));

export const PROVIDE_PASS = 'provide/PROVIDE_PASS';
export const PROVIDE_PASS_SUCCESS = 'provide/PROVIDE_PASS_SUCCESS';
export const PROVIDE_PASS_ERROR = 'provide/PROVIDE_PASS_ERROR';

export const providePass = createAction(PROVIDE_PASS, ({ gameId, param }) => ({ gameId, param }));

export const PROVIDE_SKIN = 'provide/PROVIDE_SKIN';
export const PROVIDE_SKIN_SUCCESS = 'provide/PROVIDE_SKIN_SUCCESS';
export const PROVIDE_SKIN_ERROR = 'provide/PROVIDE_SKIN_ERROR';

export const provideSkin = createAction(PROVIDE_SKIN, ({ gameId, param }) => ({ gameId, param }));

export const PROVIDE_EMBLEM = 'provide/PROVIDE_EMBLEM';
export const PROVIDE_EMBLEM_SUCCESS = 'provide/PROVIDE_EMBLEM_SUCCESS';
export const PROVIDE_EMBLEM_ERROR = 'provide/PROVIDE_EMBLEM_ERROR';

export const provideEmblem = createAction(PROVIDE_EMBLEM, ({ gameId, param }) => ({ gameId, param }));

export const PROVIDE_EMOTICON = 'provide/PROVIDE_EMOTICON';
export const PROVIDE_EMOTICON_SUCCESS = 'provide/PROVIDE_EMOTICON_SUCCESS';
export const PROVIDE_EMOTICON_ERROR = 'provide/PROVIDE_EMOTICON_ERROR';

export const provideEmoticon = createAction(PROVIDE_EMOTICON, ({ gameId, param }) => ({ gameId, param }));

export const PROVIDE_CUSTOM_TO_CREW = 'provide/PROVIDE_CUSTOM_TO_CREW';
export const PROVIDE_CUSTOM_TO_CREW_SUCCESS = 'provide/PROVIDE_CUSTOM_TO_CREW_SUCCESS';
export const PROVIDE_CUSTOM_TO_CREW_ERROR = 'provide/PROVIDE_CUSTOM_TO_CREW_ERROR';

export const provideCustomToCrew = createAction(PROVIDE_CUSTOM_TO_CREW, ({ gameId, param }) => ({ gameId, param }));

export const SET_PROVIDE_TARGET = 'provide/SET_PROVIDE_TARGET';
export const setProvideTarget = createAction(SET_PROVIDE_TARGET);
