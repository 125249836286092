import { handleActions, combineActions } from 'redux-actions';
import {
    GET_DATA_PRESET_LOGS,
    GET_DATA_PRESET_LOGS_SUCCESS,
    GET_DATA_PRESET_LOGS_ERROR,
    SEARCH_DATA_PRESET_LOGS,
    SEARCH_DATA_PRESET_LOGS_SUCCESS,
    SEARCH_DATA_PRESET_LOGS_ERROR,
    DOWNLOAD_DATA_PRESET_BACKUP_LOG,
    DOWNLOAD_DATA_PRESET_BACKUP_LOG_SUCCESS,
    DOWNLOAD_DATA_PRESET_BACKUP_LOG_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';
import { reducerUtils, handleAsyncActionsWithHeader, handleAsyncActions } from 'src/lib/asyncUtils';

const initialState = {
    result: reducerUtils.initial(false),
    presetLogs: reducerUtils.initial([]),
    isSearch: false,
    searchForm: {
        helpshift: '',
        playerId: '',
    },
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(GET_DATA_PRESET_LOGS, GET_DATA_PRESET_LOGS_SUCCESS, GET_DATA_PRESET_LOGS_ERROR)]: (
            state,
            action,
        ) => handleAsyncActionsWithHeader(GET_DATA_PRESET_LOGS, 'presetLogs', true)(state, action),
        [combineActions(SEARCH_DATA_PRESET_LOGS, SEARCH_DATA_PRESET_LOGS_SUCCESS, SEARCH_DATA_PRESET_LOGS_ERROR)]: (
            state,
            action,
        ) => handleAsyncActionsWithHeader(SEARCH_DATA_PRESET_LOGS, 'presetLogs', true)(state, action),
        [combineActions(
            DOWNLOAD_DATA_PRESET_BACKUP_LOG,
            DOWNLOAD_DATA_PRESET_BACKUP_LOG_SUCCESS,
            DOWNLOAD_DATA_PRESET_BACKUP_LOG_ERROR,
        )]: (state, action) => handleAsyncActions(DOWNLOAD_DATA_PRESET_BACKUP_LOG, 'result', true)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
