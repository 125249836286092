import { handleActions, combineActions } from 'redux-actions';
import {
    GET_DATA_PRESETS_FOR_SELECT,
    GET_DATA_PRESETS_FOR_SELECT_SUCCESS,
    GET_DATA_PRESETS_FOR_SELECT_ERROR,
    GET_ACTIVATED_DATA_PRESETS_FOR_SELECT,
    GET_ACTIVATED_DATA_PRESETS_FOR_SELECT_SUCCESS,
    GET_ACTIVATED_DATA_PRESETS_FOR_SELECT_ERROR,
    GET_DATA_PRESET,
    GET_DATA_PRESET_SUCCESS,
    GET_DATA_PRESET_ERROR,
    CREATE_DATA_PRESET,
    CREATE_DATA_PRESET_SUCCESS,
    CREATE_DATA_PRESET_ERROR,
    GET_DATA_PRESETS,
    GET_DATA_PRESETS_SUCCESS,
    GET_DATA_PRESETS_ERROR,
    SEARCH_DATA_PRESETS,
    SEARCH_DATA_PRESETS_SUCCESS,
    SEARCH_DATA_PRESETS_ERROR,
    EDIT_DATA_PRESET,
    EDIT_DATA_PRESET_SUCCESS,
    EDIT_DATA_PRESET_ERROR,
    ACTIVATE_DATA_PRESET,
    ACTIVATE_DATA_PRESET_SUCCESS,
    ACTIVATE_DATA_PRESET_ERROR,
    DEACTIVATE_DATA_PRESET,
    DEACTIVATE_DATA_PRESET_SUCCESS,
    DEACTIVATE_DATA_PRESET_ERROR,
    GET_DATA_PRESET_CATEGORIES,
    GET_DATA_PRESET_CATEGORIES_SUCCESS,
    GET_DATA_PRESET_CATEGORIES_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';
import { reducerUtils, handleAsyncActions, handleAsyncActionsWithHeader } from 'src/lib/asyncUtils';

const initialState = {
    result: reducerUtils.initial(false),
    presetsForSelect: reducerUtils.initial([]),
    preset: reducerUtils.initial({}),
    presets: reducerUtils.initial([]),
    categories: reducerUtils.initial([]),
    isSearch: false,
    searchForm: {
        description: '',
        isActivate: null,
    },
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(
            GET_DATA_PRESETS_FOR_SELECT,
            GET_DATA_PRESETS_FOR_SELECT_SUCCESS,
            GET_DATA_PRESETS_FOR_SELECT_ERROR,
        )]: (state, action) => handleAsyncActions(GET_DATA_PRESETS_FOR_SELECT, 'presetsForSelect', true)(state, action),
        [combineActions(
            GET_ACTIVATED_DATA_PRESETS_FOR_SELECT,
            GET_ACTIVATED_DATA_PRESETS_FOR_SELECT_SUCCESS,
            GET_ACTIVATED_DATA_PRESETS_FOR_SELECT_ERROR,
        )]: (state, action) =>
            handleAsyncActions(GET_ACTIVATED_DATA_PRESETS_FOR_SELECT, 'presetsForSelect', true)(state, action),
        [combineActions(GET_DATA_PRESET, GET_DATA_PRESET_SUCCESS, GET_DATA_PRESET_ERROR)]: (state, action) =>
            handleAsyncActions(GET_DATA_PRESET, 'preset', true)(state, action),
        [combineActions(CREATE_DATA_PRESET, CREATE_DATA_PRESET_SUCCESS, CREATE_DATA_PRESET_ERROR)]: (state, action) =>
            handleAsyncActions(CREATE_DATA_PRESET, 'result', true)(state, action),
        [combineActions(GET_DATA_PRESETS, GET_DATA_PRESETS_SUCCESS, GET_DATA_PRESETS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(GET_DATA_PRESETS, 'presets', true)(state, action),
        [combineActions(SEARCH_DATA_PRESETS, SEARCH_DATA_PRESETS_SUCCESS, SEARCH_DATA_PRESETS_ERROR)]: (
            state,
            action,
        ) => handleAsyncActionsWithHeader(SEARCH_DATA_PRESETS, 'presets', true)(state, action),
        [combineActions(EDIT_DATA_PRESET, EDIT_DATA_PRESET_SUCCESS, EDIT_DATA_PRESET_ERROR)]: (state, action) =>
            handleAsyncActions(EDIT_DATA_PRESET, 'result', true)(state, action),
        [combineActions(ACTIVATE_DATA_PRESET, ACTIVATE_DATA_PRESET_SUCCESS, ACTIVATE_DATA_PRESET_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(ACTIVATE_DATA_PRESET, 'result', true)(state, action),
        [combineActions(DEACTIVATE_DATA_PRESET, DEACTIVATE_DATA_PRESET_SUCCESS, DEACTIVATE_DATA_PRESET_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(DEACTIVATE_DATA_PRESET, 'result', true)(state, action),
        [combineActions(
            GET_DATA_PRESET_CATEGORIES,
            GET_DATA_PRESET_CATEGORIES_SUCCESS,
            GET_DATA_PRESET_CATEGORIES_ERROR,
        )]: (state, action) => handleAsyncActions(GET_DATA_PRESET_CATEGORIES, 'categories', true)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
