import { createAction } from 'redux-actions';

export const GET_CREW_MAIL_LOG_BY_OID = 'crewMailLogs/GET_CREW_MAIL_LOG_BY_OID';
export const GET_CREW_MAIL_LOG_BY_OID_SUCCESS = 'crewMailLogs/GET_CREW_MAIL_LOG_BY_OID_SUCCESS';
export const GET_CREW_MAIL_LOG_BY_OID_ERROR = 'crewMailLogs/GET_CREW_MAIL_LOG_BY_OID_ERROR';

export const getCrewMailLogByOid = createAction(GET_CREW_MAIL_LOG_BY_OID, (id) => id);

export const GET_CREW_MAIL_LOGS_BY_CREW_ID = 'crewMailLogs/GET_CREW_MAIL_LOGS_BY_CREW_ID';
export const GET_CREW_MAIL_LOGS_BY_CREW_ID_SUCCESS = 'crewMailLogs/GET_CREW_MAIL_LOGS_BY_CREW_ID_SUCCESS';
export const GET_CREW_MAIL_LOGS_BY_CREW_ID_ERROR = 'crewMailLogs/GET_CREW_MAIL_LOGS_BY_CREW_ID_ERROR';

export const getCrewMailLogsByCrewId = createAction(GET_CREW_MAIL_LOGS_BY_CREW_ID, ({ gameId, crewId }) => ({
    gameId,
    crewId,
}));

export const GET_CREW_MAIL_LOGS = 'crewMailLogs/GET_CREW_MAIL_LOGS';
export const GET_CREW_MAIL_LOGS_SUCCESS = 'crewMailLogs/GET_CREW_MAIL_LOGS_SUCCESS';
export const GET_CREW_MAIL_LOGS_ERROR = 'crewMailLogs/GET_CREW_MAIL_LOGS_ERROR';

export const getCrewMailLogs = createAction(GET_CREW_MAIL_LOGS, ({ page, limit }) => ({ page, limit }));

export const SEARCH_CREW_MAIL_LOGS = 'crewMailLogs/SEARCH_CREW_MAIL_LOGS';
export const SEARCH_CREW_MAIL_LOGS_SUCCESS = 'crewMailLogs/SEARCH_CREW_MAIL_LOGS_SUCCESS';
export const SEARCH_CREW_MAIL_LOGS_ERROR = 'crewMailLogs/SEARCH_CREW_MAIL_LOGS_ERROR';

export const searchCrewMailLogs = createAction(SEARCH_CREW_MAIL_LOGS, ({ page, limit, param }) => ({
    page,
    limit,
    param,
}));

export const ON_CHANGE = 'crewMailLogs/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'crewMailLogs/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
