import { takeEvery, put, call, take } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import {
    DELETE,
    EDIT,
    GET_NOTICES,
    PUBLISH,
    REGIST,
    REPUBLISH,
    SEARCH_NOTICES,
    GET_PUBLISHED_NOTICES,
    ORDER_PUBLISHED_NOTICES,
    UNPUBLISH,
} from 'src/store/modules/notice';
import { ALL_CLOSE_MODAL, CLOSE_MODAL, CONFIRM, OPEN_MODAL } from '../modal';

const getNoticesSaga = createPromiseSagaWithHeader(GET_NOTICES, api.getNotices);
const searchNoticesSaga = createPromiseSagaWithHeader(SEARCH_NOTICES, api.searchNotices);
const getPublishedNotices = createPromiseSaga(GET_PUBLISHED_NOTICES, api.getPublishedNotices);

function noticePostSaga(type, promiseCreator, isPublish = false) {
    const [SUCCESS, ERROR] = [`${type}_SUCCESS`, `${type}_ERROR`];

    return function* saga(action) {
        const { payload } = yield take(CONFIRM);
        if (payload) {
            try {
                yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
                const { data } = yield call(promiseCreator, action.payload);

                if (!action.payload.hasNextStap) {
                    yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
                    yield put({ type: OPEN_MODAL, payload: 'successAlert' });
                    yield take(CLOSE_MODAL);
                    yield put({ type: SUCCESS, payload: data });
                    yield put({ type: ALL_CLOSE_MODAL });
                    if (isPublish) {
                        window.location.reload();
                    } else {
                        const pathArr = window.location.hash.split('/');
                        pathArr.pop();
                        window.location.href = `/${pathArr.join('/')}`;
                    }
                }
            } catch (e) {
                yield put({ type: ERROR, error: true, payload: e });
                yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
                yield put({ type: OPEN_MODAL, payload: 'failAlert' });
            }
        }
    };
}
function* republishSaga(action) {
    try {
        yield call(api.unpublishNotice, action.payload);
        yield call(api.publishNotice, action.payload);
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });
        yield take(CLOSE_MODAL);
        yield put({ type: `${PUBLISH}_SUCCESS` });
        yield put({ type: ALL_CLOSE_MODAL });
        const pathArr = window.location.hash.split('/');
        const last = pathArr.pop();
        if (last === 'write') {
            window.location.href = `/${pathArr.join('/')}`;
        } else {
            window.location.reload();
        }
    } catch (e) {
        yield put({ type: `${PUBLISH}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* orderPublishedNoticesSaga(action) {
    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.orderPublishedNotices, action.payload);

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${ORDER_PUBLISHED_NOTICES}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });
        yield take(CLOSE_MODAL);

        window.location.reload();
    } catch (e) {
        yield put({ type: `${ORDER_PUBLISHED_NOTICES}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

const postNoticeSaga = noticePostSaga(REGIST, api.postNotice);
const editNoticeSaga = noticePostSaga(EDIT, api.editNotice);

const publishNoticeSaga = noticePostSaga(PUBLISH, api.publishNotice, true);
const unpublishNoticeSaga = noticePostSaga(UNPUBLISH, api.unpublishNotice, true);
const deleteNoticeSaga = noticePostSaga(DELETE, api.deleteNotice);

export default function* noticeSaga() {
    yield takeEvery(GET_NOTICES, getNoticesSaga);
    yield takeEvery(SEARCH_NOTICES, searchNoticesSaga);
    yield takeEvery(GET_PUBLISHED_NOTICES, getPublishedNotices);
    yield takeEvery(REGIST, postNoticeSaga);
    yield takeEvery(EDIT, editNoticeSaga);
    yield takeEvery(PUBLISH, publishNoticeSaga);
    yield takeEvery(UNPUBLISH, unpublishNoticeSaga);
    yield takeEvery(REPUBLISH, republishSaga);
    yield takeEvery(ORDER_PUBLISHED_NOTICES, orderPublishedNoticesSaga);
    yield takeEvery(DELETE, deleteNoticeSaga);
}
