import { createAction } from 'redux-actions';

export const PERIOD_BAN = 'periodBan/PERIOD_BAN';
export const PERIOD_BAN_SUCCESS = 'periodBan/PERIOD_BAN_SUCCESS';
export const PERIOD_BAN_ERROR = 'periodBan/PERIOD_BAN_ERROR';

export const periodBan = createAction(PERIOD_BAN, (payload) => payload);

export const PERIOD_UNBAN = 'periodBan/PERIOD_UNBAN';
export const PERIOD_UNBAN_SUCCESS = 'periodBan/PERIOD_UNBAN_SUCCESS';
export const PERIOD_UNBAN_ERROR = 'periodBan/PERIOD_UNBAN_ERROR';

export const periodUnban = createAction(PERIOD_UNBAN, (payload) => payload);

export const GET_PERIOD_BAN_STATE = 'periodBan/GET_PERIOD_BAN_STATE';
export const GET_PERIOD_BAN_STATE_SUCCESS = 'periodBan/GET_PERIOD_BAN_STATE_SUCCESS';
export const GET_PERIOD_BAN_STATE_ERROR = 'periodBan/GET_PERIOD_BAN_STATE_ERROR';

export const getPeriodBanState = createAction(GET_PERIOD_BAN_STATE, (playerId) => playerId);

export const SET_BAN_TARGET = 'periodBan/SET_BAN_TARGET';
export const setBanTarget = createAction(SET_BAN_TARGET);

export const ON_CHANGE = 'periodBan/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'periodBan/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
