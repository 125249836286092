import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { check as checkRequest } from 'src/store/modules/auth';

export default function useLoginCheck() {
    const isLogin = useSelector((state) => state.auth.isLogin);
    const dispatch = useDispatch();
    const check = useCallback(() => dispatch(checkRequest()), [dispatch]);

    return {
        isLogin,
        check,
    };
}
