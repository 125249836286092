import { createAction } from 'redux-actions';

export const GET_PACKAGES = 'package/GET_PACKAGES';
export const GET_PACKAGES_SUCCESS = 'package/GET_PACKAGES_SUCCESS';
export const GET_PACKAGES_ERROR = 'package/GET_PACKAGES_ERROR';

export const getPackages = createAction(GET_PACKAGES, (gameId) => gameId);

export const GET_COUNT_BY_PACKAGE_TYPE = 'package/GET_COUNT_BY_PACKAGE_TYPE';
export const GET_COUNT_BY_PACKAGE_TYPE_SUCCESS = 'package/GET_COUNT_BY_PACKAGE_TYPE_SUCCESS';
export const GET_COUNT_BY_PACKAGE_TYPE_ERROR = 'package/GET_COUNT_BY_PACKAGE_TYPE_ERROR';

export const getCountByPackageType = createAction(GET_COUNT_BY_PACKAGE_TYPE);

export const GET_PACKAGES_FOR_SELECT = 'package/GET_PACKAGES_FOR_SELECT';
export const GET_PACKAGES_FOR_SELECT_SUCCESS = 'package/GET_PACKAGES_FOR_SELECT_SUCCESS';
export const GET_PACKAGES_FOR_SELECT_ERROR = 'package/GET_PACKAGES_FOR_SELECT_ERROR';

export const getPackagesForSelect = createAction(GET_PACKAGES_FOR_SELECT, (gameId) => gameId);

export const CREATE_PACKAGE = 'package/CREATE_PACKAGE';
export const CREATE_PACKAGE_SUCCESS = 'package/CREATE_PACKAGE_SUCCESS';
export const CREATE_PACKAGE_ERROR = 'package/CREATE_PACKAGE_ERROR';

export const createPackage = createAction(CREATE_PACKAGE, ({ gameId, param }) => ({ gameId, param }));

export const EDIT_PACKAGE = 'package/EDIT_PACKAGE';
export const EDIT_PACKAGE_SUCCESS = 'package/EDIT_PACKAGE_SUCCESS';
export const EDIT_PACKAGE_ERROR = 'package/EDIT_PACKAGE_ERROR';

export const editPackage = createAction(EDIT_PACKAGE, ({ oid, gameId, param }) => ({ oid, gameId, param }));

export const FIND_PACKAGE = 'package/FIND_PACKAGE';
export const findPackage = createAction(FIND_PACKAGE, (id) => id);

export const ADD_ITEM = 'package/ADD_ITEM';
export const addItem = createAction(ADD_ITEM, ({ target, name, value }) => ({ target, name, value }));

export const DELETE_ITEM = 'package/DELETE_ITEM';
export const deleteItem = createAction(DELETE_ITEM, ({ target, name, index }) => ({ target, name, index }));

export const EDIT_ITEM = 'package/EDIT_ITEM';
export const editItem = createAction(EDIT_ITEM, ({ target, name, index, key, value, refName, refValue }) => ({
    target,
    name,
    index,
    key,
    value,
    refName,
    refValue,
}));

export const ON_CHANGE = 'package/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'package/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
