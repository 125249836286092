import { createAction } from 'redux-actions';

export const GET_RECEIPT_BY_OID = 'receipt/GET_RECEIPT_BY_OID';
export const GET_RECEIPT_BY_OID_SUCCESS = 'receipt/GET_RECEIPT_BY_OID_SUCCESS';
export const GET_RECEIPT_BY_OID_ERROR = 'receipt/GET_RECEIPT_BY_OID_ERROR';

export const getReceiptByOid = createAction(GET_RECEIPT_BY_OID, ({ gameId, id }) => ({ gameId, id }));

export const GET_RECEIPTS = 'receipt/GET_RECEIPTS';
export const GET_RECEIPTS_SUCCESS = 'receipt/GET_RECEIPTS_SUCCESS';
export const GET_RECEIPTS_ERROR = 'receipt/GET_RECEIPTS_ERROR';

export const getReceipts = createAction(GET_RECEIPTS, ({ gameId, page }) => ({ gameId, page }));

export const SEARCH_RECEIPTS = 'receipt/SEARCH_RECEIPTS';
export const SEARCH_RECEIPTS_SUCCESS = 'receipt/SEARCH_RECEIPTS_SUCCESS';
export const SEARCH_RECEIPTS_ERROR = 'receipt/SEARCH_RECEIPTS_ERROR';

export const searchReceipts = createAction(SEARCH_RECEIPTS, ({ gameId, page, limit, param }) => ({
    gameId,
    page,
    limit,
    param,
}));

export const GET_MY_LATEST_RECEIPTS = 'receipt/GET_MY_LATEST_RECEIPTS';
export const GET_MY_LATEST_RECEIPTS_SUCCESS = 'receipt/GET_MY_LATEST_RECEIPTS_SUCCESS';
export const GET_MY_LATEST_RECEIPTS_ERROR = 'receipt/GET_MY_LATEST_RECEIPTS_ERROR';

export const getMyLatestReceipts = createAction(GET_MY_LATEST_RECEIPTS, ({ gameId, param }) => ({ gameId, param }));

export const ON_CHANGE = 'receipt/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'receipt/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
