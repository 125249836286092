import { createAction } from 'redux-actions';

export const GET_VOIDED_PURCHASES = 'voidedPurchase/GET_VOIDED_PURCHASES';
export const GET_VOIDED_PURCHASES_SUCCESS = 'voidedPurchase/GET_VOIDED_PURCHASES_SUCCESS';
export const GET_VOIDED_PURCHASES_ERROR = 'voidedPurchase/GET_VOIDED_PURCHASES_ERROR';

export const getVoidedPurchases = createAction(GET_VOIDED_PURCHASES, ({ gameId, page, limit }) => ({
    gameId,
    page,
    limit,
}));

export const SEARCH_VOIDED_PURCHASES = 'voidedPurchases/SEARCH_VOIDED_PURCHASES';
export const SEARCH_VOIDED_PURCHASES_SUCCESS = 'voidedPurchases/SEARCH_VOIDED_PURCHASES_SUCCESS';
export const SEARCH_VOIDED_PURCHASES_ERROR = 'voidedPurchases/SEARCH_VOIDED_PURCHASES_ERROR';

export const searchVoidedPurchases = createAction(SEARCH_VOIDED_PURCHASES, ({ gameId, page, limit, param }) => ({
    gameId,
    page,
    limit,
    param,
}));

export const DOWNLOAD_VOIDED_PURCHASES = 'voidedPurchases/DOWNLOAD_VOIDED_PURCHASES';
export const DOWNLOAD_VOIDED_PURCHASES_SUCCESS = 'voidedPurchases/DOWNLOAD_VOIDED_PURCHASES_SUCCESS';
export const DOWNLOAD_VOIDED_PURCHASES_ERRROR = 'voidedPurchases/DOWNLOAD_VOIDED_PURCHASES_ERROR';

export const downloadVoidedPurchases = createAction(DOWNLOAD_VOIDED_PURCHASES, ({ gameId, startDt, endDt }) => ({
    gameId,
    startDt,
    endDt,
}));

export const ON_CHANGE = 'voidedPurchase/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'voidedPurchase/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
