import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { register as registerRequest } from 'src/store/modules/auth';

export default function useRegister() {
    const { loading, data, error } = useSelector((state) => state.auth.result);
    const dispatch = useDispatch();
    const register = useCallback((param) => dispatch(registerRequest(param)), [dispatch]);

    return {
        loading,
        data,
        error,
        register,
    };
}
