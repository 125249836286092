import { combineActions, handleActions } from 'redux-actions';
import { handleAsyncActions, handleAsyncActionsWithHeader, reducerUtils } from 'src/lib/asyncUtils';
import {
    ADD_PERIOD_BAN_PLAYERS,
    ADD_PERIOD_BAN_PLAYERS_SUCCESS,
    ADD_PERIOD_BAN_PLAYERS_ERROR,
    GET_PERIOD_BAN_PLAYERS,
    GET_PERIOD_BAN_PLAYERS_SUCCESS,
    GET_PERIOD_BAN_PLAYERS_ERROR,
    SEARCH_PERIOD_BAN_PLAYERS,
    SEARCH_PERIOD_BAN_PLAYERS_SUCCESS,
    SEARCH_PERIOD_BAN_PLAYERS_ERROR,
    DELETE_PERIOD_BAN_PLAYER,
    DELETE_PERIOD_BAN_PLAYER_SUCCESS,
    DELETE_PERIOD_BAN_PLAYER_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';

const initialState = {
    result: reducerUtils.initial(false),
    banPlayers: reducerUtils.initial([]),
    isSearch: false,
    searchForm: {},
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(ADD_PERIOD_BAN_PLAYERS, ADD_PERIOD_BAN_PLAYERS_SUCCESS, ADD_PERIOD_BAN_PLAYERS_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(ADD_PERIOD_BAN_PLAYERS, 'result', true)(state, action),
        [combineActions(GET_PERIOD_BAN_PLAYERS, GET_PERIOD_BAN_PLAYERS_SUCCESS, GET_PERIOD_BAN_PLAYERS_ERROR)]: (
            state,
            action,
        ) => handleAsyncActionsWithHeader(GET_PERIOD_BAN_PLAYERS, 'banPlayers', true)(state, action),
        [combineActions(SEARCH_PERIOD_BAN_PLAYERS, SEARCH_PERIOD_BAN_PLAYERS_SUCCESS, SEARCH_PERIOD_BAN_PLAYERS_ERROR)]:
            (state, action) =>
                handleAsyncActionsWithHeader(SEARCH_PERIOD_BAN_PLAYERS, 'banPlayers', true)(state, action),
        [combineActions(DELETE_PERIOD_BAN_PLAYER, DELETE_PERIOD_BAN_PLAYER_SUCCESS, DELETE_PERIOD_BAN_PLAYER_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(DELETE_PERIOD_BAN_PLAYER, 'result', true)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
