import { takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import { GET_REPORT_SCHEDULES, SEARCH_REPORT_SCHEDULES } from './action';

const getReportSchedules = createPromiseSagaWithHeader(GET_REPORT_SCHEDULES, api.getReportSchedules);
const searchReportSchedules = createPromiseSagaWithHeader(SEARCH_REPORT_SCHEDULES, api.searchReportSchedules);

export default function* reportScheduleSaga() {
    yield takeEvery(GET_REPORT_SCHEDULES, getReportSchedules);
    yield takeEvery(SEARCH_REPORT_SCHEDULES, searchReportSchedules);
}
