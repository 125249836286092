import { takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga } from 'src/lib/asyncUtils';
import { GET_CURRENT_LOCALE_MAP } from 'src/store/modules/lang';

const getCurrentLocaleMapSaga = createPromiseSaga(GET_CURRENT_LOCALE_MAP, api.getCurrentLocaleMap);

export default function* langSaga() {
    yield takeEvery(GET_CURRENT_LOCALE_MAP, getCurrentLocaleMapSaga);
}
