import { createAction } from 'redux-actions';

export const GET_REPORT_RESULTS = 'reportResults/GET_REPORT_RESULTS';
export const GET_REPORT_RESULTS_SUCCESS = 'reportResults/GET_REPORT_RESULTS_SUCCESS';
export const GET_REPORT_RESULTS_ERROR = 'reportResults/GET_REPORT_RESULTS_ERROR';

export const getReportResults = createAction(GET_REPORT_RESULTS, ({ type, page, limit }) => ({ type, page, limit }));

export const ON_CHANGE = 'reportResults/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'reportResults/INITIALIZE';
export const initialize = createAction(INITIALIZE);
