import { createAction } from 'redux-actions';

export const GET_PERIOD_BAN_LOGS = 'periodBanLogs/GET_PERIOD_BAN_LOGS';
export const GET_PERIOD_BAN_LOGS_SUCCESS = 'periodBanLogs/GET_PERIOD_BAN_LOGS_SUCCESS';
export const GET_PERIOD_BAN_LOGS_ERROR = 'periodBanLogs/GET_PERIOD_BAN_LOGS_ERROR';

export const getPeriodBanLogs = createAction(GET_PERIOD_BAN_LOGS, ({ page, limit }) => ({ page, limit }));

export const SEARCH_PERIOD_BAN_LOGS = 'periodBanLogs/SEARCH_PERIOD_BAN_LOGS';
export const SEARCH_PERIOD_BAN_LOGS_SUCCESS = 'periodBanLogs/SEARCH_PERIOD_BAN_LOGS_SUCCESS';
export const SEARCH_PERIOD_BAN_LOGS_ERROR = 'periodBanLogs/SEARCH_PERIOD_BAN_LOGS_ERROR';

export const searchPeriodBanLogs = createAction(SEARCH_PERIOD_BAN_LOGS, ({ page, limit, param }) => ({
    page,
    limit,
    param,
}));

export const ON_CHANGE = 'periodBanLogs/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'periodBanLogs/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
