import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { login as loginRequest } from 'src/store/modules/auth';

export default function useLogin() {
    const { loading, data, error } = useSelector((state) => state.auth.userInfo);
    const dispatch = useDispatch();
    const login = useCallback((userId, password) => dispatch(loginRequest({ userId, password })), [dispatch]);

    return {
        loading,
        data,
        error,
        login,
    };
}
