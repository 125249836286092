import { combineActions, handleActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions, handleAsyncActionsWithHeader } from 'src/lib/asyncUtils';
import {
    GET_CHEAT_PLAYERS,
    GET_CHEAT_PLAYERS_SUCCESS,
    GET_CHEAT_PLAYERS_ERROR,
    SEARCH_CHEAT_PLAYERS,
    SEARCH_CHEAT_PLAYERS_SUCCESS,
    SEARCH_CHEAT_PLAYERS_ERROR,
    ADD_CHEAT_PLAYERS,
    ADD_CHEAT_PLAYERS_SUCCESS,
    ADD_CHEAT_PLAYERS_ERROR,
    DELETE_CHEAT_PLAYER,
    DELETE_CHEAT_PLAYER_SUCCESS,
    DELETE_CHEAT_PLAYER_ERROR,
    GET_PLAYER_CHEAT_STATUS,
    GET_PLAYER_CHEAT_STATUS_SUCCESS,
    GET_PLAYER_CHEAT_STATUS_ERROR,
    PLAYER_BAN,
    PLAYER_BAN_SUCCESS,
    PLAYER_BAN_ERROR,
    PLAYER_UNBAN,
    PLAYER_UNBAN_SUCCESS,
    PLAYER_UNBAN_ERROR,
    DEVICE_BAN,
    DEVICE_BAN_SUCCESS,
    DEVICE_BAN_ERROR,
    DEVICE_UNBAN,
    DEVICE_UNBAN_SUCCESS,
    DEVICE_UNBAN_ERROR,
    INQUIRY_BAN,
    INQUIRY_BAN_SUCCESS,
    INQUIRY_BAN_ERROR,
    INQUIRY_UNBAN,
    INQUIRY_UNBAN_SUCCESS,
    INQUIRY_UNBAN_ERROR,
    BAN_WITH_INQUIRY_BAN,
    BAN_WITH_INQUIRY_BAN_SUCCESS,
    BAN_WITH_INQUIRY_BAN_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';

const initialState = {
    cheatPlayers: reducerUtils.initial([]),
    isSearch: false,
    searchForm: {},
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
    cheatStatus: reducerUtils.initial({}),
    result: reducerUtils.initial(false),
    target: null,
};

export default handleActions(
    {
        [combineActions(GET_CHEAT_PLAYERS, GET_CHEAT_PLAYERS_SUCCESS, GET_CHEAT_PLAYERS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(GET_CHEAT_PLAYERS, 'cheatPlayers', true)(state, action),
        [combineActions(SEARCH_CHEAT_PLAYERS, SEARCH_CHEAT_PLAYERS_SUCCESS, SEARCH_CHEAT_PLAYERS_ERROR)]: (
            state,
            action,
        ) => handleAsyncActionsWithHeader(SEARCH_CHEAT_PLAYERS, 'cheatPlayers', true)(state, action),
        [combineActions(ADD_CHEAT_PLAYERS, ADD_CHEAT_PLAYERS_SUCCESS, ADD_CHEAT_PLAYERS_ERROR)]: (state, action) =>
            handleAsyncActions(ADD_CHEAT_PLAYERS, 'result', true)(state, action),
        [combineActions(DELETE_CHEAT_PLAYER, DELETE_CHEAT_PLAYER_SUCCESS, DELETE_CHEAT_PLAYER_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(DELETE_CHEAT_PLAYER, 'result', true)(state, action),
        [combineActions(GET_PLAYER_CHEAT_STATUS, GET_PLAYER_CHEAT_STATUS_SUCCESS, GET_PLAYER_CHEAT_STATUS_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_PLAYER_CHEAT_STATUS, 'cheatStatus', false)(state, action),
        [combineActions(PLAYER_BAN, PLAYER_BAN_SUCCESS, PLAYER_BAN_ERROR)]: (state, action) =>
            handleAsyncActions(PLAYER_BAN, 'result', false)(state, action),
        [combineActions(PLAYER_UNBAN, PLAYER_UNBAN_SUCCESS, PLAYER_UNBAN_ERROR)]: (state, action) =>
            handleAsyncActions(PLAYER_UNBAN, 'result', false)(state, action),
        [combineActions(DEVICE_BAN, DEVICE_BAN_SUCCESS, DEVICE_BAN_ERROR)]: (state, action) =>
            handleAsyncActions(DEVICE_BAN, 'result', false)(state, action),
        [combineActions(DEVICE_UNBAN, DEVICE_UNBAN_SUCCESS, DEVICE_UNBAN_ERROR)]: (state, action) =>
            handleAsyncActions(DEVICE_UNBAN, 'result', false)(state, action),
        [combineActions(INQUIRY_BAN, INQUIRY_BAN_SUCCESS, INQUIRY_BAN_ERROR)]: (state, action) =>
            handleAsyncActions(INQUIRY_BAN, 'result', false)(state, action),
        [combineActions(INQUIRY_UNBAN, INQUIRY_UNBAN_SUCCESS, INQUIRY_UNBAN_ERROR)]: (state, action) =>
            handleAsyncActions(INQUIRY_UNBAN, 'result', false)(state, action),
        [combineActions(BAN_WITH_INQUIRY_BAN, BAN_WITH_INQUIRY_BAN_SUCCESS, BAN_WITH_INQUIRY_BAN_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(BAN_WITH_INQUIRY_BAN, 'result', false)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
