import { createAction } from 'redux-actions';

export const GET_CHEAT_PLAYERS = 'cheatPlayer/GET_CHEAT_PLAYERS';
export const GET_CHEAT_PLAYERS_SUCCESS = 'cheatPlayer/GET_CHEAT_PLAYERS_SUCCESS';
export const GET_CHEAT_PLAYERS_ERROR = 'cheatPlayer/GET_CHEAT_PLAYERS_ERROR';

export const getCheatPlayers = createAction(GET_CHEAT_PLAYERS, ({ page, limit }) => ({ page, limit }));

export const SEARCH_CHEAT_PLAYERS = 'cheatPlayer/SEARCH_CHEAT_PLAYERS';
export const SEARCH_CHEAT_PLAYERS_SUCCESS = 'cheatPlayer/SEARCH_CHEAT_PLAYERS_SUCCESS';
export const SEARCH_CHEAT_PLAYERS_ERROR = 'cheatPlayer/SEARCH_CHEAT_PLAYERS_ERROR';

export const searchCheatPlayers = createAction(SEARCH_CHEAT_PLAYERS, ({ page, limit, param }) => ({
    page,
    limit,
    param,
}));

export const ADD_CHEAT_PLAYERS = 'cheatPlayer/ADD_CHEAT_PLAYERS';
export const ADD_CHEAT_PLAYERS_SUCCESS = 'cheatPlayer/ADD_CHEAT_PLAYERS_SUCCESS';
export const ADD_CHEAT_PLAYERS_ERROR = 'cheatPlayer/ADD_CHEAT_PLAYERS_ERROR';

export const addCheatPlayers = createAction(ADD_CHEAT_PLAYERS, (payload) => payload);

export const DELETE_CHEAT_PLAYER = 'cheatPlayer/DELETE_CHEAT_PLAYER';
export const DELETE_CHEAT_PLAYER_SUCCESS = 'cheatPlayer/DELETE_CHEAT_PLAYER_SUCCESS';
export const DELETE_CHEAT_PLAYER_ERROR = 'cheatPlayer/DELETE_CHEAT_PLAYER_ERROR';

export const deleteCheatPlayer = createAction(DELETE_CHEAT_PLAYER, (oid) => oid);

export const GET_PLAYER_CHEAT_STATUS = 'cheatPlayer/GET_PLAYER_CHEAT_STATUS';
export const GET_PLAYER_CHEAT_STATUS_SUCCESS = 'cheatPlayer/GET_PLAYER_CHEAT_STATUS_SUCCESS';
export const GET_PLAYER_CHEAT_STATUS_ERROR = 'cheatPlayer/GET_PLAYER_CHEAT_STATUS_ERROR';

export const getPlayerCheatStatus = createAction(GET_PLAYER_CHEAT_STATUS, ({ gameId, id }) => ({ gameId, id }));

export const PLAYER_BAN = 'cheatPlayer/PLAYER_BAN';
export const PLAYER_BAN_SUCCESS = 'cheatPlayer/PLAYER_BAN_SUCCESS';
export const PLAYER_BAN_ERROR = 'cheatPlayer/PLAYER_BAN_ERROR';

export const playerBan = createAction(PLAYER_BAN, ({ gameId, param }) => ({ gameId, param }));

export const PLAYER_UNBAN = 'cheatPlayer/PLAYER_UNBAN';
export const PLAYER_UNBAN_SUCCESS = 'cheatPlayer/PLAYER_UNBAN_SUCCESS';
export const PLAYER_UNBAN_ERROR = 'cheatPlayer/PLAYER_UNBAN_ERROR';

export const playerUnban = createAction(PLAYER_UNBAN, ({ gameId, param }) => ({ gameId, param }));

export const DEVICE_BAN = 'cheatPlayer/DEVICE_BAN';
export const DEVICE_BAN_SUCCESS = 'cheatPlayer/DEVICE_BAN_SUCCESS';
export const DEVICE_BAN_ERROR = 'cheatPlayer/DEVICE_BAN_ERROR';

export const deviceBan = createAction(DEVICE_BAN, ({ gameId, param }) => ({ gameId, param }));

export const DEVICE_UNBAN = 'cheatPlayer/DEVICE_UNBAN';
export const DEVICE_UNBAN_SUCCESS = 'cheatPlayer/DEVICE_UNBAN_SUCCESS';
export const DEVICE_UNBAN_ERROR = 'cheatPlayer/DEVICE_UNBAN_ERROR';

export const deviceUnban = createAction(DEVICE_UNBAN, ({ gameId, param }) => ({ gameId, param }));

export const INQUIRY_BAN = 'cheatPlayer/INQUIRY_BAN';
export const INQUIRY_BAN_SUCCESS = 'cheatPlayer/INQUIRY_BAN_SUCCESS';
export const INQUIRY_BAN_ERROR = 'cheatPlayer/INQUIRY_BAN_ERROR';

export const inquiryBan = createAction(INQUIRY_BAN, ({ gameId, param }) => ({ gameId, param }));

export const INQUIRY_UNBAN = 'cheatPlayer/INQUIRY_UNBAN';
export const INQUIRY_UNBAN_SUCCESS = 'cheatPlayer/INQUIRY_UNBAN_SUCCESS';
export const INQUIRY_UNBAN_ERROR = 'cheatPlayer/INQUIRY_UNBAN_ERROR';

export const inquiryUnban = createAction(INQUIRY_UNBAN, ({ gameId, param }) => ({ gameId, param }));

export const BAN_WITH_INQUIRY_BAN = 'cheatPlayer/BAN_WITH_INQUIRY_BAN';
export const BAN_WITH_INQUIRY_BAN_SUCCESS = 'cheatPlayer/BAN_WITH_INQUIRY_BAN_SUCCESS';
export const BAN_WITH_INQUIRY_BAN_ERROR = 'cheatPlayer/BAN_WITH_INQUIRY_BAN_ERROR';

export const banWithInquiryBan = createAction(BAN_WITH_INQUIRY_BAN, ({ gameId, param }) => ({ gameId, param }));

export const SET_BAN_TARGET = 'player/SET_BAN_TARGET';
export const setBanTarget = createAction(SET_BAN_TARGET);

export const ON_CHANGE = 'cheatPlayer/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'cheatPlayer/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
