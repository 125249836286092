import { takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga } from 'src/lib/asyncUtils';
import { GET_MY_NOTIFICATIONS, UPDATE_TO_READ } from './action';

const getMyNotificationsSaga = createPromiseSaga(GET_MY_NOTIFICATIONS, api.getMyNotifications);
const updateToReadSaga = createPromiseSaga(UPDATE_TO_READ, api.updateNotificationToRead);

export default function* notificationSaga() {
    yield takeEvery(GET_MY_NOTIFICATIONS, getMyNotificationsSaga);
    yield takeEvery(UPDATE_TO_READ, updateToReadSaga);
}
