import { createAction } from 'redux-actions';

export const GET_SOURCE_PLAYER = 'recover/GET_SOURCE_PLAYER';
export const GET_SOURCE_PLAYER_SUCCESS = 'recover/GET_SOURCE_PLAYER_SUCCESS';
export const GET_SOURCE_PLAYER_ERROR = 'recover/GET_SOURCE_PLAYER_ERROR';

export const getSourcePlayer = createAction(GET_SOURCE_PLAYER, ({ gameId, id }) => ({
    gameId,
    id,
}));

export const GET_TARGET_PLAYER = 'recover/GET_TARGET_PLAYER';
export const GET_TARGET_PLAYER_SUCCESS = 'recover/GET_TARGET_PLAYER_SUCCESS';
export const GET_TARGET_PLAYER_ERROR = 'recover/GET_TARGET_PLAYER_ERROR';

export const getTargetPlayer = createAction(GET_TARGET_PLAYER, ({ gameId, id }) => ({
    gameId,
    id,
}));

export const PLAYER_RECOVER = 'recover/PLAYER_RECOVER';
export const PLAYER_RECOVER_SUCCESS = 'recover/PLAYER_RECOVER_SUCCESS';
export const PLAYER_RECOVER_ERROR = 'recover/PLAYER_RECOVER_ERROR';

export const playerRecover = createAction(PLAYER_RECOVER, ({ gameId, param }) => ({ gameId, param }));

export const SET_RECOVER_TARGET = 'recover/SET_RECOVER_TARGET';
export const setRecoverTarget = createAction(SET_RECOVER_TARGET, (key) => key);

export const INITIALIZE = 'recover/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
