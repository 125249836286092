import { call, put, take, takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSagaWithHeader, createPromiseSaga } from 'src/lib/asyncUtils';
import { GET_BACKUP_FILTER_BY_OID, GET_BACKUP_FILTERS, ADD_BACKUP_FILTER, EDIT_BACKUP_FILTER } from './action';
import { OPEN_MODAL, CLOSE_MODAL } from 'src/store/modules/modal';

const getBackupFilterByOidSaga = createPromiseSaga(GET_BACKUP_FILTER_BY_OID, api.getBackupFilterByOid);
const getBackupFiltersSaga = createPromiseSagaWithHeader(GET_BACKUP_FILTERS, api.getBackupFilters);

function* addBackupFilterSaga(action) {
    const { gameId, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.addBackupFilter, { gameId, param });

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${ADD_BACKUP_FILTER}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);
        yield put({ type: CLOSE_MODAL, payload: 'addFilter' });

        yield put({ type: GET_BACKUP_FILTERS, payload: { page: 1, limit: 5 } });
    } catch (e) {
        yield put({ type: `${ADD_BACKUP_FILTER}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* editBackupFilterSaga(action) {
    const { oid, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.editBackupFilter, { oid, param });

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${EDIT_BACKUP_FILTER}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);
        yield put({ type: CLOSE_MODAL, payload: 'editFilter' });

        yield put({ type: GET_BACKUP_FILTERS, payload: { page: 1, limit: 5 } });
    } catch (e) {
        yield put({ type: `${EDIT_BACKUP_FILTER}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* backupFilterSaga() {
    yield takeEvery(GET_BACKUP_FILTER_BY_OID, getBackupFilterByOidSaga);
    yield takeEvery(GET_BACKUP_FILTERS, getBackupFiltersSaga);
    yield takeEvery(ADD_BACKUP_FILTER, addBackupFilterSaga);
    yield takeEvery(EDIT_BACKUP_FILTER, editBackupFilterSaga);
}
