import { createAction } from 'redux-actions';

export const OPEN_MODAL = 'modal/OPEN_MODAL';
export const openModal = createAction(OPEN_MODAL, (name) => name);

export const CLOSE_MODAL = 'modal/CLOSE_MODAL';
export const closeModal = createAction(CLOSE_MODAL, (name) => name);

export const ALL_CLOSE_MODAL = 'modal/ALL_CLOSE_MODAL';
export const allCloseModal = createAction(ALL_CLOSE_MODAL, () => ({}));

export const CONFIRM = 'modal/CONFIRM';
export const confirm = createAction(CONFIRM, (isConfirm) => isConfirm);

export const CONFIRM_WITH = 'modal/CONFIRM_WITH';
export const confirmWith = createAction(CONFIRM_WITH, ({ isConfirm, ...payload }) => ({ isConfirm, ...payload }));

export const CUSTOM_MESSAGE = 'modal/CUSTOM_MESSAGE';
export const customMessage = createAction(CUSTOM_MESSAGE, (message) => message);
