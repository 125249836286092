import { createAction } from 'redux-actions';

export const GET_PLAYER_BAN_LOGS = 'playerBanLog/GET_PLAYER_BAN_LOGS';
export const GET_PLAYER_BAN_LOGS_SUCCESS = 'playerBanLog/GET_PLAYER_BAN_LOGS_SUCCESS';
export const GET_PLAYER_BAN_LOGS_ERROR = 'playerBanLog/GET_PLAYER_BAN_LOGS_ERROR';

export const getPlayerBanLogs = createAction(GET_PLAYER_BAN_LOGS, ({ gameId, database, playerId }) => ({
    gameId,
    database,
    playerId,
}));

export const GET_BAN_LOGS = 'playerBanLog/GET_BAN_LOGS';
export const GET_BAN_LOGS_SUCCESS = 'playerBanLog/GET_BAN_LOGS_SUCCESS';
export const GET_BAN_LOGS_ERROR = 'playerBanLog/GET_BAN_LOGS_ERROR';

export const getBanLogs = createAction(GET_BAN_LOGS, ({ page, limit }) => ({ page, limit }));

export const SEARCH_BAN_LOGS = 'playerBanLog/SEARCH_BAN_LOGS';
export const SEARCH_BAN_LOGS_SUCCESS = 'playerBanLog/SEARCH_BAN_LOGS_SUCCESS';
export const SEARCH_BAN_LOGS_ERROR = 'playerBanLog/SEARCH_BAN_LOGS_ERROR';

export const searchBanLogs = createAction(SEARCH_BAN_LOGS, ({ page, limit, param }) => ({ page, limit, param }));

export const ON_CHANGE = 'playerBanLog/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'playerBanLog/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
