import { createAction } from 'redux-actions';

export const REGISTER = 'auth/REGISTER';
export const REGISTER_SUCCESS = 'auth/REGISTER_SUCCESS';
export const REGISTER_ERROR = 'auth/REGISTER_ERROR';

export const register = createAction(REGISTER, (param) => param);

export const LOGIN = 'auth/LOGIN';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'auth/LOGIN_ERROR';

export const login = createAction(LOGIN, ({ userId, password }) => ({ userId, password }));

export const CHECK = 'auth/CHECK';
export const CHECK_SUCCESS = 'auth/CHECK_SUCCESS';
export const CHECK_ERROR = 'auth/CHECK_ERROR';

export const check = createAction(CHECK);

export const LOGOUT = 'auth/LOGOUT';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'auth/LOGOUT_ERROR';

export const logout = createAction(LOGOUT);
