import { takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import {
    GET_PROVIDE_LOG_BY_OID,
    GET_PROVIDE_LOGS_BY_ORDER_ID,
    GET_PROVIDE_LOGS_TO,
    GET_PROVIDE_LOGS,
    SEARCH_PROVIDE_LOGS,
} from './action';

const getProvideLogByOidSaga = createPromiseSaga(GET_PROVIDE_LOG_BY_OID, api.getProvideLogByOid);
const getProvideLogsByOrderIdSaga = createPromiseSaga(GET_PROVIDE_LOGS_BY_ORDER_ID, api.getProvideLogsByOrderId);
const getProvideLogsToSaga = createPromiseSaga(GET_PROVIDE_LOGS_TO, api.getProvideLogsTo);
const getProvideLogsSaga = createPromiseSagaWithHeader(GET_PROVIDE_LOGS, api.getProvideLogs);
const searchProvideLogsSaga = createPromiseSagaWithHeader(SEARCH_PROVIDE_LOGS, api.searchProvideLogs);

export default function* provideLogSaga() {
    yield takeEvery(GET_PROVIDE_LOG_BY_OID, getProvideLogByOidSaga);
    yield takeEvery(GET_PROVIDE_LOGS_TO, getProvideLogsToSaga);
    yield takeEvery(GET_PROVIDE_LOGS_BY_ORDER_ID, getProvideLogsByOrderIdSaga);
    yield takeEvery(GET_PROVIDE_LOGS, getProvideLogsSaga);
    yield takeEvery(SEARCH_PROVIDE_LOGS, searchProvideLogsSaga);
}
