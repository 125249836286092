import { createAction } from 'redux-actions';

export const GET_BACKUP_CRAWLERS = 'backupCrawler/GET_BACKUP_CRAWLERS';
export const GET_BACKUP_CRAWLERS_SUCCESS = 'backupCrawler/GET_BACKUP_CRAWLERS_SUCCESS';
export const GET_BACKUP_CRAWLERS_ERROR = 'backupCrawler/GET_BACKUP_CRAWLERS_ERROR';

export const getBackupCrawlers = createAction(GET_BACKUP_CRAWLERS, ({ page, limit }) => ({ page, limit }));

export const SEARCH_BACKUP_CRAWLERS = 'backupCrawler/SEARCH_BACKUP_CRAWLERS';
export const SEARCH_BACKUP_CRAWLERS_SUCCESS = 'backupCrawler/SEARCH_BACKUP_CRAWLERS_SUCCESS';
export const SEARCH_BACKUP_CRAWLERS_ERROR = 'backupCrawler/SEARCH_BACKUP_CRAWLERS_ERROR';

export const searchBackupCrawlers = createAction(SEARCH_BACKUP_CRAWLERS, ({ page, limit, param }) => ({
    page,
    limit,
    param,
}));

export const CREATE_BACKUP_CRAWLERS = 'backupCrawler/CREATE_BACKUP_CRAWLERS';
export const CREATE_BACKUP_CRAWLERS_SUCCESS = 'backupCrawler/CREATE_BACKUP_CRAWLERS_SUCCESS';
export const CREATE_BACKUP_CRAWLERS_ERROR = 'backupCrawler/CREATE_BACKUP_CRAWLERS_ERROR';

export const createBackupCrawlers = createAction(CREATE_BACKUP_CRAWLERS, ({ gameId, ymd }) => ({ gameId, ymd }));

export const RESTORE_BY_BACKUP_CRAWLER = 'backupCrawler/RESTORE_BY_BACKUP_CRAWLER';
export const RESTORE_BY_BACKUP_CRAWLER_SUCCESS = 'backupCrawler/RESTORE_BY_BACKUP_CRAWLER_SUCCESS';
export const RESTORE_BY_BACKUP_CRAWLER_ERROR = 'backupCrawler/RESTORE_BACKUP_CRAWLER_ERROR';

export const restoreByBackupCrawler = createAction(RESTORE_BY_BACKUP_CRAWLER, (oid) => oid);

export const ON_CHANGE = 'backupCrawler/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);
