import { call, put, take, takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import { GET_LOG_FILTER_BY_OID, GET_LOG_FILTERS, EDIT_LOG_FILTER, GET_LOG_REPORTS, SEARCH_LOG_REPORTS } from './action';
import { OPEN_MODAL, CLOSE_MODAL } from 'src/store/modules/modal';

const getLogFilterByOidSaga = createPromiseSaga(GET_LOG_FILTER_BY_OID, api.getLogFilterByOid);
const getLogFiltersSaga = createPromiseSaga(GET_LOG_FILTERS, api.getLogFiltersForSelect);
const getLogReportsSaga = createPromiseSagaWithHeader(GET_LOG_REPORTS, api.getLogReports);
const searchLogReportsSaga = createPromiseSagaWithHeader(SEARCH_LOG_REPORTS, api.searchLogReports);

function* editLogFilterSaga(action) {
    const { oid, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.editLogFilter, { oid, param });

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${EDIT_LOG_FILTER}_SUCCESS`, payload: true });

        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: CLOSE_MODAL, payload: 'logReportsMetadata' });
    } catch (e) {
        yield put({ type: `${EDIT_LOG_FILTER}_ERROR`, payload: false });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* logReportsSaga() {
    yield takeEvery(GET_LOG_FILTER_BY_OID, getLogFilterByOidSaga);
    yield takeEvery(GET_LOG_FILTERS, getLogFiltersSaga);
    yield takeEvery(GET_LOG_REPORTS, getLogReportsSaga);
    yield takeEvery(SEARCH_LOG_REPORTS, searchLogReportsSaga);
    yield takeEvery(EDIT_LOG_FILTER, editLogFilterSaga);
}
