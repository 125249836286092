import { combineActions, handleActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions, handleAsyncActionsWithHeader } from 'src/lib/asyncUtils';
import {
    GET_LOCALE_MAP,
    GET_LOCALE_MAP_SUCCESS,
    GET_LOCALE_MAP_ERROR,
    GET_LOCALE_MAPS,
    GET_LOCALE_MAPS_SUCCESS,
    GET_LOCALE_MAPS_ERROR,
    SEARCH_LOCALE_MAPS,
    SEARCH_LOCALE_MAPS_SUCCESS,
    SEARCH_LOCALE_MAPS_ERROR,
    CREATE_LOCALE_MAP,
    CREATE_LOCALE_MAP_SUCCESS,
    CREATE_LOCALE_MAP_ERROR,
    EDIT_LOCALE_MAP,
    EDIT_LOCALE_MAP_SUCCESS,
    EDIT_LOCALE_MAP_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';

const initialState = {
    result: reducerUtils.initial(false),
    localeMap: reducerUtils.initial({}),
    localeMaps: reducerUtils.initial([]),
    searchForm: {},
    isSearch: false,
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(GET_LOCALE_MAP, GET_LOCALE_MAP_SUCCESS, GET_LOCALE_MAP_ERROR)]: (state, action) =>
            handleAsyncActions(GET_LOCALE_MAP, 'localeMap', false)(state, action),
        [combineActions(GET_LOCALE_MAPS, GET_LOCALE_MAPS_SUCCESS, GET_LOCALE_MAPS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(GET_LOCALE_MAPS, 'localeMaps', true)(state, action),
        [combineActions(SEARCH_LOCALE_MAPS, SEARCH_LOCALE_MAPS_SUCCESS, SEARCH_LOCALE_MAPS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(SEARCH_LOCALE_MAPS, 'localeMaps', true)(state, action),
        [combineActions(CREATE_LOCALE_MAP, CREATE_LOCALE_MAP_SUCCESS, CREATE_LOCALE_MAP_ERROR)]: (state, action) =>
            handleAsyncActions(GET_LOCALE_MAPS, 'localeMaps', true)(state, action),
        [combineActions(EDIT_LOCALE_MAP, EDIT_LOCALE_MAP_SUCCESS, EDIT_LOCALE_MAP_ERROR)]: (state, action) =>
            handleAsyncActions(EDIT_LOCALE_MAP, 'result', true)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
