import { fork } from 'redux-saga/effects';
import sidebarSaga from 'src/store/modules/sidebar/saga';
import playerSaga from 'src/store/modules/player/saga';
import receiptSaga from 'src/store/modules/receipt/saga';
import cmcodeSaga from 'src/store/modules/cmcode/saga';
import gameSaga from 'src/store/modules/game/saga';
import packageSaga from 'src/store/modules/package/saga';
import providelogSaga from 'src/store/modules/provideLog/saga';
import provideSaga from 'src/store/modules/provide/saga';
import authSaga from 'src/store/modules/auth/saga';
import recoverSaga from 'src/store/modules/recover/saga';
import recoverLogSaga from 'src/store/modules/recoverLog/saga';
import backupMetadataSaga from 'src/store/modules/backupMetadata/saga';
import backupFilterSaga from 'src/store/modules/backupFilter/saga';
import backupCrawlerSaga from 'src/store/modules/backupCrawler/saga';
import accountSaga from 'src/store/modules/account/saga';
import playerBanLogSaga from 'src/store/modules/playerBanLog/saga';
import cheatPlayerSaga from 'src/store/modules/cheatPlayer/saga';
import manualProvideLogSaga from 'src/store/modules/manualProvideLog/saga';
import fieldMapSaga from 'src/store/modules/fieldMap/saga';
import notificationSaga from 'src/store/modules/notification/saga';
import managementSaga from 'src/store/modules/management/saga';
import seasonSaga from 'src/store/modules/qaqc/season/saga';
import deviceBanLogSaga from 'src/store/modules/deviceBanLog/saga';
import crewSaga from 'src/store/modules/crew/saga';
import localeSaga from 'src/store/modules/lang/saga';
import localeMapSaga from 'src/store/modules/localeMap/saga';
import voidedPurchaseSaga from './modules/voidedPurchase/saga';
import leaderboardSaga from './modules/leaderboard/saga';
import noticeSaga from './modules/notice/saga';
import reportScheduleSaga from './modules/reportSchedules/saga';
import reportResultSaga from './modules/reportResults/saga';
import inquiryBanLogSaga from './modules/inquiryBanLog/saga';
import deleteLogSaga from './modules/deleteLog/saga';
import crewMailLogSaga from './modules/crewMailLog/saga';
import diceAccrueSaga from './modules/diceAccrue/saga';
import diceAccrueLogSaga from './modules/diceAccrueLog/saga';
import diceAccrueScheduleSaga from './modules/diceAccrueSchedule/saga';
import logReportsSaga from './modules/logReports/saga';
import dataPresetSaga from './modules/dataPreset/saga';
import dataPresetLogSaga from './modules/dataPresetLog/saga';
import periodBanSaga from './modules/periodBan/saga';
import periodBanLogSaga from './modules/periodBanLog/saga';
import periodBanPlayerSaga from './modules/periodBanPlayer/saga';

export default function* rootSaga() {
    yield fork(sidebarSaga);
    yield fork(playerSaga);
    yield fork(receiptSaga);
    yield fork(cmcodeSaga);
    yield fork(gameSaga);
    yield fork(packageSaga);
    yield fork(providelogSaga);
    yield fork(provideSaga);
    yield fork(authSaga);
    yield fork(recoverSaga);
    yield fork(recoverLogSaga);
    yield fork(backupMetadataSaga);
    yield fork(backupFilterSaga);
    yield fork(backupCrawlerSaga);
    yield fork(accountSaga);
    yield fork(playerBanLogSaga);
    yield fork(cheatPlayerSaga);
    yield fork(manualProvideLogSaga);
    yield fork(fieldMapSaga);
    yield fork(notificationSaga);
    yield fork(managementSaga);
    yield fork(seasonSaga);
    yield fork(deviceBanLogSaga);
    yield fork(crewSaga);
    yield fork(localeSaga);
    yield fork(localeMapSaga);
    yield fork(voidedPurchaseSaga);
    yield fork(leaderboardSaga);
    yield fork(noticeSaga);
    yield fork(reportScheduleSaga);
    yield fork(reportResultSaga);
    yield fork(inquiryBanLogSaga);
    yield fork(deleteLogSaga);
    yield fork(crewMailLogSaga);
    yield fork(diceAccrueSaga);
    yield fork(diceAccrueLogSaga);
    yield fork(diceAccrueScheduleSaga);
    yield fork(logReportsSaga);
    yield fork(dataPresetSaga);
    yield fork(dataPresetLogSaga);
    yield fork(periodBanSaga);
    yield fork(periodBanLogSaga);
    yield fork(periodBanPlayerSaga);
}
