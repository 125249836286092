import { put, call, take, takeEvery, takeLatest } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import {
    GET_PLAYER_BY_OID,
    GET_PLAYER_BY_PID,
    GET_PLAYER_BY_PID_FROM_FIREBASE,
    GET_PLAYER_LOGS,
    GET_PLAYER_BATTLE_LOGS,
    GET_PLAYERS,
    SEARCH_PLAYERS,
    SEARCH_PLAYERS_FROM_FIREBASE,
    GET_PLAYER_FROM_FIREBASE,
    COMPARE_PLAYER_DATA,
    GET_SNAPSHOT_LIST,
    GET_PLAYER_CREW,
    SET_TARGET,
    ON_CHANGE,
} from './action';
import { CLOSE_MODAL, CONFIRM_WITH, OPEN_MODAL, CONFIRM } from 'src/store/modules/modal';
import { UPDATE_PLAYER_BY_PRESET } from '.';

const getPlayerByOidSaga = createPromiseSaga(GET_PLAYER_BY_OID, api.getPlayerByOid);
const getPlayerByPidSaga = createPromiseSaga(GET_PLAYER_BY_PID, api.getPlayerByPid);
const getPlayersSaga = createPromiseSagaWithHeader(GET_PLAYERS, api.getPlayers);
const getPlayerFromFirebaseSaga = createPromiseSaga(GET_PLAYER_FROM_FIREBASE, api.getPlayerFromFirebase);
const getPlayerLogsSaga = createPromiseSaga(GET_PLAYER_LOGS, api.getPlayerLogs);
const getPlayerBattleLogsSaga = createPromiseSaga(GET_PLAYER_BATTLE_LOGS, api.getPlayerBattleLogs);
const searchPlayersSaga = createPromiseSagaWithHeader(SEARCH_PLAYERS, api.searchPlayers);
const searchPlayersFromFirebaseSaga = createPromiseSaga(SEARCH_PLAYERS_FROM_FIREBASE, api.searchPlayersFromFirebase);
const comparePlayerDataSaga = createPromiseSaga(COMPARE_PLAYER_DATA, api.comparePlayerData);

function* getSnapshotListSaga(action) {
    const { gameId, limit, param } = action.payload;

    try {
        const { data } = yield call(api.getSnapshotList, { gameId, limit, param });

        yield put({ type: ON_CHANGE, payload: { name: 'target', value: param.oid } });
        yield put({ type: `${GET_SNAPSHOT_LIST}_SUCCESS`, payload: data });
    } catch (e) {
        yield put({ type: `${GET_SNAPSHOT_LIST}_ERROR`, error: true, payload: e });
    }
}

function* getPlayerByPidFromFirebaseSaga(action) {
    const { gameId, id } = action.payload;

    try {
        if (!id) {
            yield put({ type: OPEN_MODAL, payload: 'failAlert' });
            yield put({ type: `${GET_PLAYER_BY_PID_FROM_FIREBASE}_ERROR`, error: true });
            return;
        }

        const { data } = yield call(api.getPlayerByPidFromFirebase, { gameId, id });

        yield put({ type: `${GET_PLAYER_BY_PID_FROM_FIREBASE}_SUCCESS`, payload: data });
    } catch (e) {
        yield put({ type: `${GET_PLAYER_BY_PID_FROM_FIREBASE}_ERROR`, error: true, payload: e });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* getPlayerCrewSaga(action) {
    const { gameId, playerId } = action.payload;

    try {
        if (!playerId) {
            yield put({ type: `${GET_PLAYER_CREW}_ERROR`, error: true });
        }

        const { data } = yield call(api.getPlayerCrew, { gameId, playerId });

        yield put({ type: `${GET_PLAYER_CREW}_SUCCESS`, payload: data });
    } catch (e) {
        yield put({ type: `${GET_PLAYER_CREW}_ERROR`, error: true, payload: e });
    }
}

function* setTargetSaga() {
    try {
        const { payload } = yield take(CONFIRM_WITH);
        const { isConfirm, ...param } = payload;
        const { gameId, database, playerId } = param;

        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'loadingAlert' });
            const { data: player } = yield call(api.getPlayerFromFirebaseWithIntl, {
                gameId,
                database,
                playerId,
            });

            yield put({ type: `${GET_PLAYER_FROM_FIREBASE}_SUCCESS`, payload: { ...player, playerId } });

            yield put({ type: ON_CHANGE, payload: { name: 'target', value: { database, playerId } } });
            yield put({ type: CLOSE_MODAL, payload: 'loadingAlert' });
        }

        yield put({ type: CLOSE_MODAL, payload: 'playerSearch' });
    } catch (e) {
        yield put({ type: CLOSE_MODAL, payload: 'playerSearch' });
        yield put({ type: CLOSE_MODAL, payload: 'loadingAlert' });
    }
}

function* updateByPresetSaga(action) {
    try {
        const { payload: isConfirm } = yield take(CONFIRM);

        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
            yield call(api.updatePlayerByPreset, action.payload);
            yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

            yield put({ type: `${UPDATE_PLAYER_BY_PRESET}_SUCCESS`, payload: true });
            yield put({ type: OPEN_MODAL, payload: 'successAlert' });

            yield take(CLOSE_MODAL);
            yield put({ type: CLOSE_MODAL, payload: 'updatePlayerByPresetConfirm' });
        }
    } catch (e) {
        yield put({ type: `${UPDATE_PLAYER_BY_PRESET}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* playerSaga() {
    yield takeEvery(GET_PLAYER_BY_OID, getPlayerByOidSaga);
    yield takeEvery(GET_PLAYER_BY_PID, getPlayerByPidSaga);
    yield takeEvery(GET_PLAYER_BY_PID_FROM_FIREBASE, getPlayerByPidFromFirebaseSaga);
    yield takeEvery(GET_PLAYERS, getPlayersSaga);
    yield takeEvery(GET_PLAYER_FROM_FIREBASE, getPlayerFromFirebaseSaga);
    yield takeLatest(GET_PLAYER_LOGS, getPlayerLogsSaga);
    yield takeLatest(GET_PLAYER_BATTLE_LOGS, getPlayerBattleLogsSaga);
    yield takeEvery(SEARCH_PLAYERS, searchPlayersSaga);
    yield takeEvery(SEARCH_PLAYERS_FROM_FIREBASE, searchPlayersFromFirebaseSaga);
    yield takeEvery(COMPARE_PLAYER_DATA, comparePlayerDataSaga);
    yield takeEvery(SET_TARGET, setTargetSaga);
    yield takeEvery(GET_SNAPSHOT_LIST, getSnapshotListSaga);
    yield takeEvery(GET_PLAYER_CREW, getPlayerCrewSaga);
    yield takeEvery(UPDATE_PLAYER_BY_PRESET, updateByPresetSaga);
}
