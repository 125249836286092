import { takeEvery, put, call, take } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import { OPEN_MODAL, CLOSE_MODAL } from 'src/store/modules/modal';
import {
    GET_VOIDED_PURCHASES,
    SEARCH_VOIDED_PURCHASES,
    DOWNLOAD_VOIDED_PURCHASES,
    DOWNLOAD_VOIDED_PURCHASES_SUCCESS,
    DOWNLOAD_VOIDED_PURCHASES_ERRROR,
} from './action';

const getVoidedPurchases = createPromiseSagaWithHeader(GET_VOIDED_PURCHASES, api.getVoidedPurchases);
const searchVoidedPurchases = createPromiseSagaWithHeader(SEARCH_VOIDED_PURCHASES, api.searchVoidedPurchases);

function* downloadVoidedPurchases(action) {
    const { gameId, startDt, endDt } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        const { data: result } = yield call(api.downloadVoidedPurchases, { gameId, startDt, endDt });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: DOWNLOAD_VOIDED_PURCHASES_SUCCESS, payload: result });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: CLOSE_MODAL, payload: 'voidedPurchasesDownload' });
    } catch (e) {
        yield put({ type: DOWNLOAD_VOIDED_PURCHASES_ERRROR, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* voidedPurchaseSaga() {
    yield takeEvery(GET_VOIDED_PURCHASES, getVoidedPurchases);
    yield takeEvery(SEARCH_VOIDED_PURCHASES, searchVoidedPurchases);
    yield takeEvery(DOWNLOAD_VOIDED_PURCHASES, downloadVoidedPurchases);
}
