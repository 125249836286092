import { createAction } from 'redux-actions';

export const GET_MY_NOTIFICATIONS = 'notification/GET_MY_NOTIFICATIONS';
export const GET_MY_NOTIFICATIONS_SUCCESS = 'notification/GET_MY_NOTIFICATIONS_SUCCESS';
export const GET_MY_NOTIFICATIONS_ERROR = 'notification/GET_MY_NOTIFICATIONS_ERROR';

export const getMyNotifications = createAction(GET_MY_NOTIFICATIONS);

export const UPDATE_TO_READ = 'notification/UPDATE_TO_READ';
export const UPDATE_TO_READ_SUCCESS = 'notification/UPDATE_TO_READ_SUCCESS';
export const UPDATE_TO_READ_ERROR = 'notification/UPDATE_TO_READ_ERROR';

export const updateToRead = createAction(UPDATE_TO_READ, (oid) => oid);
