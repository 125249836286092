import { createAction } from 'redux-actions';

export const GET_LOCALE_MAP = 'localeMap/GET_LOCALE_MAP';
export const GET_LOCALE_MAP_SUCCESS = 'localeMap/GET_LOCALE_MAP_SUCCESS';
export const GET_LOCALE_MAP_ERROR = 'localeMap/GET_LOCALE_MAP_ERROR';

export const getLocaleMap = createAction(GET_LOCALE_MAP, (id) => id);

export const GET_LOCALE_MAPS = 'localeMap/GET_LOCALE_MAPS';
export const GET_LOCALE_MAPS_SUCCESS = 'localeMap/GET_LOCALE_MAPS_SUCCESS';
export const GET_LOCALE_MAPS_ERROR = 'localeMap/GET_LOCALE_MAPS_ERROR';

export const getLocaleMaps = createAction(GET_LOCALE_MAPS, ({ page, limit }) => ({ page, limit }));

export const SEARCH_LOCALE_MAPS = 'localeMap/SEARCH_LOCALE_MAPS';
export const SEARCH_LOCALE_MAPS_SUCCESS = 'localeMap/SEARCH_LOCALE_MAPS_SUCCESS';
export const SEARCH_LOCALE_MAPS_ERROR = 'localeMap/SEARCH_LOCALE_MAPS_ERROR';

export const searchLocaleMaps = createAction(SEARCH_LOCALE_MAPS, ({ page, limit, param }) => ({ page, limit, param }));

export const CREATE_LOCALE_MAP = 'localeMap/CREATE_LOCALE_MAP';
export const CREATE_LOCALE_MAP_SUCCESS = 'localeMap/CREATE_LOCALE_MAP_SUCCESS';
export const CREATE_LOCALE_MAP_ERROR = 'localeMap/CREATE_LOCALE_MAP_ERROR';

export const createLocaleMap = createAction(CREATE_LOCALE_MAP, (payload) => payload);

export const EDIT_LOCALE_MAP = 'localeMap/EDIT_LOCALE_MAP';
export const EDIT_LOCALE_MAP_SUCCESS = 'localeMap/EDIT_LOCALE_MAP_SUCCESS';
export const EDIT_LOCALE_MAP_ERROR = 'localeMap/EDIT_LOCALE_MAP_ERROR';

export const editLocaleMap = createAction(EDIT_LOCALE_MAP, ({ oid, param }) => ({ oid, param }));

export const ON_CHANGE = 'localeMap/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'localeMap/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
