import { handleActions, combineActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions } from 'src/lib/asyncUtils';
import { UPDATE_VIP_LIST, UPDATE_VIP_LIST_SUCCESS, UPDATE_VIP_LIST_ERROR } from './action';

const initialState = {
    result: reducerUtils.initial(false),
};

export default handleActions(
    {
        [combineActions(UPDATE_VIP_LIST, UPDATE_VIP_LIST_SUCCESS, UPDATE_VIP_LIST_ERROR)]: (state, action) =>
            handleAsyncActions(UPDATE_VIP_LIST, 'result', false)(state, action),
    },
    initialState,
);
