import { takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import {
    GET_CREW_MAIL_LOGS_BY_CREW_ID,
    GET_CREW_MAIL_LOGS,
    SEARCH_CREW_MAIL_LOGS,
    GET_CREW_MAIL_LOG_BY_OID,
} from './action';

const getCrewMailLogByOidSaga = createPromiseSaga(GET_CREW_MAIL_LOG_BY_OID, api.getCrewMailLogByOid);
const getCrewMailLogsByCrewIdSaga = createPromiseSaga(GET_CREW_MAIL_LOGS_BY_CREW_ID, api.getCrewMailLogsByCrewId);
const getCrewMailLogsSaga = createPromiseSagaWithHeader(GET_CREW_MAIL_LOGS, api.getCrewMailLogs);
const searchCrewMailLogsSaga = createPromiseSagaWithHeader(SEARCH_CREW_MAIL_LOGS, api.searchCrewMailLogs);

export default function* crewMailLogSaga() {
    yield takeEvery(GET_CREW_MAIL_LOGS_BY_CREW_ID, getCrewMailLogsByCrewIdSaga);
    yield takeEvery(GET_CREW_MAIL_LOGS, getCrewMailLogsSaga);
    yield takeEvery(SEARCH_CREW_MAIL_LOGS, searchCrewMailLogsSaga);
    yield takeEvery(GET_CREW_MAIL_LOG_BY_OID, getCrewMailLogByOidSaga);
}
