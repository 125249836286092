import { createAction } from 'redux-actions';

export const GET_LATEST_RECORDS = 'leaderboards/GET_LATEST_RECORDS';
export const GET_LATEST_RECORDS_SUCCESS = 'leaderboards/GET_LATEST_RECORDS_SUCCESS';
export const GET_LATEST_RECORDS_ERROR = 'leaderboards/GET_LATEST_RECORDS_ERROR';

export const getLatestRecords = createAction(GET_LATEST_RECORDS);

export const GET_CURRENT_RANKERS = 'leaderboards/GET_CURRENT_RANKERS';
export const GET_CURRENT_RANKERS_SUCCESS = 'leaderboards/GET_CURRENT_RANKERS_SUCCESS';
export const GET_CURRENT_RANKERS_ERROR = 'leaderboards/GET_CURRENT_RANKERS_ERROR';

export const getCurrentRankers = createAction(GET_CURRENT_RANKERS, ({ camp, start, end }) => ({ camp, start, end }));

export const GET_RANKERS = 'leaderboards/GET_RANKERS';
export const GET_RANKERS_SUCCESS = 'leaderboards/GET_RANKERS_SUCCESS';
export const GET_RANKERS_ERROR = 'leaderboards/GET_RANKERS_ERROR';

export const getRankers = createAction(GET_RANKERS, ({ season, camp, start, end }) => ({ season, camp, start, end }));
