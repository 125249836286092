import { takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import { GET_PLAYER_BAN_LOGS, GET_BAN_LOGS, SEARCH_BAN_LOGS } from './action';

const getPlayerBanLogsSaga = createPromiseSaga(GET_PLAYER_BAN_LOGS, api.getPlayerBanLogs);
const getBanLogsSaga = createPromiseSagaWithHeader(GET_BAN_LOGS, api.getBanLogs);
const searchBanLogsSaga = createPromiseSagaWithHeader(SEARCH_BAN_LOGS, api.searchBanLogs);

export default function* playerBanLogSaga() {
    yield takeEvery(GET_PLAYER_BAN_LOGS, getPlayerBanLogsSaga);
    yield takeEvery(GET_BAN_LOGS, getBanLogsSaga);
    yield takeEvery(SEARCH_BAN_LOGS, searchBanLogsSaga);
}
