import { createAction } from 'redux-actions';

export const GET_PLAYER_INQUIRY_BAN_LOGS = 'inquiryBanLog/GET_PLAYER_INQUIRY_BAN_LOGS';
export const GET_PLAYER_INQUIRY_BAN_LOGS_SUCCESS = 'inquiryBanLog/GET_PLAYER_INQUIRY_BAN_LOGS_SUCCESS';
export const GET_PLAYER_INQUIRY_BAN_LOGS_ERROR = 'inquiryBanLog/GET_PLAYER_INQUIRY_BAN_LOGS_ERROR';

export const getPlayerInquiryBanLogs = createAction(GET_PLAYER_INQUIRY_BAN_LOGS, ({ gameId, database, playerId }) => ({
    gameId,
    database,
    playerId,
}));

export const GET_INQUIRY_BAN_LOGS = 'inquiryBanLog/GET_INQUIRY_BAN_LOGS';
export const GET_INQUIRY_BAN_LOGS_SUCCESS = 'inquiryBanLog/GET_INQUIRY_BAN_LOGS_SUCCESS';
export const GET_INQUIRY_BAN_LOGS_ERROR = 'inquiryBanLog/GET_INQUIRY_BAN_LOGS_ERROR';

export const getInquiryBanLogs = createAction(GET_INQUIRY_BAN_LOGS, ({ page, limit }) => ({ page, limit }));

export const SEARCH_INQUIRY_BAN_LOGS = 'inquiryBanLog/SEARCH_INQUIRY_BAN_LOGS';
export const SEARCH_INQUIRY_BAN_LOGS_SUCCESS = 'inquiryBanLog/SEARCH_INQUIRY_BAN_LOGS_SUCCESS';
export const SEARCH_INQUIRY_BAN_LOGS_ERROR = 'inquiryBanLog/SEARCH_INQUIRY_BAN_LOGS_ERROR';

export const searchInquiryBanLogs = createAction(SEARCH_INQUIRY_BAN_LOGS, ({ page, limit, param }) => ({
    page,
    limit,
    param,
}));

export const ON_CHANGE = 'inquiryBanLog/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'inquiryBanLog/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
