import { combineActions, handleActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions } from 'src/lib/asyncUtils';
import {
    GET_SOURCE_PLAYER,
    GET_SOURCE_PLAYER_SUCCESS,
    GET_SOURCE_PLAYER_ERROR,
    GET_TARGET_PLAYER,
    GET_TARGET_PLAYER_SUCCESS,
    GET_TARGET_PLAYER_ERROR,
    PLAYER_RECOVER,
    PLAYER_RECOVER_SUCCESS,
    PLAYER_RECOVER_ERROR,
    INITIALIZE,
} from './action';

const initialState = {
    result: reducerUtils.initial(false),
    sourcePlayer: reducerUtils.initial(null),
    targetPlayer: reducerUtils.initial(null),
};

export default handleActions(
    {
        [combineActions(GET_SOURCE_PLAYER, GET_SOURCE_PLAYER_SUCCESS, GET_SOURCE_PLAYER_ERROR)]: (state, action) =>
            handleAsyncActions(GET_SOURCE_PLAYER, 'sourcePlayer', false)(state, action),
        [combineActions(GET_TARGET_PLAYER, GET_TARGET_PLAYER_SUCCESS, GET_TARGET_PLAYER_ERROR)]: (state, action) =>
            handleAsyncActions(GET_TARGET_PLAYER, 'targetPlayer', false)(state, action),
        [combineActions(PLAYER_RECOVER, PLAYER_RECOVER_SUCCESS, PLAYER_RECOVER_ERROR)]: (state, action) =>
            handleAsyncActions(PLAYER_RECOVER, 'result', false)(state, action),
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState,
            };
        },
    },
    initialState,
);
