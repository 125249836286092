import { call, put, take, delay, takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import { GET_GAME_BY_OID, GET_GAMES, GET_GAMES_FOR_SELECT, ADD_GAME, EDIT_GAME, GET_MAPPED_CODES } from './action';
import { OPEN_MODAL, CLOSE_MODAL } from 'src/store/modules/modal';

export const getGameByOidSaga = createPromiseSaga(GET_GAME_BY_OID, api.getGameByOid);
export const getMappedCodesSaga = createPromiseSaga(GET_MAPPED_CODES, api.getMappedCodes);
export const getGamesSaga = createPromiseSagaWithHeader(GET_GAMES, api.getGames);
export const getGamesForSelectSaga = createPromiseSaga(GET_GAMES_FOR_SELECT, api.getGamesForSelect);

function* addGameSaga(action) {
    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.addGame, action.payload);

        yield delay(1000);

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${ADD_GAME}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: CLOSE_MODAL, payload: 'addGame' });
    } catch (e) {
        yield put({ type: `${ADD_GAME}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* editGameSaga(action) {
    const { oid, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.editGame, { oid, param });

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${EDIT_GAME}_SUCCESS`, payload: true });

        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: CLOSE_MODAL, payload: 'editGame' });
    } catch (e) {
        yield put({ type: `${EDIT_GAME}_ERROR`, payload: false });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* gameSaga() {
    yield takeEvery(GET_GAME_BY_OID, getGameByOidSaga);
    yield takeEvery(GET_MAPPED_CODES, getMappedCodesSaga);
    yield takeEvery(GET_GAMES, getGamesSaga);
    yield takeEvery(GET_GAMES_FOR_SELECT, getGamesForSelectSaga);
    yield takeEvery(ADD_GAME, addGameSaga);
    yield takeEvery(EDIT_GAME, editGameSaga);
}
