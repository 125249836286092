import { handleActions, combineActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions } from 'src/lib/asyncUtils';
import {
    PROVIDE_PACKAGE,
    PROVIDE_PACKAGE_SUCCESS,
    PROVIDE_PACKAGE_ERROR,
    PROVIDE_CUSTOM,
    PROVIDE_CUSTOM_SUCCESS,
    PROVIDE_CUSTOM_ERROR,
    PROVIDE_LEGEND_DICE,
    PROVIDE_LEGEND_DICE_SUCCESS,
    PROVIDE_LEGEND_DICE_ERROR,
    PROVIDE_TOKEN,
    PROVIDE_TOKEN_SUCCESS,
    PROVIDE_TOKEN_ERROR,
    PROVIDE_PASS,
    PROVIDE_PASS_SUCCESS,
    PROVIDE_PASS_ERROR,
    PROVIDE_SKIN,
    PROVIDE_SKIN_SUCCESS,
    PROVIDE_SKIN_ERROR,
    PROVIDE_EMBLEM,
    PROVIDE_EMBLEM_SUCCESS,
    PROVIDE_EMBLEM_ERROR,
    PROVIDE_EMOTICON,
    PROVIDE_EMOTICON_SUCCESS,
    PROVIDE_EMOTICON_ERROR,
    PROVIDE_CUSTOM_TO_CREW,
    PROVIDE_CUSTOM_TO_CREW_SUCCESS,
    PROVIDE_CUSTOM_TO_CREW_ERROR,
} from './action';

const initialState = {
    result: reducerUtils.initial(false),
    targetPlayer: {},
};

export default handleActions(
    {
        [combineActions(PROVIDE_PACKAGE, PROVIDE_PACKAGE_SUCCESS, PROVIDE_PACKAGE_ERROR)]: (state, action) =>
            handleAsyncActions(PROVIDE_PACKAGE, 'result', false)(state, action),
        [combineActions(PROVIDE_CUSTOM, PROVIDE_CUSTOM_SUCCESS, PROVIDE_CUSTOM_ERROR)]: (state, action) =>
            handleAsyncActions(PROVIDE_CUSTOM, 'result', false)(state, action),
        [combineActions(PROVIDE_LEGEND_DICE, PROVIDE_LEGEND_DICE_SUCCESS, PROVIDE_LEGEND_DICE_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(PROVIDE_LEGEND_DICE, 'result', false)(state, action),
        [combineActions(PROVIDE_TOKEN, PROVIDE_TOKEN_SUCCESS, PROVIDE_TOKEN_ERROR)]: (state, action) =>
            handleAsyncActions(PROVIDE_TOKEN, 'result', false)(state, action),
        [combineActions(PROVIDE_PASS, PROVIDE_PASS_SUCCESS, PROVIDE_PASS_ERROR)]: (state, action) =>
            handleAsyncActions(PROVIDE_PASS, 'result', false)(state, action),
        [combineActions(PROVIDE_SKIN, PROVIDE_SKIN_SUCCESS, PROVIDE_SKIN_ERROR)]: (state, action) =>
            handleAsyncActions(PROVIDE_SKIN, 'result', false)(state, action),
        [combineActions(PROVIDE_EMBLEM, PROVIDE_EMBLEM_SUCCESS, PROVIDE_EMBLEM_ERROR)]: (state, action) =>
            handleAsyncActions(PROVIDE_EMBLEM, 'result', false)(state, action),
        [combineActions(PROVIDE_EMOTICON, PROVIDE_EMOTICON_SUCCESS, PROVIDE_EMOTICON_ERROR)]: (state, action) =>
            handleAsyncActions(PROVIDE_EMBLEM, 'result', false)(state, action),
        [combineActions(PROVIDE_CUSTOM_TO_CREW, PROVIDE_CUSTOM_TO_CREW_SUCCESS, PROVIDE_CUSTOM_TO_CREW_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(PROVIDE_CUSTOM_TO_CREW, 'result', false)(state, action),
    },
    initialState,
);
