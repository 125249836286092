import { takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import { GET_REPORT_RESULTS } from './action';

const getReportResultsSaga = createPromiseSagaWithHeader(GET_REPORT_RESULTS, api.getReportResults);

export default function* repportResultSaga() {
    yield takeEvery(GET_REPORT_RESULTS, getReportResultsSaga);
}
