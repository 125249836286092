import { combineActions, handleActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions, handleAsyncActionsWithHeader } from 'src/lib/asyncUtils';
import {
    GET_PLAYER_BAN_LOGS,
    GET_PLAYER_BAN_LOGS_SUCCESS,
    GET_PLAYER_BAN_LOGS_ERROR,
    GET_BAN_LOGS,
    GET_BAN_LOGS_SUCCESS,
    GET_BAN_LOGS_ERROR,
    SEARCH_BAN_LOGS,
    SEARCH_BAN_LOGS_SUCCESS,
    SEARCH_BAN_LOGS_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';

const initialState = {
    banLogs: reducerUtils.initial([]),
    isSearch: false,
    searchForm: {},
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(GET_PLAYER_BAN_LOGS, GET_PLAYER_BAN_LOGS_SUCCESS, GET_PLAYER_BAN_LOGS_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_PLAYER_BAN_LOGS, 'banLogs', true)(state, action),
        [combineActions(GET_BAN_LOGS, GET_BAN_LOGS_SUCCESS, GET_BAN_LOGS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(GET_BAN_LOGS, 'banLogs', true)(state, action),
        [combineActions(SEARCH_BAN_LOGS, SEARCH_BAN_LOGS_SUCCESS, SEARCH_BAN_LOGS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(SEARCH_BAN_LOGS, 'banLogs', true)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
