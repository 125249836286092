import { handleActions, combineActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions, handleAsyncActionsWithHeader } from 'src/lib/asyncUtils';
import {
    GET_METADATA_BY_OID,
    GET_METADATA_BY_OID_SUCCESS,
    GET_METADATA_BY_OID_ERROR,
    GET_METADATAS_FOR_SELECT,
    GET_METADATAS_FOR_SELECT_SUCCESS,
    GET_METADATAS_FOR_SELECT_ERROR,
    EDIT_METADATA,
    EDIT_METADATA_SUCCESS,
    EDIT_METADATA_ERROR,
    GET_RESULTS,
    GET_RESULTS_SUCCESS,
    GET_RESULTS_ERROR,
    SEARCH_RESULTS,
    SEARCH_RESULTS_SUCCESS,
    SEARCH_RESULTS_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';

const initialState = {
    result: reducerUtils.initial(false),
    metadata: reducerUtils.initial({}),
    metadatas: reducerUtils.initial([]),
    results: reducerUtils.initial([]),
    isSearch: false,
    searchForm: {},
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(GET_METADATA_BY_OID, GET_METADATA_BY_OID_SUCCESS, GET_METADATA_BY_OID_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_METADATA_BY_OID, 'metadata', true)(state, action),
        [combineActions(GET_METADATAS_FOR_SELECT, GET_METADATAS_FOR_SELECT_SUCCESS, GET_METADATAS_FOR_SELECT_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_METADATAS_FOR_SELECT, 'metadatas', true)(state, action),
        [combineActions(EDIT_METADATA, EDIT_METADATA_SUCCESS, EDIT_METADATA_ERROR)]: (state, action) =>
            handleAsyncActions(EDIT_METADATA, 'result', true)(state, action),
        [combineActions(GET_RESULTS, GET_RESULTS_SUCCESS, GET_RESULTS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(GET_RESULTS, 'results', true)(state, action),
        [combineActions(SEARCH_RESULTS, SEARCH_RESULTS_SUCCESS, SEARCH_RESULTS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(SEARCH_RESULTS, 'results', true)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
