import { combineActions, handleActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions, handleAsyncActionsWithHeader } from 'src/lib/asyncUtils';
import {
    GET_MY_RECOVER_LOGS,
    GET_MY_RECOVER_LOGS_SUCCESS,
    GET_MY_RECOVER_LOGS_ERROR,
    GET_RECOVER_LOGS,
    GET_RECOVER_LOGS_SUCCESS,
    GET_RECOVER_LOGS_ERROR,
    SEARCH_RECOVER_LOGS,
    SEARCH_RECOVER_LOGS_SUCCESS,
    SEARCH_RECOVER_LOGS_ERROR,
    EXPORT_SOURCE_PLAYER_SNAPSHOT,
    EXPORT_SOURCE_PLAYER_SNAPSHOT_SUCCESS,
    EXPORT_SOURCE_PLAYER_SNAPSHOT_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';

const initialState = {
    result: reducerUtils.initial(false),
    myRecoverLogs: reducerUtils.initial([]),
    recoverLogs: reducerUtils.initial([]),
    isSearch: false,
    searchForm: {},
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(GET_MY_RECOVER_LOGS, GET_MY_RECOVER_LOGS_SUCCESS, GET_MY_RECOVER_LOGS_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_MY_RECOVER_LOGS, 'myRecoverLogs', true)(state, action),
        [combineActions(GET_RECOVER_LOGS, GET_RECOVER_LOGS_SUCCESS, GET_RECOVER_LOGS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(GET_RECOVER_LOGS, 'recoverLogs', true)(state, action),
        [combineActions(SEARCH_RECOVER_LOGS, SEARCH_RECOVER_LOGS_SUCCESS, SEARCH_RECOVER_LOGS_ERROR)]: (
            state,
            action,
        ) => handleAsyncActionsWithHeader(SEARCH_RECOVER_LOGS, 'recoverLogs', true)(state, action),
        [combineActions(
            EXPORT_SOURCE_PLAYER_SNAPSHOT,
            EXPORT_SOURCE_PLAYER_SNAPSHOT_SUCCESS,
            EXPORT_SOURCE_PLAYER_SNAPSHOT_ERROR,
        )]: (state, action) => handleAsyncActions(EXPORT_SOURCE_PLAYER_SNAPSHOT, 'result', false)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
