import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { useLoginCheck } from 'src/hooks/auth';
import { useLang, useLocaleMap } from 'src/hooks/lang';
import ConfirmModalV2Container from './containers/ConfirmModalV2Container';

// Containers
const MainLayout = React.lazy(() => import('./components/Layout/MainLayout'));

// Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'));
// const Register = React.lazy(() => import('./views/pages/register/Register'));
const Login = React.lazy(() => import('./pages/LoginPage'));
const Register = React.lazy(() => import('./pages/RegisterPage'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

export default function App() {
    const { check } = useLoginCheck();
    const { lang, onChangeLang } = useLang();
    const { loading, data: localeMap, getLocaleMap } = useLocaleMap();

    useEffect(() => {
        const currentLang = localStorage.getItem('lang') || 'kr';
        onChangeLang(currentLang);

        if (currentLang !== 'kr' && currentLang !== undefined && currentLang !== null) {
            getLocaleMap(currentLang);
        }

        check();
    }, [onChangeLang, getLocaleMap, check]);

    return (
        <IntlProvider locale={lang} messages={localeMap} onError={() => {}}>
            <HashRouter>
                <ConfirmModalV2Container />
                <React.Suspense fallback={loading}>
                    <Switch>
                        <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
                        <Route
                            exact
                            path="/register"
                            name="Register Page"
                            render={(props) => <Register {...props} />}
                        />
                        <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
                        <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
                        <Route path="/" name="Home" render={(props) => <MainLayout {...props} />} />
                    </Switch>
                </React.Suspense>
            </HashRouter>
        </IntlProvider>
    );
}
