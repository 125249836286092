import { handleActions, combineActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions, handleAsyncActionsWithHeader } from 'src/lib/asyncUtils';
import {
    GET_PROVIDE_LOG_BY_OID,
    GET_PROVIDE_LOG_BY_OID_SUCCESS,
    GET_PROVIDE_LOG_BY_OID_ERROR,
    GET_PROVIDE_LOGS_BY_ORDER_ID,
    GET_PROVIDE_LOGS_BY_ORDER_ID_SUCCESS,
    GET_PROVIDE_LOGS_BY_ORDER_ID_ERROR,
    GET_PROVIDE_LOGS_TO,
    GET_PROVIDE_LOGS_TO_SUCCESS,
    GET_PROVIDE_LOGS_TO_ERROR,
    GET_PROVIDE_LOGS,
    GET_PROVIDE_LOGS_SUCCESS,
    GET_PROVIDE_LOGS_ERROR,
    SEARCH_PROVIDE_LOGS,
    SEARCH_PROVIDE_LOGS_SUCCESS,
    SEARCH_PROVIDE_LOGS_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';

const initialState = {
    log: reducerUtils.initial({}),
    logs: reducerUtils.initial([]),
    logsOfSameOrderId: reducerUtils.initial([]),
    isSearch: false,
    searchForm: {},
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(GET_PROVIDE_LOG_BY_OID, GET_PROVIDE_LOG_BY_OID_SUCCESS, GET_PROVIDE_LOG_BY_OID_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_PROVIDE_LOG_BY_OID, 'log', false)(state, action),
        [combineActions(
            GET_PROVIDE_LOGS_BY_ORDER_ID,
            GET_PROVIDE_LOGS_BY_ORDER_ID_SUCCESS,
            GET_PROVIDE_LOGS_BY_ORDER_ID_ERROR,
        )]: (state, action) =>
            handleAsyncActions(GET_PROVIDE_LOGS_BY_ORDER_ID, 'logsOfSameOrderId', true)(state, action),
        [combineActions(GET_PROVIDE_LOGS_TO, GET_PROVIDE_LOGS_TO_SUCCESS, GET_PROVIDE_LOGS_TO_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_PROVIDE_LOGS_TO, 'logs', true)(state, action),
        [combineActions(GET_PROVIDE_LOGS, GET_PROVIDE_LOGS_SUCCESS, GET_PROVIDE_LOGS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(GET_PROVIDE_LOGS, 'logs', true)(state, action),
        [combineActions(SEARCH_PROVIDE_LOGS, SEARCH_PROVIDE_LOGS_SUCCESS, SEARCH_PROVIDE_LOGS_ERROR)]: (
            state,
            action,
        ) => handleAsyncActionsWithHeader(SEARCH_PROVIDE_LOGS, 'logs', true)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
