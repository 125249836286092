import { takeEvery, take, call, put, select } from 'redux-saga/effects';
import { createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import * as api from 'src/lib/api';
import {
    ADD_PERIOD_BAN_PLAYERS,
    GET_PERIOD_BAN_PLAYERS,
    SEARCH_PERIOD_BAN_PLAYERS,
    DELETE_PERIOD_BAN_PLAYER,
} from './action';
import { CLOSE_MODAL, CONFIRM, OPEN_MODAL } from '../modal';

const getPeriodBanPlayersSaga = createPromiseSagaWithHeader(GET_PERIOD_BAN_PLAYERS, api.getPeriodBanPlayers);
const searchPeriodBanPlayersSaga = createPromiseSagaWithHeader(SEARCH_PERIOD_BAN_PLAYERS, api.searchPeriodBanPlayers);

function* addPeriodBanPlayersSaga(action) {
    const param = action.payload;

    try {
        const formData = new FormData();

        Object.keys(param).forEach((key) => {
            formData.append(key, param[key]);
        });

        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.addPeriodBanPlayers, formData);

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${ADD_PERIOD_BAN_PLAYERS}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: CLOSE_MODAL, payload: 'addPeriodBanPlayers' });
        yield put({ type: GET_PERIOD_BAN_PLAYERS, payload: { page: 1, limit: 14 } });
    } catch (e) {
        yield put({ type: `${ADD_PERIOD_BAN_PLAYERS}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* deletePeriodBanPlayerSaga(action) {
    try {
        const { payload: isConfirm } = yield take(CONFIRM);

        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

            yield call(api.deletePeriodBanPlayer, action.payload);

            yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

            yield put({ type: `${DELETE_PERIOD_BAN_PLAYER}_SUCCESS`, payload: true });

            yield put({ type: OPEN_MODAL, payload: 'successAlert' });

            yield take(CLOSE_MODAL);

            const isSearch = yield select((state) => state.periodBanPlayer.isSearch);
            const currentPage = yield select((state) => state.periodBanPlayer.currentPage);
            if (isSearch === true) {
                const searchForm = yield select((state) => state.periodBanPlayer.searchForm);
                yield put({
                    type: SEARCH_PERIOD_BAN_PLAYERS,
                    payload: { page: currentPage, limit: 14, param: searchForm },
                });
            } else {
                yield put({ type: GET_PERIOD_BAN_PLAYERS, payload: { page: currentPage, limit: 14 } });
            }
        }
    } catch (e) {
        yield put({ type: `${DELETE_PERIOD_BAN_PLAYER}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* periodBanPlayerSaga() {
    yield takeEvery(ADD_PERIOD_BAN_PLAYERS, addPeriodBanPlayersSaga);
    yield takeEvery(GET_PERIOD_BAN_PLAYERS, getPeriodBanPlayersSaga);
    yield takeEvery(SEARCH_PERIOD_BAN_PLAYERS, searchPeriodBanPlayersSaga);
    yield takeEvery(DELETE_PERIOD_BAN_PLAYER, deletePeriodBanPlayerSaga);
}
