import { combineActions, handleActions } from 'redux-actions';
import {
    PERIOD_BAN,
    PERIOD_BAN_SUCCESS,
    PERIOD_BAN_ERROR,
    PERIOD_UNBAN,
    PERIOD_UNBAN_SUCCESS,
    PERIOD_UNBAN_ERROR,
    GET_PERIOD_BAN_STATE,
    GET_PERIOD_BAN_STATE_SUCCESS,
    GET_PERIOD_BAN_STATE_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';
import { reducerUtils, handleAsyncActions } from 'src/lib/asyncUtils';

const initialState = {
    periodBanState: reducerUtils.initial([]),
    result: reducerUtils.initial(false),
    target: null,
};

export default handleActions(
    {
        [combineActions(PERIOD_BAN, PERIOD_BAN_SUCCESS, PERIOD_BAN_ERROR)]: (state, action) =>
            handleAsyncActions(PERIOD_BAN, 'result', true)(state, action),
        [combineActions(PERIOD_UNBAN, PERIOD_UNBAN_SUCCESS, PERIOD_UNBAN_ERROR)]: (state, action) =>
            handleAsyncActions(PERIOD_BAN, 'result', true)(state, action),
        [combineActions(GET_PERIOD_BAN_STATE, GET_PERIOD_BAN_STATE_SUCCESS, GET_PERIOD_BAN_STATE_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_PERIOD_BAN_STATE, 'periodBanState', true)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
