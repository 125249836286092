import { handleActions } from 'redux-actions';
import { CLOSE_CONFIRM_MODAL, OPEN_CONFIRM_MODAL } from './action';

export default handleActions(
    {
        [OPEN_CONFIRM_MODAL]: (state, action) => {
            const { onOk, message } = action.payload;

            return {
                onOk,
                message,
                isOpen: true,
            };
        },
        [CLOSE_CONFIRM_MODAL]: () => {
            return {
                isOpen: false,
            };
        },
    },
    { isOpen: false },
);
