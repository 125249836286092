import { takeEvery, put, call } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import { GET_MY_RECOVER_LOGS, GET_RECOVER_LOGS, SEARCH_RECOVER_LOGS, EXPORT_SOURCE_PLAYER_SNAPSHOT } from './action';
import { OPEN_MODAL, CLOSE_MODAL } from 'src/store/modules/modal';

const getMyRecoverLogsSaga = createPromiseSaga(GET_MY_RECOVER_LOGS, api.getMyRecoverLogs);
const getRecoverLogs = createPromiseSagaWithHeader(GET_RECOVER_LOGS, api.getRecoverLogs);
const searchRecoverLogsSaga = createPromiseSagaWithHeader(SEARCH_RECOVER_LOGS, api.searchRecoverLogs);

function* exportSourcePlayerSnapshotSaga(action) {
    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.exportSourcePlayerSnapshot, action.payload);

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${EXPORT_SOURCE_PLAYER_SNAPSHOT}_SUCCESS`, payload: true });
    } catch (e) {
        yield put({ type: `${EXPORT_SOURCE_PLAYER_SNAPSHOT}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* recoverLogSaga() {
    yield takeEvery(GET_MY_RECOVER_LOGS, getMyRecoverLogsSaga);
    yield takeEvery(GET_RECOVER_LOGS, getRecoverLogs);
    yield takeEvery(SEARCH_RECOVER_LOGS, searchRecoverLogsSaga);
    yield takeEvery(EXPORT_SOURCE_PLAYER_SNAPSHOT, exportSourcePlayerSnapshotSaga);
}
