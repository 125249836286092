import { createAction } from 'redux-actions';

export const GET_PLAYER_BY_OID = 'player/GET_PLAYER_BY_OID';
export const GET_PLAYER_BY_OID_SUCCESS = 'player/GET_PLAYER_BY_OID_SUCCESS';
export const GET_PLAYER_BY_OID_ERROR = 'player/GET_PLAYER_BY_OID_ERROR';

export const getPlayerByOid = createAction(GET_PLAYER_BY_OID, ({ gameId, id }) => ({ gameId, id }));

export const GET_PLAYER_BY_PID = 'player/GET_PLAYER_BY_PID';
export const GET_PLAYER_BY_PID_SUCCESS = 'player/GET_PLAYER_BY_PID_SUCCESS';
export const GET_PLAYER_BY_PID_ERROR = 'player/GET_PLAYER_BY_PID_ERROR';

export const getPlayerByPid = createAction(GET_PLAYER_BY_PID, ({ gameId, id }) => ({ gameId, id }));

export const GET_PLAYER_BY_PID_FROM_FIREBASE = 'player/GET_PLAYER_BY_PID_FROM_FIREBASE';
export const GET_PLAYER_BY_PID_FROM_FIREBASE_SUCCESS = 'player/GET_PLAYER_BY_PID_FROM_FIREBASE_SUCCESS';
export const GET_PLAYER_BY_PID_FROM_FIREBASE_ERROR = 'player/GET_PLAYER_BY_PID_FROM_FIREBASE_ERROR';

export const getPlayerByPidFromFirebase = createAction(GET_PLAYER_BY_PID_FROM_FIREBASE, ({ gameId, id }) => ({
    gameId,
    id,
}));

export const GET_PLAYER_FROM_FIREBASE = 'player/GET_PLAYER_FROM_FIREBASE';
export const GET_PLAYER_FROM_FIREBASE_SUCCESS = 'player/GET_PLAYER_FROM_FIREBASE_SUCCESS';
export const GET_PLAYER_FROM_FIREBASE_ERROR = 'player/GET_PLAYER_FROM_FIREBASE_ERROR';

export const getPlayerFromFirebase = createAction(GET_PLAYER_FROM_FIREBASE, ({ gameId, database, playerId }) => ({
    gameId,
    database,
    playerId,
}));

export const GET_PLAYER_LOGS = 'player/GET_PLAYER_LOGS';
export const GET_PLAYER_LOGS_SUCCESS = 'player/GET_PLAYER_LOGS_SUCCESS';
export const GET_PLAYER_LOGS_ERROR = 'player/GET_PLAYER_LOGS_ERROR';

export const getPlayerLogs = createAction(GET_PLAYER_LOGS, ({ gameId, id, date }) => ({ gameId, id, date }));

export const GET_PLAYER_BATTLE_LOGS = 'player/GET_PLAYER_BATTLE_LOGS';
export const GET_PLAYER_BATTLE_LOGS_SUCCESS = 'player/GET_PLAYER_BATTLE_LOGS_SUCCESS';
export const GET_PLAYER_BATTLE_LOGS_ERROR = 'player/GET_PLAYER_BATTLE_LOGS_ERROR';

export const getPlayerBattleLogs = createAction(GET_PLAYER_BATTLE_LOGS, ({ gameId, id, date, mode }) => ({
    gameId,
    id,
    date,
    mode,
}));

export const GET_PLAYERS = 'player/GET_PLAYERS';
export const GET_PLAYERS_SUCCESS = 'player/GET_PLAYERS_SUCCESS';
export const GET_PLAYERS_ERROR = 'player/GET_PLAYERS_ERROR';

export const getPlayers = createAction(GET_PLAYERS, ({ gameId, page }) => ({ gameId, page }));

export const SEARCH_PLAYERS = 'player/SEARCH_PLAYERS';
export const SEARCH_PLAYERS_SUCCESS = 'player/SEARCH_PLAYERS_SUCCESS';
export const SEARCH_PLAYERS_ERROR = 'player/SEARCH_PLAYERS_ERROR';

export const searchPlayers = createAction(SEARCH_PLAYERS, ({ gameId, page, param }) => ({ gameId, page, param }));

export const SEARCH_PLAYERS_FROM_FIREBASE = 'player/SEARCH_PLAYERS_FROM_FIREBASE';
export const SEARCH_PLAYERS_FROM_FIREBASE_SUCCESS = 'player/SEARCH_PLAYERS_FROM_FIREBASE_SUCCESS';
export const SEARCH_PLAYERS_FROM_FIREBASE_ERROR = 'player/SEARCH_PLAYERS_FROM_FIREBASE_ERROR';

export const searchPlayersFromFirebase = createAction(SEARCH_PLAYERS_FROM_FIREBASE, ({ gameId, id }) => ({
    gameId,
    id,
}));

export const COMPARE_PLAYER_DATA = 'player/COMPARE_PLAYER_DATA';
export const COMPARE_PLAYER_DATA_SUCCESS = 'player/COMPARE_PLAYER_DATA_SUCCESS';
export const COMPARE_PLAYER_DATA_ERROR = 'player/COMPARE_PLAYER_DATA_ERROR';

export const comparePlayerData = createAction(COMPARE_PLAYER_DATA, ({ gameId, param }) => ({ gameId, param }));

export const GET_SNAPSHOT_LIST = 'player/GET_SNAPSHOT_LIST';
export const GET_SNAPSHOT_LIST_SUCCESS = 'player/GET_SNAPSHOT_LIST_SUCCESS';
export const GET_SNAPSHOT_LIST_ERROR = 'player/GET_SNAPSHOT_LIST_ERROR';

export const getSnapshotList = createAction(GET_SNAPSHOT_LIST, ({ gameId, limit, param }) => ({
    gameId,
    limit,
    param,
}));

export const GET_PLAYER_CREW = 'player/GET_PLAYER_CREW';
export const GET_PLAYER_CREW_SUCCESS = 'player/GET_PLAYER_CREW_SUCCESS';
export const GET_PLAYER_CREW_ERROR = 'player/GET_PLAYER_CREW_ERROR';

export const getPlayerCrew = createAction(GET_PLAYER_CREW, ({ gameId, playerId }) => ({ gameId, playerId }));

export const UPDATE_PLAYER_BY_PRESET = 'player/UPDATE_PLAYER_BY_PRESET';
export const UPDATE_PLAYER_BY_PRESET_SUCCESS = 'player/UPDATE_PLAYER_BY_PRESET_SUCCESS';
export const UPDATE_PLAYER_BY_PRESET_ERROR = 'player/UPDATE_PLAYER_BY_PRESET_ERROR';

export const updatePlayerByPreset = createAction(UPDATE_PLAYER_BY_PRESET, ({ gameId, param }) => ({ gameId, param }));

export const SET_TARGET = 'player/SET_TARGET';
export const setTarget = createAction(SET_TARGET);

export const ON_CHANGE = 'player/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'player/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
