import { takeEvery, take, call, put, select } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import { CLOSE_MODAL, OPEN_MODAL, CONFIRM } from '../modal';
import {
    GET_DATA_PRESETS_FOR_SELECT,
    GET_ACTIVATED_DATA_PRESETS_FOR_SELECT,
    GET_DATA_PRESET,
    CREATE_DATA_PRESET,
    EDIT_DATA_PRESET,
    GET_DATA_PRESETS,
    SEARCH_DATA_PRESETS,
    ACTIVATE_DATA_PRESET,
    DEACTIVATE_DATA_PRESET,
    GET_DATA_PRESET_CATEGORIES,
} from './action';

const getDataPresetsForSelectSaga = createPromiseSaga(GET_DATA_PRESETS_FOR_SELECT, api.getDataPresetsForSelect);
const getActivatedDataPresetsForSelectSaga = createPromiseSaga(
    GET_ACTIVATED_DATA_PRESETS_FOR_SELECT,
    api.getActivatedDataPresetsForSelect,
);
const getDataPresetSaga = createPromiseSaga(GET_DATA_PRESET, api.getDataPreset);
const getDataPresetsSaga = createPromiseSagaWithHeader(GET_DATA_PRESETS, api.getDataPresets);
const searchDataPresetsSaga = createPromiseSagaWithHeader(SEARCH_DATA_PRESETS, api.searchDataPresets);
const getDataPresetCategoriesSaga = createPromiseSaga(GET_DATA_PRESET_CATEGORIES, api.getDataPresetCategories);

function* createDataPresetSaga(action) {
    const { payload } = action;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        yield call(api.createDataPreset, payload);
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${CREATE_DATA_PRESET}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);
        yield put({ type: CLOSE_MODAL, payload: 'dataPresetCreate' });

        const currentPage = yield select((state) => state.dataPreset.currentPage);

        yield put({ type: GET_DATA_PRESETS, payload: { page: currentPage, limit: 15 } });
    } catch (e) {
        yield put({ type: `${CREATE_DATA_PRESET}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* editDataPresetSaga(action) {
    const { payload } = action;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        yield call(api.editDataPreset, payload);
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${EDIT_DATA_PRESET}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);
        yield put({ type: CLOSE_MODAL, payload: 'dataPresetDetail' });

        const isSearch = yield select((state) => state.dataPreset.isSearch);
        const currentPage = yield select((state) => state.dataPreset.currentPage);

        if (isSearch) {
            const searchForm = yield select((state) => state.dataPreset.searchForm);
            yield put({ type: SEARCH_DATA_PRESETS, payload: { page: currentPage, limit: 15, param: searchForm } });
        } else {
            yield put({ type: GET_DATA_PRESETS, payload: { page: currentPage, limit: 15 } });
        }
    } catch (e) {
        yield put({ type: `${EDIT_DATA_PRESET}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* activateDataPresetSaga(action) {
    try {
        const { payload: isConfirm } = yield take(CONFIRM);

        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
            yield call(api.activateDataPreset, action.payload);
            yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

            yield put({ type: `${ACTIVATE_DATA_PRESET}_SUCCESS`, payload: true });
            yield put({ type: OPEN_MODAL, payload: 'successAlert' });

            yield take(CLOSE_MODAL);
            yield put({ type: CLOSE_MODAL, payload: 'activateDataPresetConfirm' });

            const isSearch = yield select((state) => state.dataPreset.isSearch);
            const currentPage = yield select((state) => state.dataPreset.currentPage);

            if (isSearch) {
                const searchForm = yield select((state) => state.dataPreset.searchForm);
                yield put({ type: SEARCH_DATA_PRESETS, payload: { page: currentPage, limit: 15, param: searchForm } });
            } else {
                yield put({ type: GET_DATA_PRESETS, payload: { page: currentPage, limit: 15 } });
            }
        }
    } catch (e) {
        yield put({ type: `${ACTIVATE_DATA_PRESET}_ERROR`, error: true, payload: e });
        yield put({ tyoe: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* deactivateDataPresetSaga(action) {
    try {
        const { payload: isConfirm } = yield take(CONFIRM);

        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
            yield call(api.deactivateDataPreset, action.payload);
            yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

            yield put({ type: `${DEACTIVATE_DATA_PRESET}_SUCCESS`, payload: true });
            yield put({ type: OPEN_MODAL, payload: 'successAlert' });

            yield take(CLOSE_MODAL);
            yield put({ type: CLOSE_MODAL, payload: 'deactivateDataPresetConfirm' });

            const isSearch = yield select((state) => state.dataPreset.isSearch);
            const currentPage = yield select((state) => state.dataPreset.currentPage);

            if (isSearch) {
                const searchForm = yield select((state) => state.dataPreset.searchForm);
                yield put({ type: SEARCH_DATA_PRESETS, payload: { page: currentPage, limit: 15, param: searchForm } });
            } else {
                yield put({ type: GET_DATA_PRESETS, payload: { page: currentPage, limit: 15 } });
            }
        }
    } catch (e) {
        yield put({ type: `${DEACTIVATE_DATA_PRESET}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* dataPresetSaga() {
    yield takeEvery(GET_DATA_PRESETS_FOR_SELECT, getDataPresetsForSelectSaga);
    yield takeEvery(GET_DATA_PRESET, getDataPresetSaga);
    yield takeEvery(CREATE_DATA_PRESET, createDataPresetSaga);
    yield takeEvery(EDIT_DATA_PRESET, editDataPresetSaga);
    yield takeEvery(GET_DATA_PRESETS, getDataPresetsSaga);
    yield takeEvery(SEARCH_DATA_PRESETS, searchDataPresetsSaga);
    yield takeEvery(ACTIVATE_DATA_PRESET, activateDataPresetSaga);
    yield takeEvery(DEACTIVATE_DATA_PRESET, deactivateDataPresetSaga);
    yield takeEvery(GET_ACTIVATED_DATA_PRESETS_FOR_SELECT, getActivatedDataPresetsForSelectSaga);
    yield takeEvery(GET_DATA_PRESET_CATEGORIES, getDataPresetCategoriesSaga);
}
