import { handleActions, combineActions } from 'redux-actions';
import { reducerUtils, handleAsyncActionsWithHeader, handleAsyncActions } from 'src/lib/asyncUtils';
import {
    GET_DELETE_LOG_BY_OID,
    GET_DELETE_LOG_BY_OID_SUCCESS,
    GET_DELETE_LOG_BY_OID_ERROR,
    GET_DELETE_LOGS,
    GET_DELETE_LOGS_SUCCESS,
    GET_DELETE_LOGS_ERROR,
    SEARCH_DELETE_LOGS,
    SEARCH_DELETE_LOGS_SUCCESS,
    SEARCH_DELETE_LOGS_ERROR,
    GET_TARGET_PLAYER,
    GET_TARGET_PLAYER_SUCCESS,
    GET_TARGET_PLAYER_ERROR,
    RECOVER,
    RECOVER_SUCCESS,
    RECOVER_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';

const initialState = {
    deleteLogs: reducerUtils.initial([]),
    deleteLog: reducerUtils.initial({}),
    targetPlayer: reducerUtils.initial(null),
    result: reducerUtils.initial(false),
    target: null,
    isSearch: false,
    searchForm: {},
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(GET_DELETE_LOG_BY_OID, GET_DELETE_LOG_BY_OID_SUCCESS, GET_DELETE_LOG_BY_OID_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_DELETE_LOG_BY_OID, 'deleteLog', false)(state, action),
        [combineActions(GET_DELETE_LOGS, GET_DELETE_LOGS_SUCCESS, GET_DELETE_LOGS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(GET_DELETE_LOGS, 'deleteLogs', true)(state, action),
        [combineActions(SEARCH_DELETE_LOGS, SEARCH_DELETE_LOGS_SUCCESS, SEARCH_DELETE_LOGS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(SEARCH_DELETE_LOGS, 'deleteLogs', true)(state, action),
        [combineActions(GET_TARGET_PLAYER, GET_TARGET_PLAYER_SUCCESS, GET_TARGET_PLAYER_ERROR)]: (state, action) =>
            handleAsyncActions(GET_TARGET_PLAYER, 'targetPlayer', false)(state, action),
        [combineActions(RECOVER, RECOVER_SUCCESS, RECOVER_ERROR)]: (state, action) =>
            handleAsyncActions(RECOVER, 'result', false)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
