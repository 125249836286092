import { combineActions, handleActions } from 'redux-actions';
import { reducerUtils, handleAsyncActionsWithHeader, handleAsyncActions } from 'src/lib/asyncUtils';
import {
    GET_BACKUP_FILTER_BY_OID,
    GET_BACKUP_FILTER_BY_OID_SUCCESS,
    GET_BACKUP_FILTER_BY_OID_ERROR,
    GET_BACKUP_FILTERS,
    GET_BACKUP_FILTERS_SUCCESS,
    GET_BACKUP_FILTERS_ERROR,
    ADD_BACKUP_FILTER,
    ADD_BACKUP_FILTER_SUCCESS,
    ADD_BACKUP_FILTER_ERROR,
    EDIT_BACKUP_FILTER,
    EDIT_BACKUP_FILTER_SUCCESS,
    EDIT_BACKUP_FILTER_ERROR,
    ON_CHANGE,
    ON_PUSH_ITEM,
    ON_REMOVE_ITEM,
    INITIALIZE,
} from './action';

const initialState = {
    result: reducerUtils.initial(false),
    filters: reducerUtils.initial([]),
    filter: reducerUtils.initial({}),
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(GET_BACKUP_FILTERS, GET_BACKUP_FILTERS_SUCCESS, GET_BACKUP_FILTERS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(GET_BACKUP_FILTERS, 'filters', true)(state, action),
        [combineActions(GET_BACKUP_FILTER_BY_OID, GET_BACKUP_FILTER_BY_OID_SUCCESS, GET_BACKUP_FILTER_BY_OID_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_BACKUP_FILTER_BY_OID, 'filter', false)(state, action),
        [combineActions(ADD_BACKUP_FILTER, ADD_BACKUP_FILTER_SUCCESS, ADD_BACKUP_FILTER_ERROR)]: (state, action) =>
            handleAsyncActions(ADD_BACKUP_FILTER, 'result', false)(state, action),
        [combineActions(EDIT_BACKUP_FILTER, EDIT_BACKUP_FILTER_SUCCESS, EDIT_BACKUP_FILTER_ERROR)]: (state, action) =>
            handleAsyncActions(EDIT_BACKUP_FILTER, 'result', false)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: [value],
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [ON_PUSH_ITEM]: (state, action) => {
            const { target, name, value } = action.payload;
            const currentList = (target ? state[target][name] : state[name]) || [];
            const newList = currentList.includes(value) ? currentList : [...currentList, value];

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: newList,
                      },
                  }
                : {
                      ...state,
                      [name]: newList,
                  };
        },
        [ON_REMOVE_ITEM]: (state, action) => {
            const { target, name, index } = action.payload;
            const currentList = state[target][name] || [];
            const newList = [...currentList.slice(0, index), ...currentList.slice(index + 1, currentList.length)];

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: newList,
                      },
                  }
                : {
                      ...state,
                      [name]: newList,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
