import { put, call, takeEvery, take } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import {
    GET_PACKAGES,
    GET_COUNT_BY_PACKAGE_TYPE,
    GET_PACKAGES_FOR_SELECT,
    FIND_PACKAGE,
    CREATE_PACKAGE,
    EDIT_PACKAGE,
} from './action';
import { createPromiseSaga } from 'src/lib/asyncUtils';
import { CLOSE_MODAL, OPEN_MODAL } from 'src/store/modules/modal';

const getPackagesForSelectSaga = createPromiseSaga(GET_PACKAGES_FOR_SELECT, api.getPackagesForSelect);

function* getPackagesSaga(action) {
    const gameId = action.payload;

    try {
        const {
            data: { packages, count },
        } = yield call(api.getPackages, gameId);

        yield put({ type: `${GET_PACKAGES}_SUCCESS`, payload: packages });
        yield put({ type: `${GET_COUNT_BY_PACKAGE_TYPE}_SUCCESS`, payload: count });
    } catch (e) {
        yield put({ type: `${GET_PACKAGES}_ERROR`, error: true, payload: e });
    }
}

function* createPackageSaga(action) {
    const { gameId, param } = action.payload;

    try {
        yield call(api.createPackage, { gameId, param });

        yield put({ type: `${CREATE_PACKAGE}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });
        yield put({ type: CLOSE_MODAL, payload: 'createPackage' });

        yield put({ type: GET_PACKAGES, payload: gameId });
    } catch (e) {
        yield put({ type: `${CREATE_PACKAGE}_ERROR`, error: true, payload: false });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* editPackageSaga(action) {
    const { oid, gameId, param } = action.payload;

    try {
        yield call(api.editPackage, { oid, param });

        yield put({ type: `${EDIT_PACKAGE}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield put({ type: GET_PACKAGES, payload: gameId });

        yield take(`${GET_PACKAGES}_SUCCESS`);
        yield put({ type: FIND_PACKAGE, payload: oid });
    } catch (e) {
        yield put({ type: `${EDIT_PACKAGE}_ERROR`, error: true, payload: false });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* packageSaga() {
    yield takeEvery(GET_PACKAGES, getPackagesSaga);
    yield takeEvery(GET_PACKAGES_FOR_SELECT, getPackagesForSelectSaga);
    yield takeEvery(CREATE_PACKAGE, createPackageSaga);
    yield takeEvery(EDIT_PACKAGE, editPackageSaga);
}
