import { combineActions, handleActions } from 'redux-actions';
import { reducerUtils, handleAsyncActionsWithHeader } from 'src/lib/asyncUtils';
import {
    GET_MANUAL_PROVIDE_LOGS,
    GET_MANUAL_PROVIDE_LOGS_SUCCESS,
    GET_MANUAL_PROVIDE_LOGS_ERROR,
    SEARCH_MANUAL_PROVIDE_LOGS,
    SEARCH_MANUAL_PROVIDE_LOGS_SUCCESS,
    SEARCH_MANUAL_PROVIDE_LOGS_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';

const initialState = {
    logs: reducerUtils.initial([]),
    searchForm: {},
    isSearch: false,
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(GET_MANUAL_PROVIDE_LOGS, GET_MANUAL_PROVIDE_LOGS_SUCCESS, GET_MANUAL_PROVIDE_LOGS_ERROR)]: (
            state,
            action,
        ) => handleAsyncActionsWithHeader(GET_MANUAL_PROVIDE_LOGS, 'logs', true)(state, action),
        [combineActions(
            SEARCH_MANUAL_PROVIDE_LOGS,
            SEARCH_MANUAL_PROVIDE_LOGS_SUCCESS,
            SEARCH_MANUAL_PROVIDE_LOGS_ERROR,
        )]: (state, action) => handleAsyncActionsWithHeader(SEARCH_MANUAL_PROVIDE_LOGS, 'logs', true)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
