import { takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import { GET_CREWS, SEARCH_CREWS, GET_CREW_DETAIL } from './action';

const getCrewsSaga = createPromiseSagaWithHeader(GET_CREWS, api.getCrews);
const searchCrewsSaga = createPromiseSagaWithHeader(SEARCH_CREWS, api.searchCrews);
const getCrewDetailSaga = createPromiseSaga(GET_CREW_DETAIL, api.getCrewDetail);

export default function* crewSaga() {
    yield takeEvery(GET_CREWS, getCrewsSaga);
    yield takeEvery(SEARCH_CREWS, searchCrewsSaga);
    yield takeEvery(GET_CREW_DETAIL, getCrewDetailSaga);
}
