import { call, put, take, takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import { GET_LOCALE_MAPS, SEARCH_LOCALE_MAPS, CREATE_LOCALE_MAP, GET_LOCALE_MAP, EDIT_LOCALE_MAP } from './action';
import { OPEN_MODAL, CLOSE_MODAL } from 'src/store/modules/modal';

const getLocaleMapSaga = createPromiseSaga(GET_LOCALE_MAP, api.getLocaleMap);
const getLocaleMapsSaga = createPromiseSagaWithHeader(GET_LOCALE_MAPS, api.getLocaleMaps);
const searchLocaleMapsSaga = createPromiseSagaWithHeader(SEARCH_LOCALE_MAPS, api.searchLocaleMaps);

function* createLocaleMapSaga(action) {
    const param = action.payload;

    try {
        const formData = new FormData();

        Object.keys(param).forEach((key) => {
            formData.append(key, param[key]);
        });

        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.createLocaleMap, formData);

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${CREATE_LOCALE_MAP}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: CLOSE_MODAL, payload: 'createLocaleMap' });
        yield put({ type: GET_LOCALE_MAPS, payload: { page: 1, limit: 10 } });
    } catch (e) {
        yield put({ type: `${CREATE_LOCALE_MAP}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* editLocaleMapSaga(action) {
    const { oid, param } = action.payload;

    try {
        const formData = new FormData();

        Object.keys(param).forEach((key) => {
            formData.append(key, param[key]);
        });

        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.editLocaleMap, { oid, param: formData });

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${EDIT_LOCALE_MAP}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: CLOSE_MODAL, payload: 'editLocaleMap' });
        yield put({ type: GET_LOCALE_MAPS, payload: { page: 1, limit: 10 } });
    } catch (e) {
        yield put({ type: `${EDIT_LOCALE_MAP}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* localeMapSaga() {
    yield takeEvery(GET_LOCALE_MAP, getLocaleMapSaga);
    yield takeEvery(GET_LOCALE_MAPS, getLocaleMapsSaga);
    yield takeEvery(SEARCH_LOCALE_MAPS, searchLocaleMapsSaga);
    yield takeEvery(CREATE_LOCALE_MAP, createLocaleMapSaga);
    yield takeEvery(EDIT_LOCALE_MAP, editLocaleMapSaga);
}
