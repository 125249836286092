import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { onChange as onChangeAction } from 'src/store/modules/lang';

export default function useLang() {
    const langOptions = [
        { value: 'kr', label: '한국어' },
        { value: 'en', label: 'English' },
        { value: 'jp', label: '日本語' },
    ];
    const lang = useSelector((state) => state.lang.lang);

    const dispatch = useDispatch();
    const onChangeLang = useCallback(
        (value) => {
            localStorage.setItem('lang', value);

            dispatch(onChangeAction({ name: 'lang', value }));
        },
        [dispatch],
    );

    return {
        langOptions,
        lang,
        onChangeLang,
    };
}
