import { call, put, take, takeEvery } from 'redux-saga/effects';
import { createPromiseSaga } from 'src/lib/asyncUtils';
import * as api from 'src/lib/api';
import {
    GET_SEASON_RECORDS,
    GET_SEASON_DATA,
    CREATE_DUMMY_RANKERS,
    SET_TARGET_PLAYER,
    EDIT_RANK,
    EDIT_SEASON,
    GET_RANKERS,
} from './action';
import { GET_PLAYER_FROM_FIREBASE } from 'src/store/modules/player';
import { OPEN_MODAL, CLOSE_MODAL, CONFIRM, CONFIRM_WITH } from 'src/store/modules/modal';

const getSeasonRecordsSaga = createPromiseSaga(GET_SEASON_RECORDS, api.getSeasonRecords);
const getSeasonDataSaga = createPromiseSaga(GET_SEASON_DATA, api.getSeasonData);
const getRankersSaga = createPromiseSaga(GET_RANKERS, api.getRankers);

function* createDummyRankersSaga(action) {
    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        yield call(api.createDummyRankers, action.payload);
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: `${CREATE_DUMMY_RANKERS}_SUCCESS`, payload: true });
    } catch (e) {
        yield put({ type: `${CREATE_DUMMY_RANKERS}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* editRankSaga(action) {
    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        yield call(api.editRank, action.payload);
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: `${EDIT_RANK}_SUCCESS`, payload: true });
    } catch (e) {
        yield put({ type: `${EDIT_RANK}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* editSeasonSaga(action) {
    const { season, param } = action.payload;

    try {
        const { payload: isConfirm } = yield take(CONFIRM);

        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
            yield call(api.editSeason, { season, param });
            yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

            yield put({ type: OPEN_MODAL, payload: 'successAlert' });
        }
    } catch (e) {
        yield put({ type: `${EDIT_SEASON}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* setTargetPlayerSaga() {
    try {
        const { payload } = yield take(CONFIRM_WITH);
        const { isConfirm, ...param } = payload;
        const { gameId, database, playerId } = param;

        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'loadingAlert' });
            yield put({ type: GET_PLAYER_FROM_FIREBASE, payload: { gameId, database, playerId } });
            yield put({ type: CLOSE_MODAL, payload: 'loadingAlert' });
        }

        yield put({ type: CLOSE_MODAL, payload: 'playerSearch' });
    } catch (e) {
        yield put({ type: `${SET_TARGET_PLAYER}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
        yield put({ type: CLOSE_MODAL, payload: 'loadingAlert' });
    }
}

export default function* seasonSaga() {
    yield takeEvery(GET_SEASON_RECORDS, getSeasonRecordsSaga);
    yield takeEvery(GET_SEASON_DATA, getSeasonDataSaga);
    yield takeEvery(CREATE_DUMMY_RANKERS, createDummyRankersSaga);
    yield takeEvery(EDIT_RANK, editRankSaga);
    yield takeEvery(EDIT_SEASON, editSeasonSaga);
    yield takeEvery(SET_TARGET_PLAYER, setTargetPlayerSaga);
    yield takeEvery(GET_RANKERS, getRankersSaga);
}
