import { call, take, put, takeEvery, throttle } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import {
    GET_BACKUP_CRAWLERS,
    SEARCH_BACKUP_CRAWLERS,
    CREATE_BACKUP_CRAWLERS,
    RESTORE_BY_BACKUP_CRAWLER,
} from './action';
import { OPEN_MODAL, CLOSE_MODAL, CONFIRM } from 'src/store/modules/modal';

const getBackupCrawlersSaga = createPromiseSagaWithHeader(GET_BACKUP_CRAWLERS, api.getBackupCrawlers);
const searchBackupCrawlerSaga = createPromiseSagaWithHeader(SEARCH_BACKUP_CRAWLERS, api.searchBackupCrawlers);

function* createBackupCrawlersSaga(action) {
    const { gameId, ymd } = action.payload;

    try {
        const { payload: isConfirm } = yield take(CONFIRM);

        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
            yield call(api.createBackupCrawlers, { gameId, ymd });
            yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

            yield put({ type: OPEN_MODAL, payload: 'successAlert' });

            yield take(CLOSE_MODAL);

            yield put({ type: SEARCH_BACKUP_CRAWLERS, payload: { page: 1, limit: 10, param: { gameId, ymd } } });
        }
    } catch (e) {
        yield put({ type: `${CREATE_BACKUP_CRAWLERS}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* restoreByBackupCrawlerSaga(action) {
    try {
        const { payload: isConfirm } = yield take(CONFIRM);

        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
            yield call(api.restoreByBackupCrawler, action.payload);
            yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

            yield put({ type: `${RESTORE_BY_BACKUP_CRAWLER}_SUCCESS`, payload: true });
            yield put({ type: OPEN_MODAL, payload: 'successAlert' });
        } else {
            yield put({ type: CLOSE_MODAL, payload: 'restoreConfirm' });
        }
    } catch (e) {
        yield put({ type: `${RESTORE_BY_BACKUP_CRAWLER}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* backupCrawlerSaga() {
    yield throttle(2000, GET_BACKUP_CRAWLERS, getBackupCrawlersSaga);
    yield throttle(1000, SEARCH_BACKUP_CRAWLERS, searchBackupCrawlerSaga);
    yield takeEvery(CREATE_BACKUP_CRAWLERS, createBackupCrawlersSaga);
    yield takeEvery(RESTORE_BY_BACKUP_CRAWLER, restoreByBackupCrawlerSaga);
}
