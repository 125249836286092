import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentLocaleMap as getCurrentLocaleMapRequest } from 'src/store/modules/lang';

export default function useLocaleMap() {
    const { loading, data, error } = useSelector((state) => state.lang.localeMap);
    const dispatch = useDispatch();
    const getLocaleMap = useCallback((lang) => dispatch(getCurrentLocaleMapRequest(lang)), [dispatch]);

    return {
        loading,
        data,
        error,
        getLocaleMap,
    };
}
