import { takeEvery, call, put, take } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import {
    GET_CHEAT_PLAYERS,
    SEARCH_CHEAT_PLAYERS,
    ADD_CHEAT_PLAYERS,
    DELETE_CHEAT_PLAYER,
    GET_PLAYER_CHEAT_STATUS,
    PLAYER_BAN,
    PLAYER_UNBAN,
    DEVICE_BAN,
    DEVICE_UNBAN,
    INQUIRY_BAN,
    INQUIRY_UNBAN,
    BAN_WITH_INQUIRY_BAN,
    SET_BAN_TARGET,
    ON_CHANGE,
} from './action';
import { GET_PLAYER_BAN_LOGS } from 'src/store/modules/playerBanLog';
import { GET_INQUIRY_BAN_LOGS, GET_PLAYER_INQUIRY_BAN_LOGS } from 'src/store/modules/inquiryBanLog';
import { OPEN_MODAL, CLOSE_MODAL, CONFIRM_WITH, CONFIRM } from 'src/store/modules/modal';
import { GET_PLAYER_FROM_FIREBASE } from '../player';

const getCheatPlayersSaga = createPromiseSagaWithHeader(GET_CHEAT_PLAYERS, api.getCheatPlayers);
const searchCheatPlayersSaga = createPromiseSagaWithHeader(SEARCH_CHEAT_PLAYERS, api.searchCheatPlayers);
const getPlayerCheatStatusSaga = createPromiseSaga(GET_PLAYER_CHEAT_STATUS, api.getPlayerCheatStatus);

function* playerBanSaga(action) {
    const { gameId, param } = action.payload;
    const { database, playerId } = param;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.playerBan, { gameId, param });

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${PLAYER_BAN}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: GET_PLAYER_BAN_LOGS, payload: { gameId, playerId, database } });
    } catch (e) {
        yield put({ type: `${PLAYER_BAN}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* playerUnbanSaga(action) {
    const { gameId, param } = action.payload;
    const { database, playerId } = param;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.playerUnban, { gameId, param });

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${PLAYER_UNBAN}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: GET_PLAYER_BAN_LOGS, payload: { gameId, database, playerId } });
    } catch (e) {
        yield put({ type: `${PLAYER_UNBAN}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* deviceBanSaga(action) {
    const { gameId, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.deviceBan, { gameId, param });

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${DEVICE_BAN}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        // TODO get log
    } catch (e) {
        yield put({ type: `${DEVICE_BAN}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* deviceUnbanSaga(action) {
    const { gameId, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.deviceUnban, { gameId, param });

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${DEVICE_UNBAN}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        // TODO get log
    } catch (e) {
        yield put({ type: `${DEVICE_UNBAN}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* inquiryBanSaga(action) {
    const { gameId, param } = action.payload;
    const { database, playerId } = param;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.inquiryBan, { gameId, param });

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${PLAYER_BAN}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: GET_INQUIRY_BAN_LOGS, payload: { gameId, playerId, database } });
    } catch (e) {
        yield put({ type: `${PLAYER_BAN}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* inquiryUnbanSaga(action) {
    const { gameId, param } = action.payload;
    const { database, playerId } = param;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.inquiryUnban, { gameId, param });

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${PLAYER_UNBAN}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: GET_INQUIRY_BAN_LOGS, payload: { gameId, database, playerId } });
    } catch (e) {
        yield put({ type: `${PLAYER_UNBAN}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* banWithInquiryBanSaga(action) {
    const { gameId, param } = action.payload;
    const { database, playerId } = param;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.inquiryBan, { gameId, param });
        yield call(api.playerBan, { gameId, param });

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${BAN_WITH_INQUIRY_BAN}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield put({ type: GET_PLAYER_BAN_LOGS, payload: { gameId, playerId, database } });
        yield put({ type: GET_PLAYER_INQUIRY_BAN_LOGS, payload: { gameId, playerId, database } });
    } catch (e) {
        yield put({ type: `${BAN_WITH_INQUIRY_BAN}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* setBanTargetSaga() {
    try {
        const { payload } = yield take(CONFIRM_WITH);
        const { isConfirm, ...param } = payload;
        const { gameId, database, playerId } = param;

        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'loadingAlert' });
            const { data: player } = yield call(api.getPlayerFromFirebaseWithIntl, {
                gameId,
                database,
                playerId,
            });
            const { data: banLogs } = yield call(api.getPlayerBanLogs, { gameId, database, playerId });
            const { data: cheatStatus } = yield call(api.getPlayerCheatStatus, { gameId, database, playerId });
            const { data: inquiryBanLogs } = yield call(api.getPlayerInquiryBanLogs, { gameId, database, playerId });

            yield put({ type: `${GET_PLAYER_FROM_FIREBASE}_SUCCESS`, payload: player });
            yield put({ type: `${GET_PLAYER_BAN_LOGS}_SUCCESS`, payload: banLogs });
            yield put({ type: `${GET_PLAYER_CHEAT_STATUS}_SUCCESS`, payload: cheatStatus });
            yield put({ type: `${GET_PLAYER_INQUIRY_BAN_LOGS}_SUCCESS`, payload: inquiryBanLogs });
            yield put({ type: ON_CHANGE, payload: { name: 'target', value: { database, playerId } } });
            yield put({ type: CLOSE_MODAL, payload: 'loadingAlert' });
        }

        yield put({ type: CLOSE_MODAL, payload: 'playerSearch' });
    } catch (e) {
        yield put({ type: `${SET_BAN_TARGET}_ERROR` });
        yield put({ type: CLOSE_MODAL, payload: 'loadingAlert' });
    }
}

function* addCheatPlayersSaga(action) {
    const param = action.payload;

    try {
        const formData = new FormData();

        Object.keys(param).forEach((key) => {
            formData.append(key, param[key]);
        });

        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.addCheatPlayers, formData);

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${ADD_CHEAT_PLAYERS}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);

        yield put({ type: CLOSE_MODAL, payload: 'addCheatPlayers' });
        yield put({ type: GET_CHEAT_PLAYERS, payload: { page: 1, limit: 14 } });
    } catch (e) {
        yield put({ type: `${ADD_CHEAT_PLAYERS}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* deleteCheatPlayerSaga(action) {
    try {
        const { payload: isConfirm } = yield take(CONFIRM);

        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
            yield call(api.deleteCheatPlayer, action.payload);
            yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

            yield put({ type: `${DELETE_CHEAT_PLAYER}_SUCCESS`, payload: true });

            yield put({ type: GET_CHEAT_PLAYERS, payload: { page: 1, limit: 14 } });
        }
    } catch (e) {
        yield put({ type: `${DELETE_CHEAT_PLAYER}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* cheatPlayerSaga() {
    yield takeEvery(GET_CHEAT_PLAYERS, getCheatPlayersSaga);
    yield takeEvery(SEARCH_CHEAT_PLAYERS, searchCheatPlayersSaga);
    yield takeEvery(GET_PLAYER_CHEAT_STATUS, getPlayerCheatStatusSaga);
    yield takeEvery(PLAYER_BAN, playerBanSaga);
    yield takeEvery(PLAYER_UNBAN, playerUnbanSaga);
    yield takeEvery(DEVICE_BAN, deviceBanSaga);
    yield takeEvery(DEVICE_UNBAN, deviceUnbanSaga);
    yield takeEvery(INQUIRY_BAN, inquiryBanSaga);
    yield takeEvery(INQUIRY_UNBAN, inquiryUnbanSaga);
    yield takeEvery(BAN_WITH_INQUIRY_BAN, banWithInquiryBanSaga);
    yield takeEvery(SET_BAN_TARGET, setBanTargetSaga);
    yield takeEvery(ADD_CHEAT_PLAYERS, addCheatPlayersSaga);
    yield takeEvery(DELETE_CHEAT_PLAYER, deleteCheatPlayerSaga);
}
