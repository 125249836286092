import { createAction } from 'redux-actions';

export const GET_BACKUP_FILTER_BY_OID = 'backupFilter/GET_BACKUP_FILTER_BY_OID';
export const GET_BACKUP_FILTER_BY_OID_SUCCESS = 'backupFilter/GET_BACKUP_FILTER_BY_OID_SUCCESS';
export const GET_BACKUP_FILTER_BY_OID_ERROR = 'backupFilter/GET_BACKUP_FILTER_BY_OID_ERROR';

export const getBackupFilterByOid = createAction(GET_BACKUP_FILTER_BY_OID, (id) => id);

export const GET_BACKUP_FILTERS = 'backupFilter/GET_BACKUP_FILTERS';
export const GET_BACKUP_FILTERS_SUCCESS = 'backupFilter/GET_BACKUP_FILTERS_SUCCESS';
export const GET_BACKUP_FILTERS_ERROR = 'backupFilter/GET_BACKUP_FILTERS_ERROR';

export const getBackupFilters = createAction(GET_BACKUP_FILTERS, ({ page, limit }) => ({ page, limit }));

export const ADD_BACKUP_FILTER = 'backupFilter/ADD_BACKUP_FILTER';
export const ADD_BACKUP_FILTER_SUCCESS = 'backupFilter/ADD_BACKUP_FILTER_SUCCESS';
export const ADD_BACKUP_FILTER_ERROR = 'backupFilter/ADD_BACKUP_FILTER_ERROR';

export const addBackupFilter = createAction(ADD_BACKUP_FILTER, ({ gameId, param }) => ({ gameId, param }));

export const EDIT_BACKUP_FILTER = 'backupFilter/EDIT_BACKUP_FILTER';
export const EDIT_BACKUP_FILTER_SUCCESS = 'backupFilter/EDIT_BACKUP_FILTER_SUCCESS';
export const EDIT_BACKUP_FILTER_ERROR = 'backupFilter/EDIT_BACKUP_FILTER_ERROR';

export const editBackupFilter = createAction(EDIT_BACKUP_FILTER, ({ oid, param }) => ({ oid, param }));

export const ON_CHANGE = 'backupFilter/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const ON_PUSH_ITEM = 'backupFilter/ON_PUSH_ITEM';
export const onPushItem = createAction(ON_PUSH_ITEM, (payload) => payload);

export const ON_REMOVE_ITEM = 'backupFilter/ON_REMOVE_ITEM';
export const onRemoveItem = createAction(ON_REMOVE_ITEM, (payload) => payload);

export const INITIALIZE = 'backupFilter/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
