import { takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import { GET_DEVICE_BAN_LOGS_BY_DEVICE_ID, GET_DEVICE_BAN_LOGS, SEARCH_DEVICE_BAN_LOGS } from './action';

const getDeviceBanLogsByDeviceIdSaga = createPromiseSaga(GET_DEVICE_BAN_LOGS, api.getDeviceBanLogsByDeviceId);
const getDeviceBanLogsSaga = createPromiseSagaWithHeader(GET_DEVICE_BAN_LOGS, api.getDeviceBanLogs);
const searchDeviceBanLogsSaga = createPromiseSagaWithHeader(SEARCH_DEVICE_BAN_LOGS, api.searchDeviceBanLogs);

export default function* deviceBanLogSaga() {
    yield takeEvery(GET_DEVICE_BAN_LOGS_BY_DEVICE_ID, getDeviceBanLogsByDeviceIdSaga);
    yield takeEvery(GET_DEVICE_BAN_LOGS, getDeviceBanLogsSaga);
    yield takeEvery(SEARCH_DEVICE_BAN_LOGS, searchDeviceBanLogsSaga);
}
