import { takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import { GET_MANUAL_PROVIDE_LOGS, SEARCH_MANUAL_PROVIDE_LOGS } from './action';

const getManualProvideLogs = createPromiseSagaWithHeader(GET_MANUAL_PROVIDE_LOGS, api.getManualProvideLogs);
const searchManualProvideLogs = createPromiseSagaWithHeader(SEARCH_MANUAL_PROVIDE_LOGS, api.searchManualProvideLogs);

export default function* manualProvideLogSaga() {
    yield takeEvery(GET_MANUAL_PROVIDE_LOGS, getManualProvideLogs);
    yield takeEvery(SEARCH_MANUAL_PROVIDE_LOGS, searchManualProvideLogs);
}
