import { combineActions, handleActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions, handleAsyncActionsWithHeader } from 'src/lib/asyncUtils';
import {
    GET_LOG_FILTER_BY_OID,
    GET_LOG_FILTER_BY_OID_SUCCESS,
    GET_LOG_FILTER_BY_OID_ERROR,
    GET_LOG_FILTERS,
    GET_LOG_FILTERS_SUCCESS,
    GET_LOG_FILTERS_ERROR,
    EDIT_LOG_FILTER,
    EDIT_LOG_FILTER_SUCCESS,
    EDIT_LOG_FILTER_ERROR,
    GET_LOG_REPORTS,
    GET_LOG_REPORTS_SUCCESS,
    GET_LOG_REPORTS_ERROR,
    SEARCH_LOG_REPORTS,
    SEARCH_LOG_REPORTS_SUCCESS,
    SEARCH_LOG_REPORTS_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';

const initialState = {
    result: reducerUtils.initial(false),
    filter: reducerUtils.initial({}),
    filters: reducerUtils.initial([]),
    reports: reducerUtils.initial([]),
    searchForm: {},
    isSearch: false,
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(GET_LOG_FILTER_BY_OID, GET_LOG_FILTER_BY_OID_SUCCESS, GET_LOG_FILTER_BY_OID_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_LOG_FILTER_BY_OID, 'filter', false)(state, action),
        [combineActions(GET_LOG_FILTERS, GET_LOG_FILTERS_SUCCESS, GET_LOG_FILTERS_ERROR)]: (state, action) =>
            handleAsyncActions(GET_LOG_FILTERS, 'filters', true)(state, action),
        [combineActions(EDIT_LOG_FILTER, EDIT_LOG_FILTER_SUCCESS, EDIT_LOG_FILTER_ERROR)]: (state, action) =>
            handleAsyncActions(EDIT_LOG_FILTER, 'result', true)(state, action),
        [combineActions(GET_LOG_REPORTS, GET_LOG_REPORTS_SUCCESS, GET_LOG_REPORTS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(GET_LOG_REPORTS, 'reports', true)(state, action),
        [combineActions(SEARCH_LOG_REPORTS, SEARCH_LOG_REPORTS_SUCCESS, SEARCH_LOG_REPORTS_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(SEARCH_LOG_REPORTS, 'reports', true)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
