import { createAction } from 'redux-actions';

export const GET_ACCOUNT_BY_OID = 'account/GET_ACCOUNT_BY_OID';
export const GET_ACCOUNT_BY_OID_SUCCESS = 'account/GET_ACCOUNT_BY_OID_SUCCESS';
export const GET_ACCOUNT_BY_OID_ERROR = 'account/GET_ACCOUNT_BY_OID_ERROR';

export const getAccountByOid = createAction(GET_ACCOUNT_BY_OID, (id) => id);

export const GET_ACCOUNTS = 'account/GET_ACCOUNTS';
export const GET_ACCOUNTS_SUCCESS = 'account/GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_ERROR = 'account/GET_ACCOUNTS_ERROR';

export const getAccounts = createAction(GET_ACCOUNTS, ({ page, limit }) => ({ page, limit }));

export const GET_ACCOUNT_GB = 'account/GET_ACCOUNT_GB';
export const GET_ACCOUNT_GB_SUCCESS = 'account/GET_ACCOUNT_GB_SUCCESS';
export const GET_ACCOUNT_GB_ERROR = 'account/GET_ACCOUNT_GB_ERROR';

export const getAccountGb = createAction(GET_ACCOUNT_GB, ({ gb, cdMajor }) => ({ gb, cdMajor }));

export const GET_PAGE_ROLES = 'account/GET_PAGE_ROLES';
export const GET_PAGE_ROLES_SUCCESS = 'account/GET_PAGE_ROLES_SUCCESS';
export const GET_PAGE_ROLES_ERROR = 'account/GET_PAGE_ROLES_ERROR';

export const getPageRoles = createAction(GET_PAGE_ROLES, ({ gb, cdMajor }) => ({ gb, cdMajor }));

export const CREATE_ACCOUNT = 'account/CREATE_ACCOUNT';
export const CREATE_ACCOUNT_SUCCESS = 'account/CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_ERROR = 'account/CREATE_ACCOUNT_ERROR';

export const createAccount = createAction(CREATE_ACCOUNT, (param) => param);

export const EDIT_ACCOUNT = 'account/EDIT_ACCOUNT';
export const EDIT_ACCOUNT_SUCCESS = 'account/EDIT_ACCOUNT_SUCCESS';
export const EDIT_ACCOUNT_ERROR = 'account/EDIT_ACCOUNT_ERROR';

export const editAccount = createAction(EDIT_ACCOUNT, ({ oid, param }) => ({ oid, param }));

export const RESET_PASSWORD = 'account/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'account/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'account/RESET_PASSWORD_ERROR';

export const resetPassword = createAction(RESET_PASSWORD, (oid) => oid);

export const FORCE_LOGOUT = 'account/FORCE_LOGOUT';
export const FORCE_LOGOUT_SUCCESS = 'account/FORCE_LOGOUT_SUCCESS';
export const FORCE_LOGOUT_ERROR = 'account/FORCE_LOGOUT_ERROR';

export const forceLogout = createAction(FORCE_LOGOUT, (oid) => oid);

export const EXPORT_HISTORY = 'account/EXPORT_HISTORY';
export const EXPORT_HISTORY_SUCCESS = 'account/EXPORT_HISTORY_SUCCESS';
export const EXPORT_HISTORY_ERROR = 'account/EXPORT_HISTORY_ERROR';

export const exportHistory = createAction(EXPORT_HISTORY, ({ oid, type }) => ({ oid, type }));

export const ON_CHANGE = 'account/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'account/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
