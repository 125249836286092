import { put, call, take, takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { UPDATE_VIP_LIST } from './action';
import { CLOSE_MODAL, OPEN_MODAL } from 'src/store/modules/modal';

function* updateVipListSaga(action) {
    const { gameId, param } = action.payload;

    try {
        const formData = new FormData();

        Object.keys(param).forEach((key) => {
            formData.append(key, param[key]);
        });

        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

        yield call(api.updateVipList, { gameId, param: formData });

        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${UPDATE_VIP_LIST}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });

        yield take(CLOSE_MODAL);
    } catch (e) {
        yield put({ type: `${UPDATE_VIP_LIST}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

export default function* managementSaga() {
    yield takeEvery(UPDATE_VIP_LIST, updateVipListSaga);
}
