import { createAction } from 'redux-actions';

export const GET_DELETE_LOG_BY_OID = 'deleteLog/GET_DELETE_LOG_BY_OID';
export const GET_DELETE_LOG_BY_OID_SUCCESS = 'deleteLog/GET_DELETE_LOG_BY_OID_SUCCESS';
export const GET_DELETE_LOG_BY_OID_ERROR = 'deleteLog/GET_DELETE_LOG_BY_OID_ERROR';

export const getDeleteLogByOid = createAction(GET_DELETE_LOG_BY_OID, (id) => id);

export const GET_DELETE_LOGS = 'deleteLog/GET_DELETE_LOGS';
export const GET_DELETE_LOGS_SUCCESS = 'deleteLog/GET_DELETE_LOGS_SUCCESS';
export const GET_DELETE_LOGS_ERROR = 'deleteLog/GET_DELETE_LOGS_ERROR';

export const getDeleteLogs = createAction(GET_DELETE_LOGS, ({ gameId, page }) => ({ gameId, page }));

export const SEARCH_DELETE_LOGS = 'deleteLog/SEARCH_DELETE_LOGS';
export const SEARCH_DELETE_LOGS_SUCCESS = 'deleteLog/SEARCH_DELETE_LOGS_SUCCESS';
export const SEARCH_DELETE_LOGS_ERROR = 'deleteLog/SEARCH_DELETE_LOGS_ERROR';

export const searchDeleteLogs = createAction(SEARCH_DELETE_LOGS, ({ gameId, page, param }) => ({
    gameId,
    page,
    param,
}));

export const GET_TARGET_PLAYER = 'deleteLog/GET_TARGET_PLAYER';
export const GET_TARGET_PLAYER_SUCCESS = 'deleteLog/GET_TARGET_PLAYER_SUCCESS';
export const GET_TARGET_PLAYER_ERROR = 'deleteLog/GET_TARGET_PLAYER_ERROR';

export const getTargetPlayer = createAction(GET_TARGET_PLAYER, ({ gameId, database, playerId }) => ({
    gameId,
    database,
    playerId,
}));

export const RECOVER = 'deleteLog/RECOVER';
export const RECOVER_SUCCESS = 'deleteLog/RECOVER_SUCCESS';
export const RECOVER_ERROR = 'deleteLog/RECOVER_ERROR';

export const recover = createAction(RECOVER, ({ gameId, param }) => ({
    gameId,
    param,
}));

export const SET_RECOVER_TARGET = 'delegeLog/SET_RECOVER_TARGET';
export const setRecoverTarget = createAction(SET_RECOVER_TARGET, (key) => key);

export const ON_CHANGE = 'deleteLog/ON_CHANGE';
export const onChagne = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'deleteLog/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
