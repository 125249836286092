import { combineReducers, applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import * as reducers from './modules';
import rootSaga from './sagas';

const rootReducer = combineReducers(reducers);

const configure = (preloadState) => {
    const sagaMiddleware = createSagaMiddleware();

    const store =
        process.env.NODE_ENV !== 'production'
            ? createStore(rootReducer, preloadState, composeWithDevTools(applyMiddleware(sagaMiddleware)))
            : createStore(rootReducer, preloadState, applyMiddleware(sagaMiddleware));

    sagaMiddleware.run(rootSaga);

    return store;
};

export default configure;
