import { call, put, take, takeEvery } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga } from 'src/lib/asyncUtils';
import { GET_SOURCE_PLAYER, GET_TARGET_PLAYER, PLAYER_RECOVER, SET_RECOVER_TARGET } from './action';
import { OPEN_MODAL, CLOSE_MODAL, CONFIRM_WITH } from 'src/store/modules/modal';

const getSourcePlayerSaga = createPromiseSaga(GET_SOURCE_PLAYER, api.getPlayerFromFirebase);
const getTargetPlayerSaga = createPromiseSaga(GET_TARGET_PLAYER, api.getPlayerFromFirebase);

function* playerRecoverSaga(action) {
    const { gameId, param } = action.payload;

    try {
        yield put({ type: OPEN_MODAL, payload: 'processingAlert' });
        yield call(api.recover, { gameId, param });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

        yield put({ type: `${PLAYER_RECOVER}_SUCCESS`, payload: true });
        yield put({ type: OPEN_MODAL, payload: 'successAlert' });
    } catch (e) {
        yield put({ type: `${PLAYER_RECOVER}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* setRecoverTargetSaga(action) {
    const key = action.payload;

    try {
        const { payload } = yield take(CONFIRM_WITH);
        const { isConfirm, ...param } = payload;
        const { gameId, database, playerId } = param;

        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'loadingAlert' });
            if (key === 'source') {
                yield put({ type: GET_SOURCE_PLAYER, payload: { gameId, database, playerId } });
            }

            if (key === 'target') {
                yield put({ type: GET_TARGET_PLAYER, payload: { gameId, database, playerId } });
            }
            yield put({ type: CLOSE_MODAL, payload: 'loadingAlert' });
        }

        yield put({ type: CLOSE_MODAL, payload: 'playerSearch' });
    } catch (e) {
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
        yield put({ type: CLOSE_MODAL, payload: 'loadingAlert' });
    }
}

export default function* recoverSaga() {
    yield takeEvery(GET_SOURCE_PLAYER, getSourcePlayerSaga);
    yield takeEvery(GET_TARGET_PLAYER, getTargetPlayerSaga);
    yield takeEvery(PLAYER_RECOVER, playerRecoverSaga);
    yield takeEvery(SET_RECOVER_TARGET, setRecoverTargetSaga);
}
