import { handleActions, combineActions } from 'redux-actions';
import { reducerUtils, handleAsyncActions, handleAsyncActionsWithHeader } from 'src/lib/asyncUtils';
import {
    GET_GAME_BY_OID,
    GET_GAME_BY_OID_SUCCESS,
    GET_GAME_BY_OID_ERROR,
    GET_MAPPED_CODES,
    GET_MAPPED_CODES_SUCCESS,
    GET_MAPPED_CODES_ERROR,
    GET_GAMES,
    GET_GAMES_SUCCESS,
    GET_GAMES_ERROR,
    GET_GAMES_FOR_SELECT,
    GET_GAMES_FOR_SELECT_SUCCESS,
    GET_GAMES_FOR_SELECT_ERROR,
    ADD_GAME,
    ADD_GAME_SUCCESS,
    ADD_GAME_ERROR,
    EDIT_GAME,
    EDIT_GAME_SUCCESS,
    EDIT_GAME_ERROR,
    ON_CHANGE,
    INITIALIZE,
} from './action';

const initialState = {
    result: reducerUtils.initial(false),
    games: reducerUtils.initial([]),
    game: reducerUtils.initial({}),
    mappedCodes: reducerUtils.initial([]),
    totalCount: 0,
    currentPage: 1,
    lastPage: 1,
};

export default handleActions(
    {
        [combineActions(GET_GAME_BY_OID, GET_GAME_BY_OID_SUCCESS, GET_GAME_BY_OID_ERROR)]: (state, action) =>
            handleAsyncActions(GET_GAME_BY_OID, 'game', true)(state, action),
        [combineActions(GET_MAPPED_CODES, GET_MAPPED_CODES_SUCCESS, GET_MAPPED_CODES_ERROR)]: (state, action) =>
            handleAsyncActions(GET_MAPPED_CODES, 'mappedCodes', true)(state, action),
        [combineActions(GET_GAMES, GET_GAMES_SUCCESS, GET_GAMES_ERROR)]: (state, action) =>
            handleAsyncActionsWithHeader(GET_GAMES, 'games', true)(state, action),
        [combineActions(GET_GAMES_FOR_SELECT, GET_GAMES_FOR_SELECT_SUCCESS, GET_GAMES_FOR_SELECT_ERROR)]: (
            state,
            action,
        ) => handleAsyncActions(GET_GAMES_FOR_SELECT, 'games', true)(state, action),
        [combineActions(ADD_GAME, ADD_GAME_SUCCESS, ADD_GAME_ERROR)]: (state, action) =>
            handleAsyncActions(ADD_GAME, 'result', false)(state, action),
        [combineActions(EDIT_GAME, EDIT_GAME_SUCCESS, EDIT_GAME_ERROR)]: (state, action) =>
            handleAsyncActions(EDIT_GAME, 'result', false)(state, action),
        [ON_CHANGE]: (state, action) => {
            const { target, name, value } = action.payload;

            return target
                ? {
                      ...state,
                      [target]: {
                          ...state[target],
                          [name]: value,
                      },
                  }
                : {
                      ...state,
                      [name]: value,
                  };
        },
        [INITIALIZE]: (state, action) => {
            const target = action.payload;

            return {
                ...state,
                [target]: initialState[target],
            };
        },
    },
    initialState,
);
