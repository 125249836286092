import { takeEvery, take, put, call } from 'redux-saga/effects';
import * as api from 'src/lib/api';
import { createPromiseSaga, createPromiseSagaWithHeader } from 'src/lib/asyncUtils';
import {
    GET_DELETE_LOG_BY_OID,
    GET_DELETE_LOGS,
    SEARCH_DELETE_LOGS,
    GET_TARGET_PLAYER,
    RECOVER,
    SET_RECOVER_TARGET,
} from './action';
import { OPEN_MODAL, CLOSE_MODAL, CONFIRM, CONFIRM_WITH } from 'src/store/modules/modal';

const getDeleteLogByOidSaga = createPromiseSaga(GET_DELETE_LOG_BY_OID, api.getDeleteLogByOid);
const getDeleteLogsSaga = createPromiseSagaWithHeader(GET_DELETE_LOGS, api.getDeleteLogs);
const searchDeleteLogsSaga = createPromiseSagaWithHeader(SEARCH_DELETE_LOGS, api.searchDeleteLogs);
const getTargetPlayerSaga = createPromiseSaga(GET_TARGET_PLAYER, api.getPlayerFromFirebase);

function* recoverSaga(action) {
    const { gameId, param } = action.payload;

    try {
        const { payload: isConfirm } = yield take(CONFIRM);

        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'processingAlert' });

            yield call(api.cancelDelete, { gameId, param });

            yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });

            yield put({ type: `${RECOVER}_SUCCESS`, payload: true });
            yield put({ type: OPEN_MODAL, payload: 'successAlert' });

            yield take(CLOSE_MODAL);

            yield put({ type: CLOSE_MODAL, payload: 'deleteRecover' });
        }
    } catch (e) {
        yield put({ type: `${RECOVER}_ERROR`, error: true, payload: e });
        yield put({ type: CLOSE_MODAL, payload: 'processingAlert' });
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
    }
}

function* setRecoverTargetSaga() {
    try {
        const { payload } = yield take(CONFIRM_WITH);
        const { isConfirm, ...param } = payload;
        const { gameId, database, playerId } = param;

        if (isConfirm) {
            yield put({ type: OPEN_MODAL, payload: 'loadingAlert' });

            yield put({ type: GET_TARGET_PLAYER, payload: { gameId, database, playerId } });

            yield put({ type: CLOSE_MODAL, payload: 'loadingAlert' });
        }

        yield put({ type: CLOSE_MODAL, payload: 'playerSearch' });
    } catch (e) {
        yield put({ type: OPEN_MODAL, payload: 'failAlert' });
        yield put({ type: CLOSE_MODAL, payload: 'loadingAlert' });
    }
}

export default function* deleteLogSaga() {
    yield takeEvery(GET_DELETE_LOG_BY_OID, getDeleteLogByOidSaga);
    yield takeEvery(GET_DELETE_LOGS, getDeleteLogsSaga);
    yield takeEvery(SEARCH_DELETE_LOGS, searchDeleteLogsSaga);
    yield takeEvery(SET_RECOVER_TARGET, setRecoverTargetSaga);
    yield takeEvery(GET_TARGET_PLAYER, getTargetPlayerSaga);
    yield takeEvery(RECOVER, recoverSaga);
}
