import { createAction } from 'redux-actions';

export const GET_DEVICE_BAN_LOGS_BY_DEVICE_ID = 'deviceBanLog/GET_DEVICE_BAN_LOGS_BY_DEVICE_ID';
export const GET_DEVICE_BAN_LOGS_BY_DEVICE_ID_SUCCESS = 'deviceBanLog/GET_DEVICE_BAN_LOGS_BY_DEVICE_ID_SUCCESS';
export const GET_DEVICE_BAN_LOGS_BY_DEVICE_ID_ERROR = 'deviceBanLog/GET_DEVICE_BAN_LOGS_BY_DEVICE_ID_ERROR';

export const getDeviceBanLogsByDeviceId = createAction(GET_DEVICE_BAN_LOGS_BY_DEVICE_ID, ({ gameId, param }) => ({
    gameId,
    param,
}));

export const GET_DEVICE_BAN_LOGS = 'deviceBanLog/GET_DEVICE_BAN_LOGS';
export const GET_DEVICE_BAN_LOGS_SUCCESS = 'deviceBanLog/GET_DEVICE_BAN_LOGS_SUCCESS';
export const GET_DEVICE_BAN_LOGS_ERROR = 'deviceBanLog/GET_DEVICE_BAN_LOGS_ERROR';

export const getDeviceBanLogs = createAction(GET_DEVICE_BAN_LOGS, ({ page, limit }) => ({ page, limit }));

export const SEARCH_DEVICE_BAN_LOGS = 'deviceBanLog/SEARCH_DEVICE_BAN_LOGS';
export const SEARCH_DEVICE_BAN_LOGS_SUCCESS = 'deviceBanLog/SEARCH_DEVICE_BAN_LOGS_SUCCESS';
export const SEARCH_DEVICE_BAN_LOGS_ERROR = 'deviceBanLog/SEARCH_DEVICE_BAN_LOGS_ERROR';

export const searchDeviceBanLogs = createAction(SEARCH_DEVICE_BAN_LOGS, ({ page, limit, param }) => ({
    page,
    limit,
    param,
}));

export const ON_CHANGE = 'deviceBanLog/ON_CHANGE';
export const onChange = createAction(ON_CHANGE, (payload) => payload);

export const INITIALIZE = 'deviceBanLog/INITIALIZE';
export const initialize = createAction(INITIALIZE, (target) => target);
